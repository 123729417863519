import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
/* import { useNavigate } from 'react-router-dom'; */
/* import LogoSVG from '../images/SVGS/LogoSVG'; */
/* import HomeSVG from '../images/SVGS/HomeSVG';
import DashboardsSVG from '../images/SVGS/DashboardsSVG';
import CalendarioSVG from '../images/SVGS/CalendarioSVG'; */
import hamburguer from '../images/hamburguer.png';
import LogoPNG from '../images/RIOLOGO.png';
import Cookies from 'js-cookie';

import GmailSendEmail from './GmailSendEmail';
/* JSON.parse(Cookies.get('user')); */

function NavBar() {
	/* const [menuOpen, setMenuOpen] = useState(false); */
	const [newLinks, setNewLinks] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isContactOpen, setisContactIsOpen] = useState(false);

	const contentRef = useRef(null);
	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	const toggleContactAccordion = () => {
		setisContactIsOpen(!isContactOpen);
	};
	const handleDataFromChild = (data) => {
		setisContactIsOpen(data);
	};

	useEffect(() => {
		if (Cookies.get('user')) {
			setNewLinks(true);
		} else {
			setNewLinks(false);
		}
	}, []);

	return (
		<>
			<nav className='flex w-full h-[80px] font-inter fixed top-0 z-30 bg-[#13335A]  bg-whiterounded-lg  shadow-lg'>
				<div className='flex justify-between md:justify-between lg:justify-between 2xl:justify-between w-[90%] sm:w-[95%] mx-auto items-center max-w-[1136px]'>
					<Link
						to='/home'
						className='text-[#1E1E1E] '>
						<div className='flex gap-2 items-center '>
							<div className='py-1'>
								<img
									src={LogoPNG}
									alt='Logo clicavel'
									className='scale-100'
								/>
							</div>
						</div>
					</Link>
					<div className='hidden  xl:flex 2xl:flex justify-center items-center gap-6 lg:gap-6  md:flex	 text-white font-inter text-base  text-[18px] not-italic  leading-normal'>
						{!newLinks ? (
							<Link to='/home'>
								<p className='flex items-center font-light hover:font-medium text-[16px] transition-all duration-200'>
									Inicio
								</p>
							</Link>
						) : null}
						{newLinks ? (
							<Link to='/hub'>
								<p className='flex items-center font-light hover:font-medium text-[16px] transition-all duration-200'>
									Área do Usuário
								</p>
							</Link>
						) : null}
						<Link to={'/blog'}>
							<p className='flex items-center font-light hover:font-medium text-[16px] transition-all duration-200'>
								Notícias
							</p>
						</Link>
						<Link to='/agenda-eventos'>
							<p className='flex items-center font-light hover:font-medium text-[16px] transition-all duration-200'>
								Agenda
							</p>
						</Link>
						<Link>
							<button
								onClick={() => toggleContactAccordion()}
								className='flex   items-center'>
								<p className='flex items-center font-light hover:font-medium text-[16px] transition-all duration-200'>
									Contato
								</p>
							</button>
						</Link>

						{newLinks ? (
							<Link
								to='/login'
								onClick={() => {
									Cookies.remove('user');
									localStorage.clear();
								}}
								className='text-white hover:bg-[#7A3B3A] bg-[#963F3D] rounded-lg font-semibold px-6 py-1.5 active:border-2 active:border-white '>
								<div className='flex gap-2  items-center '>
									Sair
								</div>
							</Link>
						) : (
							<Link
								to='/login'
								className='text-white  bg-[#42B9EB] hover:bg-[#0078B5] rounded-lg px-5 py-2   active:border-2 active:border-white '>
								<div className='flex gap-2  items-center text-center'>
									Login
								</div>
							</Link>
						)}
					</div>
					<div className='text-white md:hidden lg:hidden 2xl:hidden'>
						<button
							onClick={toggleAccordion}
							className='text-white md:hidden xl:hidden 2xl:hidden'>
							<img
								src={hamburguer}
								alt='icone de menu'
								className='w-6 h-5 z-40 fill-[#004A80] invert-0'
							/>
						</button>
						<div
							ref={contentRef}
							className={`overflow-hidden fixed right-1 top-[78.9px] transition-all duration-500 ease-in-ou w-full bg-black opacity-0 border shadow-lg faded-border  ${
								isOpen
									? 'h-screen opacity-50'
									: 'opacity-0  h-0'
							}`}></div>

						<div
							ref={contentRef}
							className={`overflow-hidden fixed right-0 top-[80px] text-center transition-all duration-500 ease-in-out bg-[#F0F4F8] shadow-lg px-[16px] z-20 ${
								isOpen ? 'h-screen' : 'h-0'
							}`}>
							<ul className='py-1'>
								<li>
									{newLinks ? (
										<Link
											to='/login'
											onClick={() => {
												Cookies.remove('user');
												localStorage.clear();
											}}
											className='block w-[100px] text-wrap px-4 mt-2 font py-2 text-white  rounded-md bg-[#963F3D]  hover:bg-[#673938]'>
											Sair
										</Link>
									) : (
										<Link
											to='/login'
											className='block mt-2  rounded-md font py-2 text-white bg-[#00C0F3] hover:bg-[#016DA3] active:border-2 active:border-white '>
											Login
										</Link>
									)}
								</li>
								<li>
									<Link
										to='/home'
										className='block w-[100px] text-wrap px-4 font py-2 text-[#004A80] hover:text-[#00C0F3]'>
										Inicio
									</Link>
								</li>

								<li>
									<Link
										to={newLinks ? '/blog' : '/blog'}
										className='block w-[100px] text-wrap px-4 hover:text-[#00C0F3] font py-2 text-[#004A80] '>
										Notícias
									</Link>
								</li>
								<li>
									<Link
										to={
											newLinks
												? '/agenda-eventos'
												: '/agenda-eventos'
										}
										className='block w-[100px] text-wrap px-4 font py-2 text-[#004A80] hover:text-[#00C0F3] '>
										Agenda
									</Link>
								</li>
								<li>
									<Link
										onClick={() => toggleContactAccordion()}
										className='block w-[100px] text-wrap px-4 font py-2 text-[#004A80] hover:text-[#00C0F3]'>
										Contato
									</Link>
								</li>
								{newLinks ? (
									<li>
										<Link
											to='/hub'
											className='block w-[100px] text-wrap px-4 font py-2 text-[#004A80]  hover:text-[#00C0F3]'>
											Área do Usuário
										</Link>
									</li>
								) : null}
							</ul>
						</div>
					</div>
				</div>
				<div className='h-screen z-20'>
					<div
						ref={contentRef}
						className={`overflow-hidden fixed right-0 bottom-0 transition-all duration-500 z-10  ease-in-out  w-[500px] ${
							isContactOpen
								? 'translate-y-0 h-[460px]'
								: 'translate-y-full h-0'
						}`}>
						<GmailSendEmail
							sendDataToParent={handleDataFromChild}
							className='rounded-t-2xl '
						/>
					</div>
				</div>
			</nav>
			{/* <hr class='h-px mt-16  bg-gray-700 border-10 '></hr> */}
		</>
	);
}

export default NavBar;
