import { React } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Grid from '../components/Grid';
import BlogShortcut from '../components/BlogShortcut';
import Carousel from '../components/Carousel';
import Footer from '../components/Footer';
import { IoIosArrowForward } from 'react-icons/io';
import { requestDocuments } from '../services/requests';

function Home() {
	const { hash } = useLocation();

	useEffect(() => {
		if (hash) {
			const elemento = document.getElementById(hash.replace('#', ''));
			if (elemento) {
				elemento.scrollIntoView({ behavior: 'smooth', block: 'end' });
			}
		}
	}, [hash]);
	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			<div className='text-center flex-grow  mx-auto  w-full mt-[80px] md:mt-[92px] '>
				<Carousel />
				<div
					id='servicos'
					className='h-[113.6]  w-full bg-[#F0F4F8] sm:mt-6 py-3'>
					<div className='max-w-[1136px] sm:w-[95%] mx-auto'>
						<p className='text-[#004A80]  text-3xl font-semibold w-[87%] sm:w-full  text-start mx-auto font-inter '>
							Serviços
						</p>
						<Grid />
					</div>
				</div>
				<div
					id='noticias'
					className='h-[113.6]  w-full mt-6 py-3'>
					<div className='max-w-[1136px] w-[90%] sm:w-[95%] mx-auto'>
						<div className='flex justify-between flex-col sm:flex-row '>
							<p className='text-[#004A80] text-3xl font-semibold   text-start font-inter '>
								Últimas Publicações
							</p>
							<a
								rel='noreferrer'
								href='/blog'>
								<button className='text-[#00C0F3] text-2xl font-medium   text-start  font-inter hover:text-[#0078B5] hover:scale-[102%] transition-all flex  '>
									Acesse nosso blog{' '}
									<IoIosArrowForward className='h-full scale-[150%] mx-2 mt-1' />
								</button>
							</a>
						</div>
						<BlogShortcut />
					</div>
				</div>
			</div>
			{/* <ContactPop /> */}
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Home;
