import React, { useState, useEffect } from 'react';

import VanillaTableScale from './VanillaTableScale';
import CheckTable from './CheckTable';

import Cookies from 'js-cookie';

import { setores } from '../pages/servicos/descricoes e contatos/escala';

const FormScale = () => {
	const [showAlert, setShowAlert] = useState('loading');
	const [carregando, setCarregando] = useState(false);

	const [cabeçalho, setCabeçalho] = useState({
		unidades: '',
		setores: '',
		regimes: '',
		turnos: '',
	});

	const moment = require('moment');
	require('moment/locale/pt-br');
	moment.locale('pt-br');

	const editButton = () => {
		setCarregando(true);
		localStorage.removeItem('cabeçalho');

		setTimeout(() => {
			setCarregando(false);
		}, 1000);
	};

	const handleChange = (value, id) => {
		let result = { ...cabeçalho };
		result[id] = value;
		setCabeçalho(result);
	};

	const handleTableScaleResize = (element) => {};

	const regis = () => {
		const user = JSON.parse(Cookies.get('user'));

		if (!user) return ['plantonista', 'rotina'];
		const { unidade, nome } = user;
		if (unidade && unidade.includes('padi')) {
			return ['PADI'];
		}
		if (nome && nome.includes('padi')) {
			return ['PADI'];
		}
		return ['plantonista', 'rotina'];
	};

	const lastEdit = () => {
		let date = moment(new Date()).format('DD/MM/YYYY');
		localStorage.setItem('lastEdit', date);
	};

	const sendToLocalHeader = () => {
		setCarregando(true);
		lastEdit();
		let marcos = [];
		marcos[0] = cabeçalho;
		localStorage.setItem('cabeçalho', JSON.stringify(marcos));

		setTimeout(() => {
			setCarregando(false);
		}, 1000);
	};

	const options = [
		'Apple',
		'Banana',
		'Orange',
		'Mango',
		'Pineapple',
		'Grapes',
		'Blueberry',
	];
	const [inputValue, setInputValue] = useState('');

	const filteredOptions = options.filter((option) =>
		option.toLowerCase().includes(inputValue.toLowerCase())
	);

	useEffect(() => {
		let userVerification = Cookies.get('user');

		if (userVerification) {
			let user = JSON.parse(Cookies.get('user'));
			const { unidade, nome } = user;

			const value = unidade ? unidade : nome;
			const padiVerify = value.includes('padi') ? 'padi' : '';
			setCabeçalho((prevState) => ({
				...prevState,
				unidades: value,
				regimes: padiVerify,
			}));
		}
		if (JSON.parse(localStorage.getItem('cabeçalho'))) {
			setCabeçalho(JSON.parse(localStorage.getItem('cabeçalho'))[0]);
		}
		const currentPage = 'escala';
		const validate = String(window.location.pathname).includes(currentPage);
		const localData = Cookies.get('user');
		if (localData) {
			if (JSON.parse(localData).permissao) {
				if (JSON.parse(localData).permissao.includes('escala')) {
					setShowAlert(false);
				} else {
					setShowAlert(
						validate &&
							(localData === null ||
								JSON.parse(localData).cargo !== 'gestor' ||
								JSON.parse(localData).cargo !== 'desenvolvedor')
					);
				}
			} else if (
				JSON.parse(localData).cargo === 'gestor' ||
				JSON.parse(localData).cargo === 'desenvolvedor'
			) {
				setShowAlert(false);
			}
		}
	}, []);
	return (
		<div className='flex flex-col mx-auto min-h-screen'>
			{!showAlert ? (
				<div className='text-center flex-grow mt-14'>
					<div className='flex flex-col w-[100%] lg:w-[80%] mx-auto mt-2 text-wrap text-center flex-grow'>
						<h1 className='mx-[7%] text-center mb-10 md:text-center flex justify-center text-[#004A80] font-bold text-3xl md:text-5xl lg:text-5xl md:mt-14 font-roboto'>
							Escalas de Plantão
						</h1>
						{inputValue && (
							<ul
								style={{
									border: '1px solid #ccc',
									marginTop: '5px',
									padding: '0',
								}}>
								{filteredOptions.map((option, index) => (
									<li
										key={index}
										style={{
											padding: '5px',
											cursor: 'pointer',
										}}
										onClick={() => setInputValue(option)}>
										{option}
									</li>
								))}
							</ul>
						)}

						<div className='w-[87%] md:w-[49%] lg:w-[100%] mx-[8%] md:mx-auto flex justify-center mt-12 '>
							<div className='grid grid-cols-1 lg:grid-cols-2 gap-x-24 justify-around'>
								<label className='flex mb-7 items-center md:flex-row  justify-end'>
									<p className='flex justify-end md:justify-end w-[20%]  md:w-[200px] font-semibold text-sm md:text-xl items-center text-[#323232] mr-5'>
										{' '}
										UNIDADES
									</p>
									<select
										disabled={
											localStorage.getItem('cabeçalho')
												? true
												: false
										}
										className='bg-gray-300 border w-[14rem] md:w-[300px] font-medium border-gray-300 sm:text-sm rounded-sm text-gray-700 focus:ring-primary-600 uppercase focus:border-primary-600 block p-2.5'
										name='unidade'
										id='unidade'
										onChange={({ target: { value, id } }) =>
											handleChange(value, id)
										}>
										{/* {console.log(
											JSON.parse(Cookies.get('user')).unidade
										)} */}
										{JSON.parse(Cookies.get('user'))
											.unidade ? (
											<option
												value={
													JSON.parse(
														Cookies.get('user')
													).unidade
												}>
												{
													JSON.parse(
														Cookies.get('user')
													).unidade
												}
											</option>
										) : (
											<option
												value={
													JSON.parse(
														Cookies.get('user')
													).nome
												}>
												{
													JSON.parse(
														Cookies.get('user')
													).nome
												}
											</option>
										)}
									</select>
								</label>

								<label className='flex mb-7 items-center md:flex-row justify-end'>
									<p className=' justify-end font-semibold w-[20%]   text-sm md:text-xl flex text-end items-center mr-5 md:text-center text-[#323232] '>
										{' '}
										REGIMES
									</p>
									<select
										disabled={
											localStorage.getItem('cabeçalho')
												? true
												: false
										}
										className='bg-gray-300 border w-[14rem] md:w-[300px] font-medium border-gray-300 sm:text-sm rounded-sm text-gray-700 focus:ring-primary-600 focus:border-primary-600 block p-2.5'
										name='regimes'
										id='regimes'
										onChange={({ target: { value, id } }) =>
											handleChange(value, id)
										}>
										{JSON.parse(Cookies.get('user'))
											.unidade.toLowerCase()
											.includes('padi') ? (
											<option value='padi'>PADI</option>
										) : (
											<option value=''>
												Selecione o Regime
											</option>
										)}
										{regis().map((item, index) => (
											<option
												key={index}
												value={item}>
												{item.toUpperCase()}
											</option>
										))}
									</select>
								</label>
								<label className='flex mb-7  items-center md:flex-row  justify-end'>
									<p className='font-semibold text-sm md:text-xl flex items-center  text-[#323232] mr-5'>
										{' '}
										SETORES
									</p>
									<select
										disabled={
											localStorage.getItem('cabeçalho')
												? true
												: false
										}
										className='bg-gray-300 border w-[14rem] md:w-[100%] max-w-[300px] font-medium border-gray-300 sm:text-sm rounded-sm text-gray-700 focus:ring-primary-600 focus:border-primary-600 block p-2.5'
										name='setores'
										id='setores'
										onChange={({ target: { value, id } }) =>
											handleChange(value, id)
										}>
										<option value=''>
											Selecione o Setor
										</option>
										{setores.map((setor, index) => (
											<option
												key={index}
												value={setor}>
												{setor.toUpperCase()}
											</option>
										))}
									</select>
								</label>
								<label className='flex mb-7 items-center md:flex-row  justify-end'>
									<p className='font-semibold text-sm md:text-xl flex items-center text-[#323232] mr-5'>
										{' '}
										TURNOS
									</p>
									<select
										disabled={
											localStorage.getItem('cabeçalho')
												? true
												: false
										}
										id='turnos'
										name='turnos'
										className='bg-gray-300 border w-[15rem] md:w-[300px] font-medium border-gray-300 sm:text-sm rounded-sm text-gray-700 focus:ring-primary-600 focus:border-primary-600 block p-2.5 '
										onChange={({ target: { value, id } }) =>
											handleChange(value, id)
										}>
										<option value=''>
											Selecione o Turno
										</option>
										{[
											'manhã / tarde',
											'diurno / noturno',
										].map((item, index) => (
											<option
												key={index}
												value={item}>
												{item.toUpperCase()}
											</option>
										))}
									</select>
								</label>
							</div>
						</div>
						<div className=' flex justify-end md:justify-end lg:justify-end   md:w-[48.6%]  lg:w-[100%] mx-[8%] max-w-[1135px] md:mx-auto gap-2 '>
							{localStorage.getItem('cabeçalho') ? (
								<button
									type='button'
									title='CLIQUE LIMPAR OS DADOS DO CABEÇALHO'
									className='bg-[#e94a4a]  mb-10 py-2 px-6  transition ease-in-out text-white font-semibold hover:bg-[#8f0f0f]  rounded-md'
									onClick={() => editButton()}>
									Editar
								</button>
							) : null}
							<button
								type='button'
								title='CLIQUE PARA SUBMETER OS DADOS DO CABEÇALHO ESCOLHIDO,( esta opção atualiza os dados dos prifissionais ja adcionados à tabela caso existam), (este botão permanecerá desabilitado até que todos os campos do cabeçalho estejam preenchidos)'
								disabled={
									localStorage.getItem('cabeçalho') ||
									Object.values(cabeçalho).includes('')
										? true
										: false
								}
								className='text-gray-300 bg-gray-100 enabled:bg-[#125e94]   mb-10 py-2 px-6  transition ease-in-out  font-semibold enable:hover:bg-[#175988]  rounded-md enabled:text-white'
								/* test */
								onClick={sendToLocalHeader}>
								Fixar Cabeçalho
							</button>
						</div>
					</div>
					<hr className='w-[90%] md:w-[90%] lg:w-[80%] h-0.5 mx-auto bg-[#004A80]'></hr>
					{carregando ? (
						<div
							className='flex justify-center h-[300px] content-center w-full my-20'
							role='status'>
							<svg
								aria-hidden='true'
								className='w-8 h-8 text-gray-200 animate-spin  fill-blue-600'
								viewBox='0 0 100 101'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
									fill='currentColor'
								/>
								<path
									d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
									fill='currentFill'
								/>
							</svg>
						</div>
					) : (
						<CheckTable />
					)}
					<VanillaTableScale
						onResize={handleTableScaleResize}
						inputsCabeçalho={cabeçalho}
					/>
				</div>
			) : (
				<section className='bg-[#004A80] rounded-md w-[50%] mx-auto my-auto'>
					<div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
						<div className='mx-auto max-w-screen-sm text-center'>
							<h1 className='mb-4 text-white text-6xl tracking-tight font-extrabold lg:text-7xl text-primary-600 '>
								401
							</h1>
							<p className='mb-10 text-3xl tracking-tight font-bold text-slate-100 md:text-4xl '>
								Acesso Negado
							</p>
							<p className='mb-7 text-lg font-light text-gray-300 '>
								Você não possui permissão para acessar este
								campo. Entre em contato com o administrador(a)
								da sua unidade para solicitação de acesso
							</p>
							<a
								href='/login'
								className='inline-flex hover:scale-105 transition-transform text-gray-300 bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  my-4'>
								Volte para a Tela de Login
							</a>
						</div>
					</div>
				</section>
			)}
		</div>
	);
};

export default FormScale;
