import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const proxyUrl = process.env.REACT_APP_PROXY_URL;
const dbUser = process.env.REACT_APP_DB_USER;
const dbPassword = process.env.REACT_APP_DB_PASSWORD;

const local = 'localhost';
const proxy = proxyUrl;
const prod = apiUrl;

const api = axios.create({
	baseURL: `http://${local}:${process.env.REACT_APP_API_PORT}`,
});

export const requestData = async (endpoint) => {
	const { data } = await api.get(endpoint);
	return data;
};

// Função para gerar o cabeçalho de autenticação Basic
const getBasicAuthHeader = () => {
	const username = dbUser; // Substitua pelo username correto
	const password = dbPassword; // Substitua pela senha correta
	const token = btoa(`${username}:${password}`); // Codifica as credenciais em Base64
	return {
		Authorization: `Basic ${token}`,
	};
};

/* BOLETINS */
export const requestBoletins = async () => {
	try {
		const { data } = await axios.get(`${prod}/boletins/`);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao receber os boletins. Tente novamente mais tarde.',
		};
	}
};

/* EVENTOS */
export const requestEvents = async () => {
	try {
		const { data } = await axios.get(`${prod}/eventos/`);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao buscar eventos. Tente novamente mais tarde.',
		};
	}
};

/* LOGIN */
export const requestMapAtt = async () => {
	try {
		const { data } = await axios.get(
			`${prod}/mapa/ultima-atualizacao-leito/`
		);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao buscar atualização do mapa. Tente novamente mais tarde.',
		};
	}
};

/* LOGIN */
export const requestLoginNew = async (body) => {
	try {
		const { data } = await axios.post(`${prod}/usuarios/login/`, body);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao credenciar o usuario. Tente novamente mais tarde.',
		};
	}
};

export const requestUserDetails = async (usuario) => {
	try {
		const headers = getBasicAuthHeader();
		const { data } = await axios.get(
			`${prod}/usuarios/user-detail/${usuario}/`,
			{
				headers,
			}
		);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao buscar detalhes de usuario. Tente novamente mais tarde.',
		};
	}
};

/* EMAIL DE SUPORTE */
export const requestEmail = async (body) => {
	try {
		const { data } = await axios.post(`${prod}contato/`, {
			nome: body.nome,
			email: body.email,
			telefone: body.telefone,
			mensagem: body.mensagem,
		});
		return data;
	} catch (error) {
		return {
			error: 'Erro ao enviar email. Tente novamente mais tarde.',
		};
	}
};

/* PUBLICAÇÕES BLOG */
export const requestPublics = async () => {
	try {
		const { data } = await axios.get(`${prod}/noticias/`);

		return data;
	} catch (error) {
		return {
			error: 'Erro ao buscar unidades. Tente novamente mais tarde.',
		};
	}
};

/* UNIDADES */
export const requestUnitToken = async () => {
	try {
		const { data } = await axios.get(`${prod}/unidades_token/`);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao buscar unidades por token. Tente novamente mais tarde.',
		};
	}
};

/* UNIDADES */
export const requestUnits = async () => {
	try {
		const { data } = await axios.get(`${prod}/unidades/`);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao buscar unidades. Tente novamente mais tarde.',
		};
	}
};

export const requestNoteCreate = async (body) => {
	try {
		// Construção do objeto com base nos dados recebidos
		const notificationPayload = {
			unidade_token_notificacao: body.unidade_token_notificacao,
			data_ocorrencia: body.data_ocorrencia,
			prazo_classificacao: body.data_notificacao
				.split('/')
				.reverse()
				.join('-'), // Reformatando a data de notificação
			notificante: body.notificador,
			nome_paciente: body.nome_paciente,
			nascimento_paciente: body.nascimento_paciente,
			prontuario: body.prontuario,
			sexo_paciente: body.sexo_paciente?.toUpperCase() || 'NÃO INFORMADO', // Valor padrão
			tipo_incidente: body.other_checked ? '' : body.tipo_incidente, // Se outro for marcado
			tipo_incidente_outros: body.other_checked
				? body.tipo_incidente
				: '',
			descricao: body.descricao_ocorrencia,
			acoes_imediatas: body.acoes_imediatas,
			medida_preventiva: body.medida_preventiva,
			fato_presenciado: body.quem_presenciou !== 'ninguem', // Booleano direto
			quem_presenciou:
				body.quem_presenciou === 'ninguem' ? '' : body.quem_presenciou,
			setor: body.setor,
		};

		// Configuração do cabeçalho com autenticação
		const headers = getBasicAuthHeader();

		// Enviando o objeto para a API
		const { data } = await axios.post(
			`${prod}/notificacoes/notificacoes/`,
			notificationPayload,
			{ headers } // Incluindo o cabeçalho com autenticação
		);

		return data;
	} catch (error) {
		console.error('Erro ao criar notificação:', error);
		return {
			error: 'Erro ao criar a notificação. Tente novamente mais tarde.',
		};
	}
};

export const requestClassCreation = async (body) => {
	try {
		let object = {
			notificacao: body.notificacao,
			classificacao: body.classificacao,
			sub_classificacao: body.sub_classificacao,
			prazo_investigacao: body.prazo_investigacao,
		};
		const headers = getBasicAuthHeader();
		const { data } = await axios.post(
			`${prod}/notificacoes/classificacoes/`,
			object,
			{ headers }
		);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao buscar unidades. Tente novamente mais tarde.',
		};
	}
};

export const requestInvCreation = async (body, classificacao) => {
	try {
		const headers = getBasicAuthHeader();

		const { data } = await axios.put(
			`${prod}/notificacoes/investigacoes/${classificacao}/`,
			body,
			{ headers }
		);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao finalizar a investigacao. Tente novamente mais tarde.',
		};
	}
};

export const requestAvalCreation = async (body) => {
	try {
		const headers = getBasicAuthHeader();

		const { data } = await axios.post(
			`${prod}/notificacoes/avaliacoes-finais/`,
			body,
			{ headers }
		);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao finalizar a avaliacao. Tente novamente mais tarde.',
		};
	}
};

export const requestInvList = async (classificacao) => {
	try {
		const headers = getBasicAuthHeader();
		const { data } = await axios.get(
			`${prod}/notificacoes/investigacoes/${classificacao}/`,
			{ headers }
		);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao listar notificacao. Tente novamente mais tarde.',
		};
	}
};

export const requestNotesByProtocol = async (protocol) => {
	try {
		const { data } = await axios.get(
			`${prod}/notificacoes/notificacoes/${protocol}/`
		);

		return data;
	} catch (error) {
		return {
			error: 'Erro ao buscar notificações. Muitas requisições',
		};
	}
};

export const requestAllNotes = async (protocol) => {
	try {
		const headers = getBasicAuthHeader();
		const { data } = await axios.get(`${prod}/notificacoes/notificacoes/`, {
			headers,
		});
		return data;
	} catch (error) {
		return {
			error: 'Erro ao buscar notificações. Muitas requisições',
		};
	}
};

/* REQUSITA AS NOTIFICAÇÕES POR UNIDADE */

/* export const requestNotesByUnit = async (protocol) => {
	try {
		const { data } = await axios.get(`${prod}/notificacoes/notificacoes/`);

		console.log(data);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao buscar notificações. Muitas requisições',
		};
	}
}; */

export const requestNoteLogin = async (input, senha) => {
	try {
		const { data } = await axios.post(`http://${local}:3002/noteLogin`, {
			input,
			senha,
		});
		return data;
	} catch (error) {
		return { error: error.message };
	}
};

//USUARIOS
export const requestLogin = async (input, senha) => {
	try {
		const { data } = await axios.post(`http://${local}:3002/login`, {
			input,
			senha,
		});
		return data;
	} catch (error) {
		return { error: error.message };
	}
};

export const requestCreate = async (body) => {
	try {
		const response = await axios.post(
			`http://${local}:3002/register`,
			body
		);
		return { data: response.data, status: response.status };
	} catch (error) {
		return { error: error.response.data.message };
	}
};

export const requestCreateRegister = async (token, body) => {
	try {
		const response = await axios.put(
			`http://${local}:3002/register`,
			body,
			{
				headers: { Authorization: token },
			}
		);
		return response;
	} catch (error) {
		return error;
	}
};

export const requestGestData = async ({ token, nome }) => {
	try {
		const response = await axios.get(`http://${local}:3002/register`, {
			headers: { Authorization: `Bearer ${token}` },
			params: { nome },
		});
		return response.data;
	} catch (error) {
		console.error('Erro na requisição:', error.response || error.message);
		return { error: error.response?.data || error.message };
	}
};

export const requestUsers = async ({ token }) => {
	try {
		const response = await axios.get(
			`http://${local}:3002/register/search`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
		return response.data;
	} catch (error) {
		console.error('Erro na requisição:', error.response || error.message);
		return { error: error.response?.data || error.message };
	}
};

export const updatePermissions = async ({ token, body }) => {
	try {
		const response = await axios.put(
			`http://${local}:3002/register/permissions`,
			body,
			{
				headers: { Authorization: token },
			}
		);
		return response;
	} catch (error) {
		return error;
	}
};

//ESCALAS
export const requestCreateScale = async (token, body) => {
	try {
		const response = await axios.post(`http://${local}:3002/scale`, body, {
			headers: { Authorization: token },
		});
		return response;
	} catch (error) {
		return error;
	}
};

export const requestScaleData = async (body) => {
	try {
		const response = await axios.get(
			`http://${local}:3002/scale/${body.id}`,

			{
				headers: {
					Authorization: body.token,
				},
			}
		);
		return response;
	} catch (error) {
		return error;
	}
};

export const requestAllScale = async (body) => {
	try {
		const response = await axios.get(
			`http://${local}:3002/scale/`,

			{
				headers: {
					Authorization: body.token,
				},
			}
		);
		return response;
	} catch (error) {
		return error;
	}
};

/* DOCUMENTOS */

export const requestDocuments = async () => {
	const { data } = await axios.get(`http://${local}:3002/document`);
	return data;
};
