import { React } from 'react';
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import { FaPlus } from 'react-icons/fa6';
import Footer from '../../components/Footer';
import UserPNG from '../../images/NotifyHubImages/Usuario.png';
import UnidadePNG from '../../images/NotifyHubImages/Unidade.png';
import QuebradoPNG from '../../images/NotifyHubImages/Quebrado.png';
import CalendarioPNG from '../../images/NotifyHubImages/Calendario.png';
import PranchaPNG from '../../images/NotifyHubImages/Prancha.png';
import { useNavigate } from 'react-router-dom';
import {
	requestNotesByProtocol /*  requestToken */,
} from '../../services/requests';
import Json from '../../utils/tipos_incidentes.json';

function NotesHub() {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const [protocolResult, setProtocolResult] = useState('');
	const [inputProtocol, setInputProtocol] = useState('');
	const [noteData, setNoteData] = useState();
	const [loading, setLoading] = useState(false);
	const [currentSubtitle, setCurrentSubtitle] = useState({
		title: 'Notificado',
		subtitle: 'Obrigado pela sua notificação, estamos trabalhando nela.',
	});

	const subtitles = {
		NOTIFICADO:
			'Sua Notificação esta em análise da equipe de classificação no momento.',
		CLASSIFICADO:
			'Sua Notificação esta em análise da equipe de investigação no momento.',
		INVESTIGADO:
			'Sua Notificação foi encaminhada para a equipe de conclusão e sera finalizada em breve.',
		CONCLUIDO: 'Sua Notificação foi concluída.',
	};

	const contentRef = useRef(null);
	const toTitleCase = (text) => {
		const lowerCased = text?.toLowerCase();
		return lowerCased?.charAt(0).toUpperCase() + lowerCased?.slice(1);
	};

	const toggleAccordion = async () => {
		setLoading(true);
		const data = await requestNotesByProtocol(inputProtocol);
		/* console.log(data); */

		if (!data.error) {
			const findDisplayFromValue = (displayValue) => {
				let word = displayValue.trim();
				const result = Json.find((item) => item.value === word);
				return result ? result.display : '';
			};
			setNoteData({
				...data,
				tipo_incidente: findDisplayFromValue(data.tipo_incidente),
			});
		}
		setTimeout(() => {
			setLoading(false);
		}, [2000]);

		if (data) {
			setCurrentSubtitle({
				title: toTitleCase(data.status),
				subtitle: subtitles[data.status],
			});
		} else {
			setProtocolResult(false);
		}

		if (data?.protocolo === inputProtocol) {
			setProtocolResult(true);
			setIsOpen(true);
		} else {
			setProtocolResult(false);
			setIsOpen(false);
		}
	};
	/* const requesToktste = async () => {
		let marcos = await requestToken();
		console.log(marcos);
	}; */

	const formatToBrazilianDate = (dateString) => {
		const [year, month, day] = dateString.split('-');
		return `${day}/${month}/${year}`;
	};

	const handleProtocol = (event) => {
		const protocol = event.target.value;
		setInputProtocol(protocol);
	};

	useEffect(() => {
		/* requesToktste(); */
		/* const entries = Object.entries(mock.status_notificacao);
		const lastEntry = entries[entries.length - 1];
		const [lastKey, lastValue] = lastEntry;
		setCurrentSubtitle({ title: lastKey, subtitle: subtitles[lastKey] }); */
	}, []);

	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			<div className='text-center flex-grow mx-auto font-inter w-full mt-[50px] '>
				<div className='w-full p-[42px] bg-[#F0F4F8] mt-8'>
					<div className='rounded-md w-full'>
						<p className='text-[#004A80]  text-[32px] font-semibold w-[87%] sm:w-full text-center mx-auto mb-[28px] font-inter '>
							Notificação de Incidentes
						</p>
						<div className='flex flex-col w-[75%] sm:w-[100%] mx-[auto] gap-y-4 sm:gap-y-0  sm:flex-row justify-center gap-x-[12px] content-center'>
							<p className='text-[#004A80] font-semibold   text-[22px] text-center  font-inter  '>
								Consultar protocolo
							</p>
							<input
								placeholder='Número do protocolo'
								value={inputProtocol}
								onChange={(e) => handleProtocol(e)}
								className='px-[10px] text-[15px] sm:w-[220px] border-[#E3E3E3] bg-transparent  border-[1px] rounded-[4px] text-[#757575] '></input>
							<button
								onClick={() => {
									toggleAccordion();
								}}
								className='shadow-lg text-[#757575] rounded-md transition-all font-normal px-[6px] bg-[#E3E3E3] hover:text-[#FFFFFF] hover:bg-[#7F7F7F]'>
								Consultar
							</button>
							<button
								onClick={() => navigate('/notificacoes-login')}
								className='flex sm:ml-[40px] mt-4 sm:mt-0 py-5 sm:py-0 px-[12px] text-[#FFFFFF] shadow-lg text-[15px] rounded-md font-normal items-center bg-[#004A80] gap-[14px] hover:bg-[#00C0F3]'>
								<FaPlus className='w-[18px] h-[18px]' />
								<div className='h-[16px] rounded-xl w-[2.2px] bg-black opacity-20'></div>
								Criar notificação
							</button>
						</div>
					</div>
				</div>
				<div className='bg-[#E3E3E3] mt-[24px] h-[1px] my-5 w-[95%] max-w-[1136px] mx-auto'></div>
				<div
					ref={contentRef}
					className={`scale-[90%] sm:[95%] mb-2 relative overflow-y-hidden transition-all duration-700 z-10 ease-in-out 
    			rounded-sm border-[#E3E3E3] w-full mx-auto
    ${isOpen ? 'h-[1800px]  md:h-[1800px] xl:h-[900px]' : 'h-0 border-[0px]'}`}>
					{/* Conteúdo do card */}
					<div className='bg-[#F5F5F5F5] shadow-md rounded-md border-[2px] p-[32px] border-[#E3E3E3] max-w-[1136px] mx-auto'>
						<div className='flex xl:flex-row xl:items-end justify-between  gap-y-[38px] flex-wrap flex-col items-start'>
							<div className='flex items-center  w-[95%] md:w-[500px] md:h-[80px] gap-4'>
								<div className='w-10 h-10'>
									<img
										alt='icone de usuario'
										src={UserPNG}
										className='w-full h-full object-cover'
									/>
								</div>
								<div className='flex flex-col text-start w-[80%]'>
									<p className='text-[24px] text-[#555555] leading-7  break-words w-[90%] md:w-[100%]'>
										{noteData !== undefined
											? toTitleCase(
													noteData?.nome_paciente
											  )
											: ''}
									</p>
									<p className='text-[#757575] font-normal text-[clamp(12px, 2vw, 16px)] leading-none mt-1'>
										Nome do paciente
									</p>
								</div>
							</div>
							<div className='flex items-center  w-[95%] md:w-[500px] md:h-[60px] gap-4'>
								<div className='w-10 h-10'>
									<img
										alt='icone de usuario'
										src={CalendarioPNG}
										className='w-full h-full object-cover'
									/>
								</div>

								<div className='flex flex-col text-start w-[80%] '>
									<p className='text-[24px] text-[#555555] leading-7   '>
										{noteData?.nascimento_paciente
											? formatToBrazilianDate(
													noteData?.nascimento_paciente
											  )
											: ''}
									</p>
									<p className='text-[#757575] font-normal text-[clamp(12px, 2vw, 16px)] leading-none mt-1'>
										Data de nascimento
									</p>
								</div>
							</div>
							<div className='flex items-center  w-[95%] md:w-[500px] md:h-[60px] gap-4'>
								<div className=''>
									<img
										alt='icone de usuario'
										src={QuebradoPNG}
										className='w-full h-full object-cover'
									/>
								</div>
								<div className='flex flex-col text-start w-[80%]'>
									<p className='text-[24px] text-[#555555] leading-7  break-words w-[90%] md:w-[100%]'>
										{noteData?.tipo_incidente
											? noteData?.tipo_incidente
											: ''}
									</p>
									<p className='text-[#757575] font-normal text-[clamp(12px, 2vw, 16px)] leading-none mt-1'>
										Incidente
									</p>
								</div>
							</div>
							<div className='flex items-center  w-[95%] md:w-[500px] md:h-[60px] gap-4'>
								<div className='w-10 h-10'>
									<img
										alt='icone de usuario'
										src={UnidadePNG}
										className='w-full h-full object-cover'
									/>
								</div>
								<div className='flex flex-col text-start w-[80%] '>
									<p className='text-[24px] text-[#555555] leading-7  break-words w-[90%] md:w-[100%]'>
										{noteData?.unidade?.nome
											? noteData?.unidade?.nome
											: ''}
									</p>
									<p className='text-[#757575] font-normal text-[clamp(12px, 2vw, 16px)] leading-none mt-1'>
										Unidade de incidente
									</p>
								</div>
							</div>
							<div className='flex items-center  w-[95%] md:w-[500px] md:h-[60px] gap-4'>
								<div className=''>
									<img
										alt='icone de usuario'
										src={PranchaPNG}
										className='w-full h-full object-cover'
									/>
								</div>
								<div className='flex flex-col text-start w-[80%] '>
									<p className='text-[24px] text-[#555555] leading-7   '>
										{noteData?.protocolo
											? noteData?.protocolo
											: ''}
									</p>
									<p className='text-[#757575] font-normal text-[clamp(12px, 2vw, 16px)] leading-none mt-1'>
										Número do protocolo
									</p>
								</div>
							</div>
						</div>

						{/* STATUS DA NOTIFICAÇÃO */}

						{/* <div className='transition-all duration-500 content-center my-[36px] scale-[30%] lg:scale-[93%] xl:scale-[100%] mr-[150%] xl:mr-[10px]'></div> */}
						<div className='transition-all duration-500 content-center my-[36px] mt-[60px] '>
							<div className='flex flex-col col-1 gap-6 xl:flex-row  xl:gap-0 relative '>
								{/* primeiro */}
								<div className='flex xl:mb-0 mb-[40px]'>
									<div className='grid place-items-center'>
										<div
											className={`text-[#004A80] font-semibold  rounded-full border-[3px] w-[40px] h-[40px] py-0.5 text-[20px] ${
												noteData?.status ===
													'NOTIFICADO' ||
												noteData?.status ===
													'CLASSIFICADO' ||
												noteData?.status ===
													'INVESTIGADO' ||
												noteData?.status === 'CONCLUIDO'
													? 'bg-[#00C0F3] border-[#00C0F3]  text-[#F5F5F5F5]'
													: 'text-[#004A80] border-[#CBCBCB] bg-transparent'
											}`}>
											{' '}
											1
										</div>
										<div className='flex items-center'>
											<div
												className={`h-[3px] invisible xl:visible w-[217px] ${
													noteData?.status ===
														'NOTIFICADO' ||
													noteData?.status ===
														'CLASSIFICADO' ||
													noteData?.status ===
														'INVESTIGADO' ||
													noteData?.status ===
														'CONCLUIDO'
														? 'bg-[#00C0F3]'
														: 'bg-[#CBCBCB]'
												} `}></div>
											<div
												id='NOTIFICADO'
												className='relative'>
												<div
													className={`
														h-[10px] xl:h-[30px]  w-[5px] invisible xl:visible   ${
															noteData?.status ===
																'NOTIFICADO' ||
															noteData?.status ===
																'CLASSIFICADO' ||
															noteData?.status ===
																'INVESTIGADO' ||
															noteData?.status ===
																'CONCLUIDO'
																? 'bg-[#00C0F3]'
																: 'bg-[#CBCBCB]'
														}`}
													id='NOTIFICADO'></div>
											</div>
										</div>
										<div
											className={`
											 pb-2 font-medium  text-[20px]
											
											 ${
													noteData?.status ===
														'NOTIFICADO' ||
													noteData?.status ===
														'CLASSIFICADO' ||
													noteData?.status ===
														'INVESTIGADO' ||
													noteData?.status ===
														'CONCLUIDO'
														? 'text-[#00C0F3]'
														: 'text-[#555555]'
												}`}>
											{' '}
											Notificado
											<div className=' w-full text-[16px] h-[24px] '>
												{' '}
												{noteData?.status ===
													'NOTIFICADO' ||
												noteData?.status ===
													'CLASSIFICADO' ||
												noteData?.status ===
													'INVESTIGADO' ||
												noteData?.status === 'CONCLUIDO'
													? formatToBrazilianDate(
															noteData?.prazo_classificacao
													  )
													: null}
											</div>
										</div>
									</div>
								</div>
								{/* segundo */}
								<div className='flex xl:mb-0 mb-[40px]'>
									<div className='grid  place-items-center'>
										<div
											className={`text-[#004A80] font-semibold  rounded-full border-[3px] w-[40px] h-[40px] py-0.5 text-[20px] ${
												noteData?.status ===
													'CLASSIFICADO' ||
												noteData?.status ===
													'INVESTIGADO' ||
												noteData?.status === 'CONCLUIDO'
													? 'bg-[#00C0F3] border-[#00C0F3]  text-[#F5F5F5F5]'
													: 'text-[#004A80] border-[#CBCBCB] bg-transparent'
											}`}>
											{' '}
											2
										</div>
										<div className='flex items-center'>
											<div
												className={`h-[3px] w-[217px] invisible xl:visible xl:w-[296px] my-auto ${
													noteData?.status ===
														'CLASSIFICADO' ||
													noteData?.status ===
														'INVESTIGADO' ||
													noteData?.status ===
														'CONCLUIDO'
														? 'bg-[#00C0F3]'
														: 'bg-[#CBCBCB]'
												}`}></div>
											<div
												id='Aguardando Investigação'
												className=''>
												<div
													className={`h-[5px] xl:h-[30px] w-[5px] invisible xl:visible  my-auto ${
														noteData?.status ===
															'CLASSIFICADO' ||
														noteData?.status ===
															'INVESTIGADO' ||
														noteData?.status ===
															'CONCLUIDO'
															? 'bg-[#00C0F3]'
															: 'bg-[#CBCBCB]'
													}`}
													id='Aguardando Investigação'></div>
											</div>
										</div>
										<div
											className={`
											 pb-2 font-medium  text-[20px]
											 ${
													noteData?.status ===
														'CLASSIFICADO' ||
													noteData?.status ===
														'INVESTIGADO' ||
													noteData?.status ===
														'CONCLUIDO'
														? 'text-[#00C0F3]'
														: 'text-[#555555]'
												}`}>
											{' '}
											Classificado
											<div className=' w-full text-[16px]  h-[24px]'>
												{' '}
												{noteData?.status ===
													'CLASSIFICADO' ||
												noteData?.status ===
													'INVESTIGADO' ||
												noteData?.status === 'CONCLUIDO'
													? formatToBrazilianDate(
															noteData?.prazo_classificacao
													  )
													: null}
											</div>
										</div>
									</div>
								</div>
								{/* terceiro */}

								<div className='flex xl:mb-0 mb-[40px]'>
									<div className='grid  place-items-center'>
										<div
											className={`text-[#004A80] font-semibold  rounded-full border-[3px] w-[40px] h-[40px] py-0.5 text-[20px] ${
												noteData?.status ===
													'CONCLUIDO' ||
												noteData?.status ===
													'INVESTIGADO'
													? 'bg-[#00C0F3] border-[#00C0F3]  text-[#F5F5F5F5]'
													: 'text-[#004A80] border-[#CBCBCB] bg-transparent'
											}`}>
											{' '}
											3
										</div>
										<div className='flex items-center'>
											<div
												className={`h-[3px] w-[217px] xl:w-[296px] invisible xl:visible my-auto ${
													noteData?.status ===
														'INVESTIGADO' ||
													noteData?.status ===
														'CONCLUIDO'
														? 'bg-[#00C0F3]'
														: 'bg-[#CBCBCB]'
												}`}
												id='Aguardando avaliação final'></div>
											<div
												id='Aguardando avaliação final'
												className='relative'>
												<div
													className={`h-[5px] xl:h-[30px] w-[5px] invisible xl:visible my-auto ${
														noteData?.status ===
															'INVESTIGADO' ||
														noteData?.status ===
															'CONCLUIDO'
															? 'bg-[#00C0F3]'
															: 'bg-[#CBCBCB]'
													}`}
													id='Aguardando avaliação final'></div>
											</div>
										</div>
										<div
											className={`
											 pb-2 font-medium  text-[20px]
											
											 ${
													noteData?.status ===
														'INVESTIGADO' ||
													noteData?.status ===
														'CONCLUIDO'
														? 'text-[#00C0F3]'
														: 'text-[#555555]'
												}`}>
											{' '}
											Investigado
											<div className='w-full text-[16px]  h-[24px]'>
												{' '}
												{noteData?.status ===
													'INVESTIGADO' ||
												noteData?.status === 'CONCLUIDO'
													? formatToBrazilianDate(
															noteData
																?.classificacao
																?.prazo_investigacao
													  )
													: ' '}
											</div>
										</div>
									</div>
								</div>
								{/* quarto */}
								<div className='flex xl:mb-0 mb-[40px]'>
									<div className='grid  place-items-center'>
										<div
											className={`text-[#004A80] font-semibold  rounded-full border-[3px] w-[40px] h-[40px] py-0.5 text-[20px] ${
												noteData?.status === 'CONCLUIDO'
													? 'bg-[#00C0F3] border-[#00C0F3]  text-[#F5F5F5F5]'
													: 'text-[#004A80] border-[#CBCBCB] bg-transparent'
											}`}>
											{' '}
											4
										</div>
										<div className='flex items-center'>
											<div
												className={`h-[3px] w-[217px] invisible xl:visible my-auto ${
													noteData?.status ===
													'CONCLUIDO'
														? 'bg-[#00C0F3]'
														: 'bg-[#CBCBCB]'
												}`}></div>
											<div
												id='Aguardando avaliação final'
												className='h-[5px] xl:h-[30px] w-[5px] my-auto '></div>
										</div>
										<div
											className={`
											 pb-2 font-medium  text-[20px]
											
											 ${
													noteData?.status ===
														'CONLCUIDO' ||
													noteData?.status ===
														'CONCLUIDO'
														? 'text-[#00C0F3]'
														: 'text-[#555555]'
												}`}>
											{' '}
											Concluído
											<div className='w-full text-[16px] h-[24px]'>
												{' '}
												{noteData?.status ===
												'CONCLUIDO'
													? formatToBrazilianDate(
															noteData
																?.classificacao
																?.prazo_investigacao
													  )
													: ' '}
											</div>
										</div>
									</div>
								</div>
								<div className='xl:hidden absolute h-[50%] xl:h-[40%] content-center left-[200px] top-0 w-[3px]'>
									<div className=' h-[600px] w-[40px]'>
										<div className=''>
											<div
												className={`h-[135px] rounded-t-lg  w-[4px] mx-auto ${
													noteData?.status ===
														'NOTIFICADO' ||
													noteData?.status ===
														'CLASSIFICADO' ||
													noteData?.status ===
														'INVESTIGADO' ||
													noteData?.status ===
														'CONCLUIDO'
														? 'bg-[#00C0F3]'
														: 'bg-[#CBCBCB]'
												}`}></div>
											<div
												className={`h-[4px] w-[80%] mx-auto rounded-md ${
													noteData?.status ===
														'NOTIFICADO' ||
													noteData?.status ===
														'CLASSIFICADO' ||
													noteData?.status ===
														'INVESTIGADO' ||
													noteData?.status ===
														'CONCLUIDO'
														? 'bg-[#00C0F3]'
														: 'bg-[#CBCBCB]'
												}`}></div>
										</div>
										<div className=''>
											<div
												className={`h-[170px]  w-[4px] mx-auto ${
													noteData?.status ===
														'CLASSIFICADO' ||
													noteData?.status ===
														'INVESTIGADO'
														? 'bg-[#00C0F3]'
														: 'bg-[#CBCBCB]'
												}`}></div>
											<div
												className={`h-[4px] w-[80%] mx-auto rounded-md ${
													noteData?.status ===
														'CLASSIFICADO' ||
													noteData?.status ===
														'INVESTIGADO'
														? 'bg-[#00C0F3]'
														: 'bg-[#CBCBCB]'
												}`}></div>
										</div>
										<div className=''>
											<div
												className={` h-[155px]  w-[4px] mx-auto ${
													noteData?.status ===
														'INVESTIGADO' ||
													noteData?.status ===
														'CONCLUIDO'
														? 'bg-[#00C0F3]'
														: 'bg-[#CBCBCB]'
												}`}></div>
											<div
												className={`h-[4px] w-[80%] mx-auto rounded-md ${
													noteData?.status ===
														'INVESTIGADO' ||
													noteData?.status ===
														'CONCLUIDO'
														? 'bg-[#00C0F3]'
														: 'bg-[#CBCBCB]'
												}`}></div>
										</div>
										<div className=''>
											<div
												className={` h-[130px] rounded-b-lg w-[4px] mx-auto ${
													noteData?.status ===
													'CONCLUIDO'
														? 'bg-[#00C0F3]'
														: 'bg-[#CBCBCB]'
												}`}></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='bg-[#FCFCFC] flex flex-grow border-[2px] border-[#E3E3E3] mx-auto rounded-lg w-[99%] xl:w-[1072px] w-max-[1072px] text-start py-[28px]'>
							<div className=' flex flex-col gap-y-4'>
								<p className='text-[#00C0F3] flex gap-x-[32px] text-[20px] font-medium'>
									<span className='bg-[#00C0F3] h-[25px] w-[8px]'></span>
									{currentSubtitle.title}
								</p>
								<p className='text-[#555555] px-[40px] text-[20px] font-normal'>
									{currentSubtitle.title === 'Notificado' ||
									currentSubtitle.title === 'Investigado' ||
									currentSubtitle.title === 'Classificado'
										? 'Prezado(a) Notificante, sua notificação foi recebida com sucesso e está em análise pelo Núcleo de Segurança do Paciente. Agradecemos por sua notificação.'
										: 'Prezado(a) Notificante, sua notificação foi recebida e avaliada. Após análise criteriosa, foi finalizada de maneira eficaz, contribuindo para melhoria contínua dos nossos processos de trabalho. Agradecemos pela sua contribuição!'}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div>
					{loading ? (
						<div className='loader'>
							<div
								className='flex justify-center h-[80%] content-center align-bottom items-baseline  w-full my-20'
								role='status'>
								<svg
									aria-hidden='true'
									className='w-8 h-8 text-gray-200 animate-spin mt-[100px]  fill-blue-600'
									viewBox='0 0 100 101'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
										fill='currentColor'
									/>
									<path
										d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
										fill='currentFill'
									/>
								</svg>
								<span className='sr-only'>Loading...</span>
							</div>
						</div>
					) : (
						<div>
							{protocolResult === false && isOpen === false ? (
								<div className='top-[400px] z-0 text-gray-400 font-bold text-xl sm:text-2xl w-[100%] mx-auto text-center'>
									<p>
										Nenhuma notificação em curso com este
										protocolo
									</p>
								</div>
							) : null}
						</div>
					)}
				</div>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default NotesHub;
