import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { contatos } from './descricoes e contatos/cegonha';
/* import { Link } from 'react-router-dom'; */

function Cegonha() {
	return (
		<div className=''>
			<NavBar />
			<div className='flex flex-col w-[80%] md:w-[96%] xl:w-[63%] mt-14 mb-14 mx-auto text-wrap '>
				<h1 className='mx-auto md:mx-10 text-[#004A80] font-bold text-3xl md:text-4xl mt-12 mb-12 w-first-letter:text-xl font-roboto'>
					Cegonha Carioca
				</h1>
				<span className='text-justify pb-5 font-roboto mx-auto md:mx-10'>
					Projeto pioneiro no Brasil, implantado em 2011, o Cegonha
					Carioca tem como principais objetivos humanizar e garantir o
					melhor cuidado para a mãe e o bebê, desde o pré-natal até o
					parto, incentivando a realização de exames pré-natais.
					Lançado inicialmente na Rocinha e na área de Santa Cruz,
					Paciência e Sepetiba, o projeto chegou a toda a cidade em
					2012.
					<br />
					<br />
					As gestantes que realizam o pré-natal em unidades da rede
					básica municipal recebem o Passaporte Cegonha, onde fica
					registrada a maternidade de referência para a realização do
					parto. As mulheres cadastradas e um acompanhante visitam a
					maternidade com antecedência, conhecem a estrutura e tiram
					dúvidas sobre o momento da internação e do parto. Essa
					visita é agendada no terceiro trimestre da gestação.
					<br />
					<br />
					Além disso, o pré-natal completo nas clínicas da família e
					centros municipais de saúde do Rio garante às gestantes um
					kit com enxoval para o bebê na visita para conhecer a
					maternidade ou após o parto. O conjunto conta com uma bolsa,
					um trocador, macacão manga curta sem pé, casaco com capuz,
					body manga curta, body manga longa, toalha de banho com
					capuz, manta, duas calças sem pé e dois pares de meia. As
					peças seguem um padrão neutro, sem opção de escolha de cor.
					Se a gestante tiver gêmeos, receberá um kit para cada bebê.
					<br />
					<br />
					Na hora do parto, o Cegonha Carioca também está presente: As
					gestantes atendidas pelo programa contam com uma ambulância
					exclusiva para levá-las até a maternidade. Se você é
					atendida pelo Programa Cegonha Carioca, pode solicitar o
					serviço pelo telefone 2599-4744.
					<br />
					<br />
					Ao chegar à maternidade de referência, toda gestante é
					acolhida e classificada por enfermeiras obstétricas e
					técnicos de enfermagem.
					<br />
					<br />
					No total, 19 maternidades fazem parte do programa, entre
					municipais, estaduais, federais e universitárias.
				</span>

				<div className='flex flex-col  mb-14  text-wrap '>
					<h1 className='mx-auto md:mx-10 text font-bold text-1xl md:text-2xl mt-12 w-first-letter:text-xl font-roboto'>
						Unidades
					</h1>
					<div className='mx-auto md:mx-10 py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
						{contatos.map((hospital, index) => (
							<div
								key={index}
								className='border border-gray-200 p-4 rounded-md'>
								<p className='text-[#004A80] font-bold text-md'>
									{hospital.nome}
								</p>
								<p className='text-gray-600 text-sm'>
									{hospital.endereco}
								</p>
								<p className='text-gray-600 text-sm'>
									{hospital.telefone}
								</p>
							</div>
						))}
					</div>
					{/* <Link>
						<h4 className='mx-auto md:mx-10 text-[#004A80] font-bold text-2xl md:text-xl mt-7 mb-[10%] w-first-letter:text-xl font-roboto'>
							Acessar Documentos do Cegonha Carioca
						</h4>
					</Link> */}
				</div>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Cegonha;
