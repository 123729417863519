import React, { useEffect, useState } from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
/* import axios from 'axios'; */
import { requestUnits } from '../../services/requests';
/* import { Link } from 'react-router-dom'; */
import /* emergenciasPsiquiatricas,
	hospitaisPsiquiatricos,
	hospitalGeriatrico, */
/* 	hospitaisPediatricos,
	hospitaisEspecializados, */
'./descricoes e contatos/especializados';

function Hospitais() {
	const [units, setUnits] = useState([]);
	/* 	const [error, setError] = useState(null); */

	const fetchUnits = async () => {
		const response = await requestUnits();

		if (response.error) {
			console.log(response.error);
			/* alert(response.error); */
		} else {
			let groupedUnits = () => {
				return response.reduce((acc, unidade) => {
					const tipo = unidade.tipo_unidade;
					if (!acc[tipo]) {
						acc[tipo] = [];
					}
					acc[tipo].push(unidade);
					return acc;
				}, {});
			};
			setUnits(groupedUnits());
		}
	};

	useEffect(() => {
		fetchUnits();
	}, []);

	return (
		<div className=''>
			<NavBar />
			<div className='flex flex-col w-[80%] md:w-[96%] xl:w-[63%] mb-14 mt-14 mx-auto text-wrap'>
				<h1 className='mx-auto md:mx-10 text-[#004A80] font-bold text-3xl md:text-4xl mt-12 mb-12 w-first-letter:text-xl font-roboto'>
					Hospitais Especializados
				</h1>
				<span className='text-justify pb-5 font-roboto md:mx-10'>
					A rede dos hospitais gerais e especializados é destinada à
					prestação de assistência à saúde em finalidades específicas.
					É composta por nove unidades hospitalares que concentram
					suas ações em atendimentos e consultas ambulatoriais,
					internações e cirurgias em diversas especialidades, como:
					clínica médica, infectologia, pediatria, pneumologia,
					terapia intensiva adulto, geriatria, cirurgia de urologia,
					ginecologia, cirurgia geral, cirurgia plástica reparadora,
					cirurgia ortopédica, entre outras.
				</span>
				<span className='text-justify pb-5 font-roboto md:mx-10'>
					O acesso a esses serviços é pelo Sistema de Regulação
					(SISREG), sendo necessário buscar atendimento na unidade de
					Atenção Primária mais próxima de sua residência.
				</span>
				<h1 className='ml-0 md:mx-10 text-[#004A80] font-bold text-2xl md:text-2xl mt-12  w-first-letter:text-xl text-start font-roboto'>
					Hospitais Especializados
				</h1>
				<div className='mx-auto md:mx-10 py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
					{units['Hospital Especializado'] ? (
						units['Hospital Especializado'].map(
							(hospital, index) => (
								<div
									key={index}
									className='border border-gray-200 p-4 rounded-md'>
									<p className='text-[#004A80] font-bold text-md'>
										{hospital.nome}
									</p>
									<p className='text-gray-600 text-sm mt-2'>
										Endereço:. {hospital.endereco},{' '}
										{hospital.bairro}
									</p>
									<p className='text-gray-600 text-sm mt-1'>
										{hospital.telefone === null
											? 'Tel:.  não informado'
											: 'Tel:. ' + hospital.telefone}
									</p>
								</div>
							)
						)
					) : (
						<div className=' text-gray-500 font-bold font-roboto'>
							Nenhuma Unidade Foi Encontrada.
						</div>
					)}
				</div>
				{/* <h1 className='ml-0 md:mx-10 text-[#004A80] font-bold text-2xl md:text-2xl mt-12  w-first-letter:text-xl text-start font-roboto'>
					Hospitais Pediátricos
				</h1>
				<div className='mx-auto md:mx-10 py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
					{units['Hospital Pediátrico'] ? (
						units['Hospital Pediátrico'].map((hospital, index) => (
							<div
								key={index}
								className='border border-gray-200 p-4 rounded-md'>
								<p className='text-[#004A80] font-bold text-md'>
									{hospital.nome}
								</p>
								<p className='text-gray-600 text-sm mt-2'>
									{'Endereço: ' + hospital.endereco}
								</p>
								<p className='text-gray-600 text-sm mt-1'>
									{hospital.telefone === null
										? 'Tel:.  não informado'
										: 'Tel:. ' + hospital.telefone}
								</p>
							</div>
						))
					) : (
						<div className=' text-gray-500 font-bold font-roboto'>
							Nenhuma Unidade Foi Encontrada.
						</div>
					)}
				</div> */}

				{/* <h1 className='ml-0 md:mx-10 text-[#004A80] font-bold text-2xl md:text-2xl mt-12  w-first-letter:text-xl text-start font-roboto'>
					Hospital Geriátrico
				</h1>
				<div className='mx-auto md:mx-10 py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
					{hospitalGeriatrico.map((hospital, index) => (
						<div
							key={index}
							className='border border-gray-200 p-4 rounded-md'>
							<p className='text-[#004A80] font-bold text-md'>
								{hospital.nome}
							</p>
							<p className='text-gray-600 text-sm'>
								{'Endereço: ' + hospital.endereco}
							</p>
							<p className='text-gray-600 text-sm'>
								{hospital.telefone}
							</p>
						</div>
					))}
				</div> */}
				{/* 
				<h1 className='ml-0 md:mx-10 text-[#004A80] font-bold text-2xl md:text-2xl mt-12  w-first-letter:text-xl text-start font-roboto'>
					Hospitais e Institutos Psiquiátricos
				</h1>
				<div className='mx-auto md:mx-10 py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
					{hospitaisPsiquiatricos.map((hospital, index) => (
						<div
							key={index}
							className='border border-gray-200 p-4 rounded-md'>
							<p className='text-[#004A80] font-bold text-md'>
								{hospital.nome}
							</p>
							<p className='text-gray-600 text-sm'>
								{'Endereço: ' + hospital.endereco}
							</p>
							<p className='text-gray-600 text-sm'>
								{hospital.telefone}
							</p>
						</div>
					))}
				</div> */}

				{/* <h1 className='ml-0 md:mx-10 text-[#004A80] font-bold text-2xl md:text-2xl mt-12  w-first-letter:text-xl text-start font-roboto'>
					Emergências Psiquiátricas
				</h1>
				<div className=' md:mx-10 py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
					{emergenciasPsiquiatricas.map((hospital, index) => (
						<div
							key={index}
							className='border border-gray-200 p-4 rounded-md'>
							<p className='text-[#004A80] font-bold text-md'>
								{hospital.nome}
							</p>
							<p className='text-gray-600 text-sm'>
								{'Endereço: ' + hospital.endereco}
							</p>
							<p className='text-gray-600 text-sm'>
								{hospital.telefone}
							</p>
						</div>
					))}
				</div> */}
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Hospitais;
