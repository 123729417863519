import React from 'react';
import INSTAGRAM from '../images/logo insta.png';
import logo_saude from '../images/RIOLOGO.png';
function Footer() {
	return (
		<footer className='bg-[#13335A] text-[6px] md:text-lg  bottom-0 md:bottom-0  font-Inter text-white py-4 text-center  w-full'>
			<div className=' xl:flex mx-auto xl:justify-between w-[100%] sm:w-[95%]  items-center max-w-[1136px] min-h-fit   xl:h-[65px]  ml-2 md:mx-auto'>
				<div className='xl:text-start block text-center text-[12px] '>
					<div className=' text-[#00C0F3] font-medium'>Endereço</div>
					<div className='mb-2'>
						<p className='h-auto break-words'>
							Centro Administrativo São Sebastião
						</p>
						<p className='h-auto break-words'>
							Rua Afonso Cavalcanti, 455 - Cidade Nova, Rio de
							Janeiro - RJ
						</p>
						<p className='h-auto break-words'>
							Bloco 1 - Sala 801 / CEP: 20211-110
						</p>
					</div>
				</div>
				<div className='xl:text-start  text-[12px] text-center'>
					<p className=' h-auto break-words'>
						Secretaria Municipal de Saúde do Rio de Janeiro
					</p>
					<p className='h-auto break-words'>
						Subsecretaria de Atenção Hospitalar, Urgência e
						Emergência
					</p>
				</div>
				<div className='text-start  text-[12px]'>
					<div className='flex justify-center mt-4 xs:mt-0 my-4 '>
						<a
							target='_blank'
							rel='noreferrer'
							href='https://www.instagram.com/subhue.smsrio?igsh=MXFqdHJ1NjBybGo0bg=='>
							<img
								alt='icone de instagram'
								src={INSTAGRAM}></img>
						</a>
					</div>
				</div>
				<div className='text-start   text-[12px]'>
					<div className='flex justify-center'>
						<img
							alt='logo da rio saude'
							src={logo_saude}></img>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
