import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { FaLocationPin } from 'react-icons/fa6';
import { requestMapAtt } from '../../services/requests';
import Cer from '../../images/Monitoramento/CER.png';
import Upa from '../../images/Monitoramento/UPA.png';
import Emergencia from '../../images/Monitoramento/EMERGENCIA.png';
import Hospitais from '../../images/Monitoramento/HOSPITAIS.png';
import Maternidade from '../../images/Monitoramento/MATERNIDADE.png';
import Pediatrico from '../../images/Monitoramento/PEDIATRICO.png';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import Cookies from 'js-cookie';

function Monitoramento() {
	const [showInfo, setShowInfo] = useState(true);
	const [showAlert, setShowAlert] = useState(true);
	const [lastAtt, setLastAtt] = useState('Carregando...');

	const attRequest = async () => {
		const att = await requestMapAtt();
		if (att) {
			setLastAtt(att);
		}
	};

	useEffect(() => {
		const localData = Cookies.get('user');
		attRequest();

		if (localData) {
			if (JSON.parse(Cookies.get('user')).cargo === 'desenvolvedor') {
				setShowAlert(false);
			}
		}
	}, []);

	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			{showAlert ? (
				<section className='bg-[#004A80] mt-[150px] rounded-md w-[90%] sm:w-[70%] md:w-[60%] lg:w-[50%]  mx-auto my-auto'>
					<div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
						<div className='mx-auto max-w-screen-sm text-center'>
							<h1 className='mb-4 text-white text-5xl sm:text-6xl lg:text-7xl tracking-tight font-extrabold text-primary-600'>
								401
							</h1>
							<p className='mb-10 text-2xl sm:text-3xl md:text-4xl tracking-tight font-bold text-slate-100'>
								Acesso Negado
							</p>
							<p className='mb-7 text-sm sm:text-base md:text-lg font-light text-gray-300'>
								Você não possui permissão para acessar este
								campo. Entre em contato com o administrador(a)
								da sua unidade para solicitação de acesso.
							</p>
							<a
								href='/login'
								className='inline-flex hover:scale-105 transition-transform text-gray-300 bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4'>
								Volte para a Tela de Login
							</a>
						</div>
					</div>
				</section>
			) : (
				<div className='flex flex-col min-h-screen'>
					<div className='bg-[#F0F4F8] mt-[80px]  h-[110px] w-full font-inter '>
						<div className='w-full flex  justify-center h-full align-middle items-center text-[#004A80] font-semibold text-[32px]  p-6 sm:p-0'>
							Monitoramento de Leitos SUBHUE
						</div>
					</div>

					<div className=' font-inter text-center flex-grow mt-14'>
						<div className='mx-auto items-center w-[95%] max-w-[1440px] bg-gray'>
							<div className='flex justify-end rounded-md  '>
								<div className=' flex justify-between flex-grow  max-w-[89%]'>
									<button
										className={` relative transition-all duration-300  ${
											showInfo ? '  ' : 'text-[#004A80]'
										}`}
										type='button'
										onClick={() => {
											setShowInfo(!showInfo);
										}}>
										<IoIosInformationCircleOutline />

										<div
											className={`text-[#757575] transition-all h-[85px] w-[260px] duration-300 absolute border-[1px] border-gray-300 bg-[#E3E3E3] rounded-md  ${
												showInfo
													? 'opacity-0 scale-90  pointer-events-none'
													: 'opacity-100 scale-100 '
											}`}
											style={{
												transition: 'all 0.3s ease',
											}}>
											<div className='w-full p-1 text-start text-[10px] '>
												<div className='font-semibold mb-4'>
													Taxa de Ocupação Hospitalar:
													<p className='font-normal'>
														Leitos Ocupados /(Leitos
														orginários + Leitos
														Extras)
													</p>
												</div>
												<div className='font-semibold'>
													Taxa de Ocupação Emergência:
													<p className='font-normal'>
														Leitos Ocupados /(Leitos
														ordinários)
													</p>
												</div>
											</div>
										</div>
									</button>
									<p className='text-[12px] text-[#757575]'>
										Ultima atualização:{' '}
										{lastAtt !== 'Carregando...'
											? lastAtt[0]['ultima_atualizacao']
											: 'Carregando...'}
									</p>
								</div>
							</div>
						</div>

						<div className=' min-h-[604px] mb-10 mt-2  w-[90%] sm:w-[95%] mx-auto items-center  max-w-[1500px]'>
							<div className='w-full'></div>
							<div className='flex justify-between h-[604px]  w-full'>
								<div className=' text-start p-1 pr-4'>
									<div className='w-[132px] mx-auto'>
										<p className='text-[#757575] text-[14px] mb-2'>
											Taxa de ocupação:
										</p>

										<div className='flex flex-col gap-2'>
											<div className='flex items-center text-[14px] text-[#47AD4B] gap-1 font-medium'>
												<div className='flex items-center justify-center h-full text-[15px]'>
													<FaLocationPin />
												</div>
												<p className='font-inter text-[13px]'>
													{'<50%'}
												</p>
											</div>
											<div>
												<div className='flex items-center text-[14px] text-[#ED8338] font-medium gap-1 '>
													<div className='flex items-center justify-center h-full text-[15px]'>
														<FaLocationPin />
													</div>
													<p className='font-inter text-[13px]'>
														{'<80%'}
													</p>
												</div>
											</div>
											<div>
												<div className='flex items-center text-[14px] text-[#C94444] font-medium gap-1 '>
													<div className='flex items-center justify-center h-full text-[15px]'>
														<FaLocationPin />
													</div>
													<p className='font-inter text-[13px]'>
														{'≥80%'}
													</p>
												</div>
											</div>
										</div>

										<div className='w-full flex justify-center'>
											<div className='h-[1px] w-[90%] bg-[#D9D9D9] my-5'></div>
										</div>
										<div className='grid gap-4 justify-center text-[14px] text-[#858585] font-medium'>
											<div className='flex gap-2 justify-between'>
												<p>CER</p>
												<img
													alt='icone do CER'
													className='max-w-[30px] h-auto object-contain'
													src={Cer}
												/>
											</div>
											<div className='flex gap-2 justify-between'>
												<p>UPA</p>
												<img
													alt='icone do CER'
													className='max-w-[30px] h-auto object-contain'
													src={Upa}
												/>
											</div>
											<div className='flex gap-2 justify-between'>
												<p>Maternidade</p>
												<img
													alt='icone do CER'
													className='max-w-[22px] h-auto object-contain'
													src={Maternidade}
												/>
											</div>
											<div className='flex gap-2 justify-between'>
												<p>Hospital Pediátrico</p>
												<img
													alt='icone do CER'
													className='max-w-[22px] h-auto object-contain'
													src={Pediatrico}
												/>
											</div>
											<div className='flex gap-2 justify-between'>
												<p>Hospital Especializado</p>
												<img
													alt='icone do CER'
													className='max-w-[17px] h-auto object-contain'
													src={Hospitais}
												/>
											</div>
											<div className='flex gap-2 justify-between'>
												<p>Hospital Emergência</p>
												<img
													alt='icone do CER'
													className='max-w-[23px] h-auto object-contain '
													src={Emergencia}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='min-h-[604px] flex-grow  max-w-[1350px] overflow-x-hidden  bg-[#C4C4C4] rounded-md p-2'>
									<div
										style={{
											width: '100%',
											height: '100%',
										}}>
										<iframe
											src='http://46.202.146.125:8051/'
											style={{
												width: '100%',
												height: '100%',
												border: 'none',
												overflow: 'hidden',
												borderRadius: '5px',
												// Impede a barra de rolagem
											}}
											allowFullScreen
											title='Embedded Content'></iframe>
									</div>
								</div>
							</div>
							<div className='w-[90%] sm:w-[100%] mx-auto items-center  max-w-[1500px] bg-gray flex  justify-end'>
								<p className='text-[12px] text-[#757575]'>
									Fonte: Plataforma SMS Rio{' '}
								</p>
							</div>
						</div>
					</div>
				</div>
			)}
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Monitoramento;
