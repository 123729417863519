import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { FaMapMarkerAlt, FaClock, FaCalendar } from 'react-icons/fa';
import { requestEvents } from '../services/requests';
import { use } from 'react';

const monthNames = [
	'Janeiro',
	'Fevereiro',
	'Março',
	'Abril',
	'Maio',
	'Junho',
	'Julho',
	'Agosto',
	'Setembro',
	'Outubro',
	'Novembro',
	'Dezembro',
];

/* const eventsData = [
	{
		id: 1,
		titulo: 'Luta Contra a Aids',
		descricao: 'Dia Mundial de Luta Contra a Aids',
		categoria: 'Conferencia',
		categoria_display: 'Conferência',
		local: 'Centro Administrativo Municipal São Sebastião',
		data: '2024-12-01',
		horario: '00:00',
	},
	{
		id: 2,
		titulo: 'Dia Pan-Americano de Saúde',
		descricao: 'Dia Pan-Americano de Saúde',
		categoria: 'Palestra',
		categoria_display: 'Palestra',
		local: 'Centro Administrativo Municipal São Sebastião',
		data: '2024-12-02',
		horario: '00:00',
	},
	{
		id: 3,
		titulo: 'Luta das Pessoas com Deficiência',
		descricao: 'Dia Internacional de Luta das Pessoas com Deficiência',
		categoria: 'Palestra',
		categoria_display: 'Palestra',
		local: 'Centro Administrativo Municipal São Sebastião',
		data: '2024-12-03',
		horario: '00:00',
	},
	{
		id: 4,
		titulo: 'Dia do Fonoaudiólogo',
		descricao: 'Palestra Sobre Dia do Fonoaudiólogo',
		categoria: 'Palestra',
		categoria_display: 'Palestra',
		local: 'Centro Administrativo Municipal São Sebastião',
		data: '2024-12-09',
		horario: '00:00',
	},
	{
		id: 5,
		titulo: 'Dia da Criança com Deficiência',
		descricao: 'Dia da Criança com Deficiência',
		categoria: 'Palestra',
		categoria_display: 'Palestra',
		local: 'Centro Administrativo Municipal São Sebastião',
		data: '2024-12-09',
		horario: '00:00',
	},
	{
		id: 6,
		titulo: 'Dia Internacional dos Direitos Humanos',
		descricao: 'Dia Internacional dos Direitos Humanos',
		categoria: 'Palestra',
		categoria_display: 'Palestra',
		local: 'Centro Administrativo Municipal São Sebastião',
		data: '2024-12-10',
		horario: '00:00',
	},
	{
		id: 7,
		titulo: 'Inicio do Verão',
		descricao: 'Protocolo para o Inicio do Verão',
		categoria: 'Palestra',
		categoria_display: 'Palestra',
		local: 'Centro Administrativo Municipal São Sebastião',
		data: '2024-12-21',
		horario: '00:00',
	},
	{
		id: 8,
		titulo: 'Dia Internacional da Biodiversidade',
		descricao: 'Dia Internacional da Biodiversidade',
		categoria: 'Conferencia',
		categoria_display: 'Conferência',
		local: 'Centro Administrativo Municipal São Sebastião',
		data: '2024-12-29',
		horario: '00:00',
	},
]; */

function Agenda() {
	const [currentDate, setCurrentDate] = useState(new Date());
	const [selectedCategory, setSelectedCategory] = useState('Todos');
	const [eventsData, setEventsData] = useState([]);
	const [filters, setFilters] = useState([]);

	const handleNextMonth = () => {
		setCurrentDate((prevDate) => {
			const nextDate = new Date(prevDate);
			nextDate.setMonth(nextDate.getMonth() + 1);
			return nextDate;
		});
	};

	const handlePreviousMonth = () => {
		setCurrentDate((prevDate) => {
			const previousDate = new Date(prevDate);
			previousDate.setMonth(previousDate.getMonth() - 1);
			return previousDate;
		});
	};

	const month = monthNames[currentDate.getMonth()];
	const year = currentDate.getFullYear();

	const filteredEvents = eventsData.filter((event) => {
		const eventDate = new Date(`${event.data}T12:00:00`);
		const isSameMonth =
			eventDate.getMonth() === currentDate.getMonth() &&
			eventDate.getFullYear() === currentDate.getFullYear();
		const isCategoryMatch =
			selectedCategory === 'Todos' ||
			event.categoria === selectedCategory;

		return isSameMonth && isCategoryMatch;
	});

	useEffect(() => {
		requestEvents().then((data) => {
			let fit = [];
			if (!data.error) {
				data.forEach((event) => {
					if (!fit.includes(event.categoria)) {
						fit.push(event.categoria);
					}
				});
				setFilters(fit);
				setEventsData(data);
			} else {
				console.log('Erro ao carregar eventos');
			}
		});
	}, []);

	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			<div className='text-center flex-grow mx-auto w-[90%] max-w-[1136px] mt-[92px] md:mt-[92px]'>
				<h1 className='text-[#004A80] font-semibold flex items-center justify-center text-3xl md:text-5xl mb-5 mt-6 font-roboto'>
					Agenda de Eventos
				</h1>

				<hr className='w-20 h-1 md:h-2 mx-auto my-3 bg-[#4dc6ff]'></hr>

				{/* Carrossel */}
				<div className='mt-10 bg-[#004A80] h-28 rounded-md flex items-center justify-center px-4 '>
					<button
						type='button'
						onClick={handlePreviousMonth}
						className='text-white w-[2rem] scale-125 font-medium rounded-md text-sm transition duration-300 ease-in-out hover:scale-150 p-2'>
						<IoIosArrowBack className='scale-150' />
					</button>
					<div className='grid place-items-center mx-5 md:mx-10 h-full font-bold text-lg md:text-[25px] text-gray-100'>
						{month} de {year}
					</div>
					<button
						type='button'
						onClick={handleNextMonth}
						className='text-white w-[2rem] font-medium rounded-md text-sm transition duration-300 ease-in-out scale-125 hover:scale-150 p-2'>
						<IoIosArrowForward className='scale-150' />
					</button>
				</div>

				<div className='flex justify-center'>
					<div className='flex flex-wrap justify-around my-8 w-full md:w-[40%] gap-2'>
						{['Todos', ...filters].map((category) => (
							<button
								key={category}
								onClick={() => setSelectedCategory(category)}
								className={`px-4 md:px-6 h-10 rounded-sm ${
									selectedCategory === category
										? 'bg-[#4dc6ff]'
										: 'bg-[#004A80]'
								} hover:bg-[#4dc6ff] text-white font-semibold transition duration-200 ease-in-out`}>
								{category}
							</button>
						))}
					</div>
				</div>
				<div className='flex flex-wrap justify-center gap-5 gap-y-10 my-10'>
					{filteredEvents.map((event) => (
						<div
							key={event.id}
							className='w-full max-w-xs sm:w-[300px] rounded-sm shadow-xl bg-slate-50'>
							<div className='flex flex-grow justify-center px-2 place-items-center min-h-[100px] text-white font-bold text-md w-full rounded-sm bg-[#004A80]'>
								{event.titulo}
							</div>
							<div className='p-4'>
								<div className='text-start font-bold text-xs text-[#3882b8]'>
									{event.descricao}
								</div>
								<div className='my-3 mb-10'>
									<div className='flex items-center my-2 text-start text-[13px] font-semibold text-[#4a4e52]'>
										<FaCalendar
											color='#004A80'
											className='mr-1'
										/>
										{(() => {
											const [ano, mes, dia] =
												event.data.split('-');
											return `${dia} de ${
												monthNames[
													parseInt(mes, 10) - 1
												]
											} de ${ano}`;
										})()}
									</div>
									<div className='flex items-center my-2 text-start text-[13px] font-semibold text-[#4a4e52]'>
										<FaClock
											color='#004A80'
											className='mr-1'
										/>
										{/* {console.log(event.horario)} */}
										{event.horario.slice(0, 5)}
									</div>
									<div className='flex items-start my-2 text-start text-[13px] font-semibold text-[#4a4e52]'>
										<FaMapMarkerAlt
											color='#004A80'
											className='mr-1 mt-1'
										/>
										{event.local}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Agenda;
