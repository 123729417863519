import React, { useEffect, useRef, useState } from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
/* import {
	UPAS,
	CER,
	hospitaisEmergencia,
} from './descricoes e contatos/urgencia'; */

import { requestUnits } from '../../services/requests';

function Urgencia() {
	const [isOpen, setIsOpen] = useState('');
	const [units, setUnits] = useState([]);
	const contentRef = useRef(null);

	const toggleAccordion = (event) => {
		const value = event.target.value;
		if (isOpen.includes(value)) {
			setIsOpen('');
		} else {
			setIsOpen(`${value}/open`);
		}
	};

	/* function handleHide(evt) {
		let status = document.getElementById(`${evt.target.value}`).className;

		if (status === 'visible') {
			document.getElementById(`${evt.target.value}`).className = 'hidden';
		} else {
			document.getElementById(`${evt.target.value}`).className =
				'visible';
		}
	} */

	const fetchUnits = async () => {
		const response = await requestUnits();

		if (response.error) {
			console.log(response.error);
			/* alert(response.error); */
		} else {
			let groupedUnits = () => {
				return response.reduce((acc, unidade) => {
					const tipo = unidade.tipo_unidade;
					if (!acc[tipo]) {
						acc[tipo] = [];
					}
					acc[tipo].push(unidade);
					return acc;
				}, {});
			};
			/* console.log(groupedUnits()); */
			setUnits(groupedUnits());
		}
	};

	useEffect(() => {
		fetchUnits();
	}, []);
	return (
		<div>
			<NavBar />
			{/* {console.log(isOpen)} */}
			<div className='flex flex-col w-[80%] md:w-[96%] xl:w-[63%] mb-14 mt-14 mx-auto text-wrap'>
				<h1 className='mx-auto md:mx-10 text-center md:text-start text-[#004A80] font-bold text-3xl md:text-4xl mt-12 mb-12 w-first-letter:text-xl font-roboto'>
					Urgência e Emergência
				</h1>
				<div className='text-justify pb-5 font-roboto md:mx-10'>
					A rede de urgência e emergência do município é formada por
					três diferentes tipos de unidades: UPA (Unidade de Pronto
					Atendimento), CER (Coordenação de Emergência Regional) e
					hospitais para grande emergência. Cada uma tem um papel
					específico na rede SUS, com diferentes perfis de
					atendimento. Essa segmentação é necessária para distribuir
					os pacientes entre as unidades conforme o grau de
					complexidade dos quadros, evitando assim sobrecarga de
					algumas, reduzindo o tempo de espera e permitindo que os
					hospitais possam se dedicar aos casos de maior gravidade e
					traumas.
					<br />
					<br />A rede de urgência e emergência da Secretaria
					Municipal de Saúde - que inclui UPAs, CERs e hospitais de
					emergência ou pronto atendimento - trabalham com a política
					de acolhimento e classificação de risco e atendem de acordo
					com seu perfil específico. Na entrada de cada unidade, o
					paciente é avaliado por profissionais, que analisam os casos
					individualmente. Os que estão em estado mais grave são
					atendidos antes dos menos graves, independentemente da ordem
					de chegada.
				</div>
				<h1 className='mx-auto md:mx-10 text-[#004A80] font-bold text-2xl md:text-2xl mt-12 mb-3 text-center md:text-start font-roboto'>
					Unidades de Pronto Atendimento (UPA 24 Horas)
				</h1>
				<div className='text-justify pb-5 font-roboto md:mx-10'>
					As UPAs são estruturas de complexidade intermediária entre
					as Unidades Básicas de Saúde e as portas de urgência e
					emergência hospitalares. Em conjunto com os CERs e os
					hospitais, as unidades compõem uma rede organizada de
					Atenção às Urgências.
					<br />
					<br />
					As UPAs trabalham com classificação de risco, avaliando
					todos os pacientes e priorizando os atendimentos de
					urgência. O principal objetivo deste tipo de unidade é
					concentrar o atendimento aos pacientes menos graves, e
					também alguns de maior gravidade, para que os hospitais
					possam se dedicar àquelas situações em que haja risco
					iminente de morte.
					<br />
					<br />
					No total, o município do Rio conta com 14 UPAs, que atendem
					mais de três milhões de pessoas. As unidades realizam, em
					média, cem mil atendimentos mensais.
				</div>
				<button
					value='UPA'
					type='button'
					onClick={(e) => toggleAccordion(e)}
					className='block w-[60%] md:w-[20%] mx-auto mb-[2%] text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1 md:py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
					VER UNIDADES
				</button>
				<div
					id='upa'
					/* className='hidden' */
				>
					{Object.keys(units).length > 0 ? (
						<div
							ref={contentRef}
							style={{
								maxHeight:
									isOpen === 'UPA/open'
										? `${
												contentRef.current
													.scrollHeight + 1000
										  }px`
										: '0px',
							}}
							className='overflow-hidden my-2  transition-all duration-1000 ease-in-out mx-auto md:mx-10  grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
							{units.UPA.map((upa, index) => (
								<div
									key={index}
									className='border border-gray-200 p-4 rounded-md'>
									<p className='text-[#004A80] font-bold text-md'>
										{upa.nome}
									</p>
									<p className='text-gray-600 text-sm'>
										Endereço:. {upa.endereco}, {''}{' '}
										{upa.bairro}
									</p>
									<p className='text-gray-600 text-sm'>
										Tel.: {upa.telefone}
									</p>
								</div>
							))}
						</div>
					) : (
						<div id='UPA'>
							<div
								ref={contentRef}
								style={{
									maxHeight:
										isOpen === 'UPA/open'
											? `${contentRef.current.scrollHeight}px`
											: '0px',
								}}
								className='overflow-hidden  transition-all duration-500 ease-in-out mx-auto md:mx-10  grid '>
								<div className='border border-gray-200 p-4 rounded-md'>
									<p className='text-[#004A80] h-[100px] text-center font-bold text-md'>
										{' '}
										UNIDADES NÃO ENCONTRADAS . . .
									</p>
								</div>
							</div>
						</div>
					)}
				</div>

				<h1 className='mx-auto md:mx-10 text-[#004A80] font-bold text-2xl md:text-2xl mt-12 mb-3 text-center md:text-start font-roboto'>
					Centro de Emergência Regional (CER)
				</h1>
				<div className='text-justify pb-5 font-roboto md:mx-10'>
					Os Centros de Emergência Regional (CER) são um modelo de
					atendimento de urgência e emergência clínica que compõem uma
					rede organizada de atenção ao carioca. Elas funcionam 24
					horas e são instaladas sempre ao lado de um grande hospital
					de emergência, absorvendo o atendimento dos casos de menor
					complexidade e deixando a unidade hospitalar liberada para
					se dedicar aos casos de traumas e cirúrgicos, sua real
					vocação.
					<br />
					<br />
					Essas unidades realizam o acolhimento e assistência aos
					pacientes com necessidades de pronto atendimento clínico,
					referenciando-os, se for o caso, para a rede básica de
					atenção à saúde, para a rede especializada ou para
					internação, de acordo com a necessidade.
					<br />
					<br />
					Os CERs possuem um complexo sistema de regulação próprio,
					que permite o controle, distribuição dos leitos e serviços
					da região, garantindo atendimento, internação e acesso aos
					serviços de saúde. Permite atendimento integral para o
					paciente na área de emergência, com a realização de toda a
					assistência médica e dos exames necessários.
					<br />
					<br />O município do Rio conta atualmente com sete CERs:
					Centro, Barra da Tijuca, Leblon, Ilha do Governador e Santa
					Cruz, Realengo e Campo Grande.
				</div>
				<button
					value='CER'
					type='button'
					onClick={(e) => toggleAccordion(e)}
					className='block w-[60%] md:w-[20%] mx-auto mb-[2%] text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1 md:py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 '>
					VER UNIDADES
				</button>
				{Object.keys(units).length > 0 ? (
					<div id='CER'>
						<div
							ref={contentRef}
							style={{
								maxHeight:
									isOpen === 'CER/open'
										? `${
												contentRef.current
													.scrollHeight + 1000
										  }px`
										: '0px',
							}}
							className='overflow-hidden mt-3  transition-all duration-1000 ease-in-out mx-auto md:mx-10  grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
							{units.CER.map((cer, index) => (
								<div
									key={index}
									className='border border-gray-200 p-4 rounded-md'>
									<p className='text-[#004A80] font-bold text-md'>
										{cer.nome}
									</p>
									<p className='text-gray-600 text-sm'>
										Endereço:. {cer.endereco}, {''}{' '}
										{cer.bairro}
									</p>
									<p className='text-gray-600 text-sm'>
										{cer.telefone === 'nan'
											? 'Tel.: não informado'
											: 'Tel.: ' + cer.telefone}
									</p>
								</div>
							))}
						</div>
					</div>
				) : (
					<div id='CER'>
						<div
							ref={contentRef}
							style={{
								maxHeight:
									isOpen === 'CER/open'
										? `${contentRef.current.scrollHeight}px`
										: '0px',
							}}
							className='overflow-hidden  transition-all duration-500 ease-in-out mx-auto md:mx-10  grid '>
							<div className='border border-gray-200 p-4 rounded-md'>
								<p className='text-[#004A80] h-[100px] text-center font-bold text-md'>
									{' '}
									UNIDADES NÃO ENCONTRADAS . . .
								</p>
							</div>
						</div>
					</div>
				)}
				<h1 className='mx-auto md:mx-10 text-[#004A80] font-bold text-2xl md:text-2xl mt-12 mb-3 text-center md:text-start font-roboto'>
					Hospitais de Emergência e Pronto Atendimento
				</h1>
				<div className='text-justify pb-5 font-roboto md:mx-10'>
					Na estratégia de atendimento do SUS, para cada tipo de
					enfermidade e grau de complexidade, há um local de
					referência hierarquizado para o serviço. Os hospitais de
					emergência e grande porte se dedicam às emergências mais
					graves, aos casos cirúrgicos e às internações, salvando
					vidas em risco imediato.
					<br />
					<br />A Secretaria Municipal de Saúde conta com oito
					hospitais de emergência nas principais áreas de planejamento
					da cidade: Centro, Méier, Leblon, Barra da Tijuca, Santa
					Cruz, lha do Governador, Realengo e Campo Grande. Outras
					duas unidades de pronto atendimento, em Botafogo e Irajá,
					completam a rede hospitalar de urgência e emergência.
				</div>
				<button
					value='HME'
					type='button'
					onClick={(e) => toggleAccordion(e)}
					className='block w-[60%] md:w-[20%] mx-auto mb-[2%] text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1 md:py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
					VER UNIDADES
				</button>
				{Object.keys(units).length > 0 ? (
					<div id='HME'>
						<div
							ref={contentRef}
							style={{
								maxHeight:
									isOpen === 'HME/open'
										? `${
												contentRef.current
													.scrollHeight + 1000
										  }px`
										: '0px',
							}}
							className='overflow-hidden  mt-3  transition-all duration-1000 ease-in-out mx-auto md:mx-10  grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
							{units['Hospital Emergência'].map(
								(hospital, index) => (
									<div
										key={index}
										className='border border-gray-200 p-4 rounded-md'>
										<p className='text-[#004A80] font-bold text-md'>
											{hospital.nome}
										</p>
										<p className='text-gray-600 text-sm'>
											Endereço:. {hospital.endereco},{' '}
											{hospital.bairro}
										</p>
										<p className='text-gray-600 text-sm'>
											{hospital.telefone}
										</p>
									</div>
								)
							)}
						</div>
					</div>
				) : (
					<div id='HME'>
						<div
							ref={contentRef}
							style={{
								maxHeight:
									isOpen === 'HME/open'
										? `${contentRef.current.scrollHeight}px`
										: '0px',
							}}
							className='overflow-hidden  transition-all duration-500 ease-in-out mx-auto md:mx-10  grid '>
							<div className='border border-gray-200 p-4 rounded-md'>
								<p className='text-[#004A80] h-[100px] text-center font-bold text-md'>
									{' '}
									UNIDADES NÃO ENCONTRADAS . . .
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
			<Footer />
		</div>
	);
}
export default Urgencia;
