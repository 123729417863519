import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestUnits, requestUnitToken } from '../services/requests';
import Footer from '../../src/components/Footer';
import NavBar from '../components/NavBar';

function NotesLogin() {
	const [token, setToken] = useState('');
	const [showAlert, setShowAlert] = useState(false);
	const navigate = useNavigate();
	const inputRef = useRef(null);

	const handleInputChange = (e) => {
		const value = e.target.value;
		setToken(value);
	};

	const handleLogin = async () => {
		const requestLog = await requestUnitToken();
		const filter = requestLog.filter(
			(unidade) => unidade.token_notificacao === token
		);
		const requestResult =
			filter.length > 0 ? filter[0].nome : { error: 'Token inválido' };
		if (!requestResult.error) {
		} else {
			setShowAlert(true);
		}

		if (!requestResult.error) {
			if (token.length === 7) {
				navigate(`/notificacoes/${requestResult}`);
			} else {
				setShowAlert(true);
			}
		}
	};

	useEffect(() => {}, []);

	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			<div className='text-center flex-grow mt-10'>
				<section>
					<div className='flex flex-col items-center mb-[200px]  sm:mb-[100px] mt-20 mx-auto md:h-full w-[80%] sm:w-[100%] lg:py-0'>
						<div className='md:w-full bg-white rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0'>
							<div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
								<h1 className='text-2xl font-bold leading-tight tracking-tight text-slate-700  md:text-3xl'>
									Insira o Token referente a sua Unidade
								</h1>
								<p className='text-md font-semibold leading-tight tracking-tight text-slate-500  '>
									ou escaneie o QR code localizado na sua
									unidade
								</p>
								<form
									className='space-y-4 md:space-y-6'
									onSubmit={handleLogin}>
									<div className='relative'>
										<input
											ref={inputRef}
											type='text'
											placeholder='Token'
											className='bg-gray-50 border border-gray-300 text-gray-700 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
											value={token}
											onChange={handleInputChange}
											required
										/>
										<button
											type='button'
											onClick={handleLogin}
											disabled={token.length < 7}
											className='text-white bg-[#61d3f3] enabled:bg-[#004A80] enabled:hover:bg-[#1c4b6d]
											focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm w-full px-5 py-2.5 me-2 mb-2 mt-10 focus:outline-none'>
											{token.length < 7
												? 'Digite o token'
												: 'ENTRAR'}
										</button>
									</div>
									{showAlert && (
										<p className='text-red-500 font-roboto font-semibold'>
											Token Inválido
										</p>
									)}
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</div>
	);
}

export default NotesLogin;
