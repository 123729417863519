/* import React from 'react';
import html2pdf from 'html2pdf.js';

const BotaoGerarPDF = () => {
	const gerarPDF = () => {
		// Carregar o arquivo HTML
		fetch('/TOMA.html')
			.then((response) => {
				if (!response.ok) {
					throw new Error('Erro ao carregar o HTML');
				}
				return response.text();
			})
			.then((htmlContent) => {
				// Criar um elemento temporário para inserir o HTML
				const divTemp = document.createElement('div');
				divTemp.innerHTML = htmlContent;
				document.body.appendChild(divTemp);

				// Configurar opções para o PDF
				const options = {
					margin: 5, // Ajuste a margem se necessário
					filename: 'documento.pdf',
					html2canvas: {
						scale: 2,
						scrollX: 2,
						scrollY: 2,
						useCORS: true, // Habilita o carregamento de imagens externas
					},
					jsPDF: {
						unit: 'mm',
						format: 'a4',
						orientation: 'portrait',
					},
				};

				// Gerar PDF a partir do conteúdo HTML
				html2pdf()
					.set(options)
					.from(divTemp)
					.save()
					.then(() => {
						// Remover o elemento temporário
						document.body.removeChild(divTemp);
					});
			})
			.catch((error) => {
				console.error('Erro ao gerar o PDF:', error);
			});
	};

	return <button onClick={gerarPDF}>Gerar PDF a partir do HTML</button>;
};

export default BotaoGerarPDF;
 */

import React, { useRef, useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Formulario = () => {
	const [formData, setFormData] = useState({
		nome: '',
		dataNascimento: '',
	});

	const formRef = useRef(null);

	// Atualiza os dados do formulário no estado
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	// Gera o PDF com o layout e dados dinâmicos
	const handleGeneratePDF = () => {
		const input = formRef.current;

		// Força tamanho fixo para capturar no formato A4
		input.style.width = '794px'; // 210mm em pixels (A4)
		input.style.minHeight = '1123px'; // 297mm em pixels (A4)

		html2canvas(input, { scale: 2 }).then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF('p', 'mm', 'a4');
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
			pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
			pdf.save('formulario.pdf');

			// Restaura o layout responsivo após gerar o PDF
			input.style.width = '';
			input.style.minHeight = '';
		});
	};

	return (
		<div>
			{/* Formulário na tela */}
			<div
				ref={formRef}
				style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
				<h1>Formulário</h1>
				<form>
					<div>
						<label>Nome:</label>
						<input
							type='text'
							name='nome'
							value={formData.nome}
							onChange={handleInputChange}
							placeholder='Digite seu nome'
						/>
					</div>
					<div>
						<label>Data de Nascimento:</label>
						<input
							type='date'
							name='dataNascimento'
							value={formData.dataNascimento}
							onChange={handleInputChange}
						/>
					</div>
				</form>
				<div
					style={{
						marginTop: '20px',
						borderTop: '1px solid #ddd',
						paddingTop: '10px',
					}}>
					<p>Resumo:</p>
					<p>
						<strong>Nome:</strong>{' '}
						{formData.nome || 'Não preenchido'}
					</p>
					<p>
						<strong>Data de Nascimento:</strong>{' '}
						{formData.dataNascimento || 'Não preenchido'}
					</p>
				</div>
			</div>

			{/* Botão para gerar o PDF */}
			<button
				onClick={handleGeneratePDF}
				style={{ marginTop: '20px' }}>
				Gerar PDF
			</button>
		</div>
	);
};

export default Formulario;
