import React, { useState, useEffect } from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
/* import { Link } from 'react-router-dom'; */
import { dadosContatos } from './descricoes e contatos/apd-padi';
import { requestUnits } from '../../services/requests';

function Assessoria() {
	const [units, setUnits] = useState([]);
	const fetchUnits = async () => {
		const response = await requestUnits();

		if (response.error) {
			console.log(response.error);
			/* alert(response.error); */
		} else {
			let groupedUnits = () => {
				return response.reduce((acc, unidade) => {
					const tipo = unidade.tipo_unidade;
					if (!acc[tipo]) {
						acc[tipo] = [];
					}
					acc[tipo].push(unidade);
					return acc;
				}, {});
			};
			/* console.log(groupedUnits()); */
			setUnits(groupedUnits());
		}
	};

	useEffect(() => {
		fetchUnits();
	}, []);

	return (
		<div className=''>
			<NavBar />
			<div className='flex flex-col w-[80%] md:w-[96%] xl:w-[63%] mt-14 mb-14 mx-auto text-wrap '>
				<h1 className='mx-auto md:mx-10  text-[#004A80] font-bold text-3xl md:text-4xl mt-12 mb-12 w-first-letter:text-xl font-roboto'>
					Assessoria de Programas de Desospitalização - PADI
				</h1>
				<span className='text-justify pb-5 font-roboto mx-auto md:mx-10'>
					O PADI é destinado ao usuário, munícipe do Rio de Janeiro,
					que se encontra em condição de restrição ao leito ou ao
					domicílio, em decorrência de doenças crônicas agudizadas, de
					incapacidade funcional provisória ou permanente, pacientes
					egressos de internação hospitalar prolongada ou recorrente,
					com dificuldade ou impossibilidade física de locomoção,
					pacientes em cuidados paliativos e outros agravos passíveis
					de recuperação funcional, que necessite de cuidados
					intensificados e sequenciais, realizados por equipe
					multiprofissional.
					<br />
					<br />
					<span className='text-[#004A80] font-bold text-md'>
						O Programa conta com 19 Equipes Multiprofissionais de
						Atenção Domiciliar - EMAD e 7 Equipes Multiprofissionais
						de Apoio - EMAP, totalizando 26 equipes.
					</span>{' '}
					Cada EMAD é composta por médico, enfermeiro, fisioterapeuta
					e técnicos de enfermagem. As EMAPs podem ser compostas por
					assistente social, nutricionista, fonoaudiólogo, psicólogo e
					terapeuta ocupacional. As equipes estão instaladas nos
					Hospitais Municipais Miguel Couto, Salgado Filho, Paulino
					Werneck, Lourenço Jorge, Rocha Faria, Francisco da Silva
					Telles,{' '}
					<span className='text-[#004A80] font-bold text-md'>
						Pedro II e Albert Schweitzer
					</span>{' '}
					onde buscam identificar, através de busca ativa nas
					enfermarias e nas salas amarelas, os pacientes com perfil
					para atendimento domiciliar.
					<br />
					<br />
					Os principais benefícios{' '}
					<span className='text-[#004A80] font-bold text-md'>
						do PADI
					</span>{' '}
					são a aceleração da recuperação, a redução do tempo médio de
					internação hospitalar, a liberação dos leitos hospitalares
					para outros pacientes, a independência funcional, a melhora
					na capacidade de se cuidar e de ser cuidado no ambiente
					domiciliar.
					<br />
					<br />
					Para admissão no Programa, o domicílio precisa ter
					acessibilidade tanto para o paciente, quanto para a equipe,
					recursos mínimos de infraestrutura, saneamento básico e
					ventilação. Em alguns casos será necessário que o/a paciente
					tenha um(a) cuidador(a). O(a) usuário(a) precisa ter um
					responsável que assine o termo de compromisso do Programa de
					Atenção Domiciliar ao Idoso. O(a) usuário(a) não pode
					depender de equipamentos para manter as funções vitais.
				</span>
				<div className='flex flex-col  mb-14  text-wrap '>
					<h1 className='mx-auto md:mx-10 text font-bold text-1xl md:text-2xl mt-12 w-first-letter:text-xl font-roboto'>
						Contatos
					</h1>
					{console.log(units.PADI)}
					{Object.keys(units).length > 0 ? (
						<div className='mx-auto md:mx-10 py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
							{units.PADI.map((hospital, index) => (
								<div
									key={index}
									className='border border-gray-200 p-4 rounded-md'>
									<p className='text-[#004A80] font-bold text-md'>
										{hospital.nome}
									</p>
									<p className='text-gray-600 text-sm'>
										Endereço:. {hospital.endereco},{' '}
										{hospital.bairro}
									</p>

									<p className='text-gray-600 text-sm'>
										Tel:. {hospital.telefone}
									</p>
								</div>
							))}
						</div>
					) : (
						<div className=' border-gray-200 p-4 rounded-md'>
							<p className='text-[#004A80] h-[100px] mx-6 font-bold text-md'>
								{' '}
								UNIDADES NÃO ENCONTRADAS . . .
							</p>
						</div>
					)}
				</div>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Assessoria;
