import React, { useEffect, useState, useRef } from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { requestAllNotes } from '../../services/requests';
import Relogio from '../../images/NotifyAvaliationImages/relogio.png';
import Lupa from '../../images/NotifyAvaliationImages/lupa.png';
import Check from '../../images/NotifyAvaliationImages/check.png';
import Notepad from '../../images/NotifyAvaliationImages/notepad.png';
import { LuClipboardCheck } from 'react-icons/lu';
import { FaSortAlphaDown } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import { IoMdEye } from 'react-icons/io';
import { FaFilter } from 'react-icons/fa';
import { GoDotFill } from 'react-icons/go';
import Json from '../../utils/tipos_incidentes.json';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import ClassInvForm from '../../components/ClassInvForm';
import tiposIncidentes from '../../utils/tipos_incidentes.json';
import Cookies from 'js-cookie';

function NotesAvaliation() {
	const navigate = useNavigate();
	const [notes, setNotes] = useState([]);
	const [allNotes, setAllNotes] = useState([]);
	const [currentFilter, setCurrentFilter] = useState([]);
	const [duality, setDuality] = useState(false);
	const [loading, setLoading] = useState(true);
	const [listLoading, setListLoading] = useState(false);
	const [propNotes, setPropNotes] = useState([]);
	const [notifyNumbers, setNotifyNumbers] = useState();
	const [currentUser, setCurrentUser] = useState({
		unit: 'Hospital Municipal Albert Schweitzer',
		status: 'NOTIFICADO',
		card: 'NOTIFICADO',
	});
	const [icons, setIcons] = useState({
		0: {
			icon: Relogio,
			status: 'notificados',
			descrição: 'Aguardando Classificação',
			noPrazo: '33',
			atrasados: '25',
		},
		1: {
			icon: Lupa,
			status: 'classificados',
			descrição: 'Em Investigação',
			noPrazo: '20',
			atrasados: '25',
		},
		2: {
			icon: Check,
			status: 'investigados',
			descrição: 'Avaliação Final',
			concluidos: '12',
			pendentes: '7',
		},
	});
	const [filter, setFilter] = useState({
		notificacao: false,
		paciente: false,
		incidente: false,
		status: false,
		data_notificacao: false,
		data_nascimento: false,
	});
	const [isOpen, setIsOpen] = useState(false);
	const [showAlert, setShowAlert] = useState(true);
	const [categorias, setCategorias] = useState([]);
	/* const icons = {
		0: {
			icon: Relogio,
			descrição: 'Aguardando Classificação',
			noPrazo: '33',
			atrasados: '25',
		},
		1: {
			icon: Lupa,
			descrição: 'Em Investigação',
			noPrazo: '20',
			atrasados: '25',
		},
		2: {
			icon: Check,
			descrição: 'Avaliação Final',
			concluidos: '12',
			pendentes: '7',
		},
	}; */
	const handleClick = (data) => {
		/* console.log(data); */

		let filter;
		/* console.log(allNotes); */
		if (allNotes.length > 0) {
			filter = allNotes.filter(
				(note) =>
					note.unidade.nome === currentUser.unit &&
					note.status === currentUser.card
			);
		}

		switch (data) {
			case 'notificados':
				setListLoading(true);
				setNotes(
					allNotes?.filter(
						(note) =>
							note.unidade.nome === currentUser.unit &&
							note.status === 'NOTIFICADO'
					)
				);
				setCurrentUser({ ...currentUser, card: 'NOTIFICADO' });
				setTimeout(() => {
					setListLoading(false);
				}, 1000);

				break;

			case 'classificados':
				setListLoading(true);
				setNotes(
					allNotes?.filter(
						(note) =>
							note.unidade.nome === currentUser.unit &&
							note.status === 'CLASSIFICADO'
					)
				);
				setCurrentUser({ ...currentUser, card: 'CLASSIFICADO' });
				setTimeout(() => {
					setListLoading(false);
				}, 1000);

				break;
			case 'investigados':
				setListLoading(true);
				setNotes(
					allNotes?.filter(
						(note) =>
							note.unidade.nome === currentUser.unit &&
							note.status === 'INVESTIGADO'
					)
				);
				setCurrentUser({ ...currentUser, card: 'INVESTIGADO' });
				setTimeout(() => {
					setListLoading(false);
				}, 1000);
				break;
			default:
				console.log('Valor não corresponde a nenhum caso');
		}
	};

	const conteudo = 'Pendente';

	const meses = [
		'janeiro',
		'fevereiro',
		'março',
		'abril',
		'maio',
		'junho',
		'julho',
		'agosto',
		'setembro',
		'outubro',
		'novembro',
		'dezembro',
	];

	const toggleAccordion = async () => {
		setIsOpen(!isOpen);
	};

	const filteChange = (e) => {
		/* console.log(e.target.id); */
		let newFilter = { ...filter, [e.target.id]: !filter[e.target.id] };
		setFilter(newFilter);

		const result = notes;
		setCurrentFilter(ordenarNotificacoes(result, newFilter));
	};

	const findValueFromDisplay = (displayValue) => {
		let word = displayValue.trim();
		const result = Json.find((item) => item.value === word);
		return result ? result.display : '';
	};

	const goToClassification = (caso, card, note) => {
		/* console.log(caso, card, note); */
		if (caso === card) {
			setPropNotes(note);

			setLoading(true);
			setDuality(!duality);
			setTimeout(() => {
				setLoading(false);
			}, 2000);
		}

		/* navigate('/class-inv-form'); */
	};

	const ordenarNotificacoes = (notificacoes, filtros) => {
		// Define a ordem de prioridade para os status
		const prioridadeStatus = {
			pendente: 2,
			'em atraso': 1,
			'no prazo': 3,
			concluído: 4,
		};
		const marcos = notificacoes.sort((a, b) => {
			for (const [chave, ativo] of Object.entries(filtros)) {
				if (ativo) {
					// Ordena por status primeiro (prioridade definida acima)
					if (chave === 'status') {
						const prioridadeA =
							prioridadeStatus[a.status.toLowerCase()] || 99;
						const prioridadeB =
							prioridadeStatus[b.status.toLowerCase()] || 99;
						if (prioridadeA !== prioridadeB)
							return prioridadeA - prioridadeB;
					}
					// Ordena por data_notificacao em ordem crescente
					if (chave === 'data_notificacao') {
						const dataA = new Date(a.data_notificacao);
						const dataB = new Date(b.data_notificacao);
						if (dataA - dataB !== 0) return dataA - dataB;
					}
					// Ordena alfabeticamente por outras chaves (como paciente ou incidente)
					if (a[chave] !== b[chave]) {
						return a[chave]?.localeCompare?.(b[chave]) || 0;
					}
				}
			}
			// Critério padrão para manter uma ordenação estável
			return 0;
		});
		/* console.log(marcos); */
		return marcos;
	};

	// Exemplo de uso

	/* console.log(resultado); */

	/* 	const categorias = [
		'categoria1',
		'categoria2',
		'categoria3',
		'categoria4',
		'categoria5',
	];
 */

	/* const contentRef = useRef(null);
	const [filters, setFilters] = useState({
		ano: '',
		mes: '',
		categoria: '',
	}); */

	/* const handleFilterChange = (key, value) => {
		setFilters((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const anos = () => {
		let ano = new Date().getFullYear();
		let anos = [];
		for (let i = 0; i < 50; i++) {
			anos.push(ano - i);
		}
		return anos;
	}; */

	const handleRequest = async () => {
		const result = await requestAllNotes();
		function agruparNotificacoesPorStatus(notificacoes) {
			const notificacoesFiltradas = notificacoes.filter(
				(n) => n.unidade && n.unidade.nome === currentUser.unit
			);
			return {
				notificados: notificacoesFiltradas.filter(
					(n) => n.status === 'NOTIFICADO'
				),
				classificados: notificacoesFiltradas.filter(
					(n) => n.status === 'CLASSIFICADO'
				),
				investigados: notificacoesFiltradas.filter(
					(n) => n.status === 'INVESTIGADO'
				),
				concluidos: notificacoesFiltradas.filter(
					(n) => n.status === 'CONCLUIDO'
				),
			};
		}

		/* let previousIcon = icons; */
		/* console.log(previousIcon);
		console.log(agruparNotificacoesPorStatus(result)); */
		setNotifyNumbers(agruparNotificacoesPorStatus(result));

		if (!result.error) {
			let filter = result.filter(
				(note) =>
					note.unidade.nome === currentUser.unit &&
					note.status === currentUser.card
			);
			setAllNotes(result);
			setNotes(filter);
		} else {
			console.log(result.error);
		}
	};

	useEffect(() => {
		const localData = Cookies.get('user');
		if (localData) {
			if (JSON.parse(Cookies.get('user')).cargo === 'desenvolvedor') {
				setShowAlert(false);
			}
		}

		if (currentUser.card === '') {
			setCurrentUser({ ...currentUser, card: 'NOTIFICADO' });
		}
		handleRequest();
	}, []);

	return (
		<div className='flex flex-col mx-auto min-h-screen'>
			<NavBar />
			{/* {console.log(notes)} */}
			{duality ? (
				<div>
					{loading ? (
						<div className='mt-[500px]'>
							<Loading />
						</div>
					) : (
						<div className='grid my-[100px] w-full'>
							<div className='mx-auto w-[1066px]'>
								<button
									className='bg-[#E3E3E3] p-1 text-[#757575] font-semibold mx-auto w-[80px] rounded-md hover:bg-[#757575] transition-all duration-200 hover:text-[#f0f0f0]'
									onClick={() => {
										window.location.reload();
										/* setLoading(true);
										setDuality(true);
										setTimeout(() => {
											goToClassification();
										}, 2000); */
									}}>
									{''} Voltar
								</button>
							</div>
							<ClassInvForm notificacao={propNotes} />
						</div>
					)}
				</div>
			) : (
				<div className='font-inter flex flex-col flex-grow '>
					{showAlert ? (
						<section className='bg-[#004A80] mt-[150px] rounded-md w-[90%] sm:w-[70%] md:w-[60%] lg:w-[50%]  mx-auto my-auto'>
							<div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
								<div className='mx-auto max-w-screen-sm text-center'>
									<h1 className='mb-4 text-white text-5xl sm:text-6xl lg:text-7xl tracking-tight font-extrabold text-primary-600'>
										401
									</h1>
									<p className='mb-10 text-2xl sm:text-3xl md:text-4xl tracking-tight font-bold text-slate-100'>
										Acesso Negado
									</p>
									<p className='mb-7 text-sm sm:text-base md:text-lg font-light text-gray-300'>
										Você não possui permissão para acessar
										este campo. Entre em contato com o
										administrador(a) da sua unidade para
										solicitação de acesso.
									</p>
									<a
										href='/login'
										className='inline-flex hover:scale-105 transition-transform text-gray-300 bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4'>
										Volte para a Tela de Login
									</a>
								</div>
							</div>
						</section>
					) : (
						<div>
							<div className='sm:top-[80px] mt-[80px] bg-[#F0F4F8]  w-full'>
								<div className='w-[90%] sm:w-[95%] mx-auto items-center max-w-[1136px]'>
									<div className='px-[32px] py-[36px]'>
										<p className='text-[32px] font-semibold w-full text-center text-[#004A80]'>
											Avaliação das notificações de
											incidentes
										</p>
									</div>
								</div>
							</div>
							<div className='w-[90%] sm:w-[95%] mt-[24px]  mx-auto items-center max-w-[1136px] mb-10 flex-grow'>
								{/* CARDS */}
								{/* {console.log(filter)} */}
								<div className='flex flex-wrap gap-y-8 gap-[73px] justify-center min-h-[440px]'>
									{/* CARDS 1 */}
									{Object.keys(icons).map((icon, index) => (
										<div
											key={index}
											className='flex flex-col justify-between items-center bg-[#F5F5F5] shadow-md w-[330px] p-[18px] rounded-lg gap-y-[18px]  min-h-[440px] border-[2px]'>
											<div className='  w-full justify-center grid gap-[18px]'>
												<div className='grid justify-center'>
													<img
														alt='icone do status'
														src={
															icons[`${index}`]
																.icon
														}></img>
												</div>
												<div className='grid text-center leading-none  text-[#004A80] text-[32px] font-bold'>
													{
														icons[`${index}`]
															.descrição
													}
												</div>
											</div>
											<div className='  w-full text-center grid gap-[18px] '>
												<div className='grid justify-center text-[#004A80] text-[36px] font-bold'>
													{notifyNumbers
														? notifyNumbers[
																icons[
																	`${index}`
																].status
														  ].length
														: ''}
												</div>
												<div className=' w-full grid gap-[10px]'>
													<div className='bg-[#E3E3E3] h-[2px] w-full'></div>
													<div className='grid justify-center w-full text-[14px] text-[#757575]'>
														Notificações{' '}
														{icons[`${index}`]
															.descrição ===
														'Avaliação Final'
															? 'Concluídas'
															: icons[`${index}`]
																	.descrição}
													</div>
												</div>
												<div className=' flex gap-[32px] justify-center my-[10px]'>
													<div
														className={`grid ${
															icons[`${index}`]
																.concluidos
																? 'text-[#32993E]'
																: 'text-[#00C0F3]'
														} `}>
														<div className='grid font-semibold text-[20px]'>
															{/* {notifyNumbers
														? console.log(
																notifyNumbers[
																	icons[
																		`${index}`
																	].status
																].filter(
																	(notify) =>
																		notify.atraso ===
																		false
																).length
														  )
														: ''} */}
															{notifyNumbers
																? notifyNumbers[
																		icons[
																			`${index}`
																		].status
																  ].filter(
																		(
																			notify
																		) =>
																			notify.atraso ===
																			false
																  ).length
																: ''}
														</div>
														<div className='grid  font-normal text-[16px]'>
															{icons[`${index}`]
																.noPrazo
																? 'No Prazo'
																: 'Concluídos'}
														</div>
													</div>
													<div className=' w-[2px] h-[100%] content-center my-auto bg-[#004A80] rounded-lg'></div>
													<div className='grid text-[#B74644]'>
														<div className='grid  font-semibold text-[20px]'>
															{/* {console.log(notifyNumbers)}
													{console.log(
														icons[`${index}`]
															.status ===
															'classificados'
													)} */}
															{notifyNumbers
																? notifyNumbers[
																		icons[
																			`${index}`
																		].status
																  ].filter(
																		(
																			notify
																		) =>
																			notify.atraso ===
																			true
																  ).length
																: ''}
														</div>
														<div className='grid font-normal text-[16px]'>
															{icons[`${index}`]
																.atrasados
																? 'Em Atraso'
																: 'Pendentes'}
														</div>
													</div>
												</div>
												<button
													onClick={() =>
														handleClick(
															icons[`${index}`]
																.status
														)
													}
													/* onClick={()=>{
											const previousUser = currentUser

											
											setCurrentUser({})
										}
										} */
													className='w-full py-[8px] hover:border-transparent border-[2px] transition-all duration-200 border-[#757575] text-[#757575]  hover:bg-[#004A80] rounded-lg hover:text-[#F5F5F5] '>
													<div className='flex justify-center gap-[12px]'>
														<IoMdEye className='scale-[120%]' />
														<p className=' text-[12px] font-normal'>
															Visualizar
														</p>
													</div>
												</button>
											</div>
										</div>
									))}
								</div>
								<div className='w-full h-[1px] bg-[#E3E3E3] my-[28px] '></div>
								{/* LISTA */}
								<div className='border-[2px] border-[#E3E3E3]  py-[32px] flex flex-col  rounded-lg gap-[22px]'>
									<div className='flex justify-between pr-[22px]'>
										<div className='flex gap-[18px]'>
											<div className='w-[8px] h-[25px] bg-[#00C0F3]'></div>
											<p className='text-[#757575]'>
												Notificações aguardando{' '}
												{currentUser.card ===
												'NOTIFICADO'
													? 'classificação'
													: currentUser.card ===
													  'CLASSIFICADO'
													? 'investigação'
													: 'avaliação final'}
											</p>
										</div>
										<div className='flex flex-col gap-[22px] relative '>
											<button
												onClick={() =>
													toggleAccordion()
												}
												className={`border-[2px] transition-all duration-700 border-[#757575] rounded-lg text-[#757575] px-[8px] py-1 flex gap-x-[8px]  hover:bg-[#757575] hover:text-[white] ${
													isOpen
														? 'bg-[#757575] text-[white] w-[116px] rounded-b-none'
														: 'w-[110px]'
												}`}>
												<div className='h-full my-[4px]'>
													<FaFilter />
												</div>
												<div className='flex'>
													Ordenar
												</div>
											</button>
											<div
												/* ref={contentRef} */
												/* style={{
										maxHeight: isOpen
											? `${contentRef.current.scrollHeight}px`
											: '0px',
									}} */
												className={`absolute ${
													isOpen
														? 'h-[200px] p-2 '
														: 'h-[0px] py-0  px-2 '
												} overflow-hidden right-0 grid gap-2 rounded-t-none  transition-all duration-700 mt-[38px] ease-in-out rounded-md shadow-lg bg-[#f5f5f5] text-[#6e6e6e]`}>
												<button
													id='notificacao'
													className='flex items-center text-[14px] font-normal gap-[8px] justify-between hover:text-[#4aa7dd]'
													onClick={(e) =>
														filteChange(e)
													}>
													Notificação
													<FaSortAlphaDown
														id='notificacao'
														onClick={(e) =>
															filteChange(e)
														}
													/>
													{/* <input
											onChange={(e) => filteChange(e)}
											className=''
											type='checkbox'
										/> */}
												</button>
												<button
													id='paciente'
													className='flex items-center text-[14px] font-medium gap-[8px] justify-between hover:text-[#4aa7dd]   text-[#347bbd]'
													onClick={(e) =>
														filteChange(e)
													}>
													Paciente
													<FaSortAlphaDown
														id='paciente'
														onClick={(e) =>
															filteChange(e)
														}
													/>
												</button>
												<button
													id='incidente'
													className='flex items-center text-[14px] font-normal gap-[8px] justify-between hover:text-[#4aa7dd]'
													onClick={(e) =>
														filteChange(e)
													}>
													Incidente
													<FaSortAlphaDown
														id='incidente'
														onClick={(e) =>
															filteChange(e)
														}
													/>
												</button>
												<button
													id='status'
													className=' flex items-center text-[14px] font-medium gap-[8px] justify-between hover:text-[#4aa7dd]   text-[#347bbd]'
													onClick={(e) =>
														filteChange(e)
													}>
													Status
													<FaSortAlphaDown
														id='status'
														onClick={(e) =>
															filteChange(e)
														}
													/>
												</button>
												<button
													id='data_notificacao'
													className='flex items-center text-[14px] font-normal gap-[8px] justify-between hover:text-[#4aa7dd]'
													onClick={(e) =>
														filteChange(e)
													}>
													Data
													<FaSortAlphaDown
														id='data_notificacao'
														onClick={(e) =>
															filteChange(e)
														}
													/>
												</button>
												<button
													id='data_nascimento'
													className='flex items-center text-[14px] font-medium gap-[8px] justify-between hover:text-[#4aa7dd]   text-[#347bbd]'
													onClick={(e) =>
														filteChange(e)
													}>
													Nascimento
													<FaSortAlphaDown
														id='data_nascimento'
														onClick={(e) =>
															filteChange(e)
														}
													/>
												</button>
											</div>
										</div>
									</div>
									{/* {console.log(currentUser.status, currentUser.card)} */}
									{!listLoading ? (
										<div className='flex-col w-full px-[22px] pr-[28px]'>
											{(currentFilter.length > 0
												? currentFilter
												: notes
											).map((notificacao, index) => (
												<div
													key={index}
													className='w-full'>
													<div className=' w-full flex justify-between'>
														<div className='h-full my-auto mr-[10px] xl:mr-0'>
															<button
																onClick={() =>
																	goToClassification(
																		currentUser.status,
																		currentUser.card,
																		notificacao
																	)
																}
																className='bg-white  border-[2px] px-[10px] py-[8px] rounded-md text-[#004A80] gap-[10px] font-semibold flex border-[#004A80]  hover:text-white align-text-middle hover:bg-[#004A80] transition-all duration-200'>
																{' '}
																<div className='[8px]'>
																	{{
																		'NOTIFICADO-CLASSIFICADO':
																			(
																				<IoMdEye className='scale-[130%]' />
																			),
																		'NOTIFICADO-NOTIFICADO':
																			(
																				<HiOutlinePencilSquare className='scale-[180%]' />
																			),
																		'NOTIFICADO-INVESTIGADO':
																			(
																				<IoMdEye className='scale-[130%]' />
																			),
																		'CLASSIFICADO-NOTIFICADO':
																			(
																				<IoMdEye className='scale-[130%]' />
																			),
																		'CLASSIFICADO-INVESTIGADO':
																			(
																				<IoMdEye className='scale-[130%]' />
																			),
																		'CLASSIFICADO-CLASSIFICADO':
																			(
																				<FaSearch className='scale-[120%]' />
																			),
																		'INVESTIGADO-NOTIFICADO':
																			(
																				<IoMdEye className='scale-[130%]' />
																			),
																		'INVESTIGADO-CLASSIFICADO':
																			(
																				<IoMdEye className='scale-[130%]' />
																			),
																		'INVESTIGADO-INVESTIGADO':
																			(
																				<LuClipboardCheck className='scale-[180%]' />
																			),
																	}[
																		`${currentUser.status}-${currentUser.card}`
																	] || null}
																</div>
																<p className='my-auto text-[12px]'>
																	{{
																		'NOTIFICADO-CLASSIFICADO':
																			'Visualizar',
																		'CLASSIFICADO-CLASSIFICADO':
																			'Investigar',
																		'NOTIFICADO-NOTIFICADO':
																			'Classificar',
																		'NOTIFICADO-INVESTIGADO':
																			'Visualizar',
																		'CLASSIFICADO-NOTIFICADO':
																			'Visualizar',
																		'CLASSIFICADO-INVESTIGADO':
																			'Visualizar',
																		'INVESTIGADO-NOTIFICADO':
																			'Visualizar',
																		'INVESTIGADO-CLASSIFICADO':
																			'Visualizar',
																		'INVESTIGADO-INVESTIGADO':
																			'Avaliar',
																	}[
																		`${currentUser.status}-${currentUser.card}`
																	] || null}
																</p>
															</button>
														</div>
														<div className='flex gap-[14px] overflow-x-auto '>
															<div className=' flex flex-col justify-center text-center gap-[12px]'>
																<p className='text-[#757575] text-[14px] font-normal'>
																	{' '}
																	Notificação
																</p>
																<p className='text-[#004A80] text-[16px] font-normal'>
																	{' '}
																	{
																		notificacao.num_notificacao
																	}
																</p>
															</div>
															<div className=' h-[100%] content-center'>
																<div className='w-[1px]  h-[45px]  bg-[#E3E3E3] '></div>
															</div>
															<div className=' flex flex-col text-center justify-center gap-[12px]'>
																<p className='text-[#757575] text-[14px] font-normal'>
																	{' '}
																	Data da
																	notificação
																</p>
																<p className='text-[#004A80] text-[16px] font-normal'>
																	{' '}
																	{notificacao.data_notificacao
																		.split(
																			'-'
																		)
																		.reverse()
																		.join(
																			'/'
																		)}
																</p>
															</div>

															<div className=' h-[100%] content-center '>
																<div className='w-[1px] h-[45px] my-[20%] bg-[#E3E3E3] '></div>
															</div>
															<div className=' flex flex-col justify-center text-center gap-[12px]'>
																<p className='text-[#757575] text-[14px] font-normal'>
																	{' '}
																	Incidente
																</p>
																<p className='text-[#004A80] max-w-[171px] leading-[19.2px]  text-[16px] font-normal'>
																	{' '}
																	{findValueFromDisplay(
																		notificacao.tipo_incidente
																	)}
																</p>
															</div>
															<div className=' h-[100%] content-center '>
																<div className='w-[1px] h-[45px] my-[20%] bg-[#E3E3E3] '></div>
															</div>
															<div className=' flex flex-col text-center justify-center w-[110px] gap-[12px]'>
																<p className='text-[#757575] text-[14px] font-normal '>
																	{' '}
																	Paciente
																</p>
																<p className='text-[#004A80] max-w-[130px] leading-[19.2px] text-[16px] font-normal'>
																	{' '}
																	{
																		notificacao.nome_paciente
																	}
																</p>
															</div>
															<div className=' h-[100%] content-center'>
																<div className='w-[1px] h-[45px] my-[20%] bg-[#E3E3E3] '></div>
															</div>
															<div className=' flex flex-col text-center justify-center gap-[12px]'>
																<p className='text-[#757575] text-[14px] font-normal'>
																	{' '}
																	Data de
																	nascimento
																</p>
																<p className='text-[#004A80] text-[16px] font-normal'>
																	{' '}
																	{notificacao.nascimento_paciente
																		.split(
																			'-'
																		)
																		.reverse()
																		.join(
																			'/'
																		)}
																</p>
															</div>
															<div className=' h-[100%] content-center'>
																<div className='w-[1px] h-[45px] my-[20%] bg-[#E3E3E3]'></div>
															</div>
															<div className='flex flex-col text-center justify-center gap-[12px] min-w-[110px]'>
																<p className='text-[#757575] text-[14px] font-normal'>
																	{' '}
																	Status
																</p>
																<div
																	className={`px-[8px] rounded-full justify-center flex gap-2 py-[2px] ${
																		{
																			pendente:
																				'bg-[#FFE4E3] text-[#B74644]',
																			'em atraso':
																				'bg-[#FFE4E3] text-[#B74644]',
																			'no prazo':
																				'bg-[#E3F2FF] text-[#2A6BBF]',
																			concluido:
																				'bg-[#E4FFE3] text-[#44B746]',
																		}[
																			notificacao.atraso
																				? 'em atraso'
																				: 'no prazo'
																		]
																	}`}>
																	<div className='flex gap-2 '>
																		<div className='items-center content-center'>
																			<GoDotFill />
																		</div>
																		<p className='content-center flex items-center'>
																			{(notificacao.atraso
																				? 'em atraso'
																						.charAt(
																							0
																						)
																						.toUpperCase()
																				: 'no prazo'
																						.charAt(
																							0
																						)
																						.toUpperCase()) +
																				(notificacao.atraso
																					? 'em atraso'.slice(
																							1
																					  )
																					: 'no prazo'.slice(
																							1
																					  ))}
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className='bg-[#dadada] h-[1px] w-full my-[32px]'></div>
												</div>
											))}
										</div>
									) : (
										<Loading />
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			)}
			<Footer />
		</div>
	);
}

export default NotesAvaliation;
