import React, { useState, useEffect, useRef } from 'react';
import Logo from '../images/logoGrandePreta.jpg';
import { usePDF } from 'react-to-pdf';
import BotaoGerarPDF from './GerarPDFComReactPDF';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
	requestNoteCreate,
	requestUnits,
	requestUnitToken,
} from '../services/requests';
import Json from '../utils/tipos_incidentes.json';
import { IoMdCheckmarkCircle } from 'react-icons/io';

function NotifyForm() {
	const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });
	const [otherCheck, setOtherCheck] = useState(false);
	const [yesCheck, setYesCheck] = useState(false);
	const [formLayout, setFormLayout] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [enviado, setEnviado] = useState(false);
	const [copiado, setCopiado] = useState(false);
	const [protocolo, setProtocolo] = useState('');

	const [form, setForm] = useState({
		unidade_token_notificacao: '',
		data_notificacao: '',
		data_ocorrencia: '',
		unidade: '',
		setor: '',
		nome_paciente: '',
		sexo_paciente: '',
		nascimento_paciente: '',
		prontuario: '',
		notificador: '',
		tipo_incidente: '',
		quem_presenciou: '',
		descricao_ocorrencia: '',
		acoes_imediatas: '',
		medida_preventiva: '',
		other_checked: false,
	});

	const formRef = useRef(null);

	const handleCopy = (event) => {
		const content = event.target.value; // Conteúdo específico
		navigator.clipboard
			.writeText(content)
			.then(() => {
				setCopiado(true);
			})
			.catch((err) => {
				console.error('Erro ao copiar:', err);
			});
	};

	const handleGeneratePDF = () => {
		setFormLayout(true);
		const input = formRef.current;

		input.style.width = '1236px';
		input.style.minHeight = '1123px';

		setTimeout(() => {
			html2canvas(input, { scale: 2 }).then((canvas) => {
				const imgData = canvas.toDataURL('image/jpeg', 2);
				const pdf = new jsPDF('p', 'mm', 'a4');
				const pdfWidth = pdf.internal.pageSize.getWidth() - 2;
				const pdfHeight =
					(canvas.height * pdfWidth) / canvas.width - 12;

				pdf.addImage(
					imgData,
					'JPEG',
					0,
					0,
					pdfWidth,
					pdfHeight,
					undefined,
					'FAST'
				);
				pdf.save('formulario.pdf');

				setFormLayout(false);
				setTimeout(() => {
					input.style.width = '';
					input.style.minHeight = '';
				}, 100);
			});
		}, 500);
	};

	const moment = require('moment');
	require('moment/locale/pt-br');
	moment.locale('pt-br');

	const incidentTypes = [
		'Falha na identificação do paciente',
		'Falha na terapia medicamentosa',
		'Falha na segurança cirúrgica',
		'Queda ou quase queda do paciente',
		'Lesão por pressão adquirida após admissão do paciente',
		'Reações transfusionais ou falhas com hemocomponentes',
		'Incidente envolvendo a dieta',
		'Falha no transporte/transferência do paciente',
		'Falha relacionada ao uso de gases/oxigênio/vapor',
		'Falha relacionada a exames e/ou diagnósticos',
		'Falha operacional de equipamentos ou dispositivos médicos',
		'Falha relacionada à infraestrutura, edifício ou instalações',
		'Falhas na comunicação e/ou documentação',
		'Contenção, suicídio ou dano autoinfligido',
		'Evasão de paciente',
	];

	const handleForm = (value, id) => {
		const findValueFromDisplay = (displayValue) => {
			let word = displayValue.trim();
			const result = Json.find((item) => item.display === word);
			return result ? result.value : '';
		};

		if (id === 'tipo_incidente') {
			setForm((prevForm) => ({
				...prevForm,
				tipo_incidente: findValueFromDisplay(value),
			}));
		} else {
			setForm((prevForm) => ({ ...prevForm, [id]: value }));
		}
	};

	const isFormValid = () => {
		return Object.entries(form).every(
			([key, value]) =>
				[
					'nome_paciente',
					'sexo_paciente',
					'nascimento_paciente',
					'notificador',
				].includes(key) || value !== ''
		);
	};

	const handleSubmit = async () => {
		setForm((prevForm) => ({ ...prevForm, other_checked: otherCheck }));

		if (!isFormValid()) {
			setShowAlert(true);
		} else {
			console.log({
				...form,
				data_notificacao: moment(new Date()).format('YYYY-MM-DD'),
			});
			const result = await requestNoteCreate({
				...form,
				data_notificacao: moment(new Date()).format('YYYY-MM-DD'),
			});
			if (result.error) {
				console.log(result.error);
			} else {
				setProtocolo(result.protocolo);
				setEnviado(true);
				console.log('Formulário enviado');
			}

			/* console.log(form); */
		}
	};

	const setUnitToken = async () => {
		const units = await requestUnitToken();
		/* const unitToken = await requestUnitToken(); */

		const unit = units.find(
			(unit) =>
				decodeURIComponent(
					String(window.location.pathname).split('/')[2]
				) === unit.nome
		);
		setForm((prevForm) => ({
			...prevForm,
			unidade_token_notificacao: unit.token_notificacao,
		}));
	};

	useEffect(() => {
		setForm((prevForm) => ({
			...prevForm,
			data_notificacao: moment(new Date()).format('DD/MM/YYYY'),
			unidade: decodeURIComponent(
				String(window.location.pathname).split('/')[2]
			),
		}));
		setUnitToken();
	}, []);

	return (
		<div>
			{/* {console.log(form)} */}
			{enviado ? (
				<div className='font-inter flex justify-center items-start w-full h-screen'>
					<div className='flex flex-col  bg-[#F5F5F5] mt-[100px]  items-center rounded-md max-w-[800px] gap-5  flex-grow shadow-lg'>
						<div className='bg-[#004A80]  rounded-t-md  w-full h-[20px] '>
							{' '}
						</div>
						<div className='text-[#00C0F3]  rounded-full '>
							<IoMdCheckmarkCircle size={120} />
						</div>
						<div className='text-3xl font-semibold text-[#004A80]'>
							{' '}
							Notificação Enviada com Sucesso{' '}
						</div>
						<div className='text-lg font-medium  w-[80%] text-[#8b8b8b]'>
							{' '}
							Sua Notificação foi enviada para a equipe de
							classificação, acompanhe o andamento dela através do
							protocolo abaixo.{' '}
						</div>
						<div className=' flex flex-col text-4xl font-bold mt-16 text-[#004A80]'>
							<div className='flex gap-3'>
								<div>Protocolo: </div>
								<button
									onClick={(event) => handleCopy(event)}
									value={protocolo}
									className=''>
									{protocolo}
									{/* {' '}
									{Math.floor(Math.random() * 1000000)} */}
								</button>
							</div>
							{copiado ? (
								<div className='flex flex-col justify-end '>
									<div className='text-[#a0a0a0] font-semibold  text-[12px]'>
										Protocolo copiado!
									</div>
								</div>
							) : null}
						</div>

						<a
							className='text-[#00C0F3] mb-14 hover:text-[#004A80] transition-all duration-200'
							href='/hub-de-notificacoes'>
							{' '}
							clique aqui para ser redirecionado à consulta de
							protocolos
						</a>
					</div>
				</div>
			) : (
				<div
					ref={formRef}
					id='my-table-id'
					className='mx-auto w-full max-w-[1130px] mt-10 px-4 sm:px-6 lg:px-8'>
					<div
						className={`${
							formLayout
								? 'flex flex-row justify-between w-full h-[150px] my-1'
								: 'flex flex-col md:flex-row justify-between w-full h-[190px] sm:h-[150px]  my-1'
						}`}>
						<div
							className={`${
								formLayout
									? 'scale-[70%] object-contain my-3 flex justify-start'
									: 'w-0 md:w-[26%] h-[105px] my-3 flex justify-center md:justify-start'
							}`}>
							<img
								alt='logo da prefeitura'
								src={Logo}
								className='h-full object-contain '
							/>
						</div>
						<div
							className={`${
								formLayout
									? 'w-[65%] h-[105px] my-5 text-white font-bold text-center flex items-center justify-center'
									: 'w-full md:w-[65%] h-[150px] sm:h-[105px] my-5 text-white font-bold text-center flex items-center justify-center '
							}`}>
							<p
								className={`${
									formLayout
										? 'p-0 pb-10 text-3xl bg-gray-800 h-full content-center'
										: 'text-2xl py-5 px-5 md:p-0 md:text-2xl lg:text-3xl bg-gray-800 h-full content-center'
								}`}>
								NOTIFICAÇÃO DE INCIDENTES E NÃO CONFORMIDADES
							</p>
						</div>
					</div>
					<div className='w-full border-[1px] border-black'>
						<div className='w-full'>
							<div
								className={`${
									formLayout
										? 'flex flex-row'
										: 'flex flex-col md:flex-row'
								}`}>
								<div className=' flex w-full justify-start md:w-1/2 border-r-[1px] border-b-[1px] border-black px-2 py-3'>
									<p className='font-semibold w-4/12 text-start content-center '>
										Data da notificação:
									</p>
									<input
										name='data_notificacao'
										id='data_notificacao'
										defaultValue={form.data_notificacao}
										onChange={({ target: { value, id } }) =>
											handleForm(value, id)
										}
										className='w-3/12  focus:outline-none'
									/>
								</div>
								<div className=' flex w-full justify-start md:w-1/2  border-b-[1px] border-black px-2 py-3'>
									<p className='font-semibold w-4/12 text-start content-center '>
										Data da ocorrência:
									</p>
									<input
										name='data_ocorrencia'
										id='data_ocorrencia'
										onChange={({ target: { value, id } }) =>
											handleForm(value, id)
										}
										type='date'
										className='w-3/12 focus:outline-none'
									/>
								</div>
							</div>
							<div
								className={`${
									formLayout
										? 'flex flex-row'
										: 'flex flex-col md:flex-row'
								}`}>
								<div className='flex w-full md:w-1/2 border-r-[1px] border-b-[1px] border-black px-2 py-3'>
									<p className='font-semibold content-center align-baseline py-1'>
										Unidade:
									</p>
									<input
										disabled
										name='unidade'
										id='unidade'
										defaultValue={form.unidade}
										className='w-[400px] ml-2 focus:outline-none bg-white'
										type='text'
									/>
								</div>
								<div
									className={`${
										formLayout
											? 'flex w-7/12 border-b-[1px] border-black px-2 py-3'
											: 'flex w-full md:w-7/12 border-b-[1px] border-black px-2 py-3'
									}`}>
									<p className='font-semibold content-center w-9/12 py-1'>
										Setor onde ocorreu o incidente:
									</p>
									<input
										name='setor'
										id='setor'
										onChange={({ target: { value, id } }) =>
											handleForm(value, id)
										}
										className='ml-2 w-full focus:outline-none align-top '
										type='text'
									/>
								</div>
							</div>
							<div className='flex border-b-[1px] border-black px-2 py-3'>
								<p className='font-semibold  text-start py-1'>
									Nome completo do paciente:
								</p>
								<input
									name='nome_paciente'
									id='nome_paciente'
									onChange={({ target: { value, id } }) =>
										handleForm(value, id)
									}
									className='w-9/12 ml-2 focus:outline-none '
									type='text'
								/>
							</div>
							<div
								className={`${
									formLayout
										? 'w-full flex flex-row'
										: 'w-full flex flex-col md:flex-row'
								}`}>
								<div className=' flex xs:w-1/4 border-r-[1px] border-b-[1px] border-black px-2 py-3'>
									<p className='font-semibold text-start mr-3 content-center'>
										Sexo:
									</p>
									<div className='flex  gap-4 '>
										<label className='flex items-end py-1 '>
											<input
												type='radio'
												name='sexo_paciente'
												id='masculino'
												onChange={({
													target: { id, name },
												}) => handleForm(id, name)}
												className=' h-4 w-4 text-blue-600'
											/>
											<span className='ml-2 text-sm'>
												Masculino
											</span>
										</label>
										<label className='flex items-end py-1'>
											<input
												type='radio'
												name='sexo_paciente'
												id='feminino'
												onChange={({
													target: { id, name },
												}) => handleForm(id, name)}
												className=' h-4 w-4 text-blue-600'
											/>
											<span className='ml-2 text-sm'>
												Feminino
											</span>
										</label>
									</div>
								</div>
								<div className='flex w-full justify-start md:w-5/12  border-b-[1px] border-black px-2 py-3'>
									<p className='font-semibold w-10/12 text-start content-center '>
										Data de Nascimento:
									</p>
									<input
										name='nascimento_paciente'
										id='nascimento_paciente'
										onChange={({ target: { value, id } }) =>
											handleForm(value, id)
										}
										type='date'
										className='w-5/12 focus:outline-none'
									/>
								</div>
								<div className=' flex w-full md:w-2/3 border-l border-b-[1px] border-black px-2 py-3'>
									<p className='font-semibold content-center py-1'>
										Prontuário/BE:
									</p>
									<input
										name='prontuario'
										id='prontuario'
										onChange={({ target: { value, id } }) =>
											handleForm(value, id)
										}
										className=' ml-2 w-full focus:outline-none'
										type='text'
									/>
								</div>
							</div>
							<div
								className={`${
									formLayout
										? 'flex w-full  border-black px-2 py-3'
										: 'flex w-full md:w-full  border-black px-2 py-3'
								}`}>
								<p
									className={`${
										formLayout
											? 'font-semibold content-center text-start py-1 w-[300px]'
											: 'font-semibold content-center text-start py-1 sm:w-[300px]'
									}`}>
									Identificação do notificador (opcional):
								</p>
								<input
									name='notificador'
									id='notificador'
									onChange={({ target: { value, id } }) =>
										handleForm(value, id)
									}
									className=' ml-2 w-9/12 focus:outline-none'
									type='text'
								/>
							</div>
						</div>
					</div>
					<div className='w-full my-5 border-[1px] border-black'>
						<div className='w-full bg-gray-300 py-2 text-center font-bold'>
							INCIDENTES E NÃO CONFORMIDADES
						</div>
						<div className='w-full border-b-[1px] border-t border-black p-4'>
							<div
								className={`${
									formLayout
										? 'grid grid-cols-2 gap-4'
										: 'grid grid-cols-1 lg:grid-cols-2 gap-4'
								}`}>
								{incidentTypes.map((incident, index) => (
									<label
										key={index}
										className='flex items-center whitespace-nowrap'>
										<input
											type='radio'
											name='tipo_incidente'
											value={incident}
											id={incident}
											onChange={({
												target: { value, name },
											}) => {
												handleForm(value, name);
												setOtherCheck(false);
											}}
											className='form-radio h-3 w-3 md:h-4 md:w-4 text-blue-600'
										/>
										<span
											className={`${
												formLayout
													? 'ml-2 text-gray-700 mb-2 text-base '
													: 'ml-2 text-gray-700 text-sm md:text-base text-wrap flex text-start'
											}`}>
											{incident}
										</span>
									</label>
								))}
								<label className='flex items-center whitespace-nowrap'>
									<input
										type='radio'
										name='tipo_incidente'
										onChange={({ target: { checked } }) =>
											setOtherCheck(checked)
										}
										className='form-radio h-3 w-3 md:h-4 md:w-4 text-blue-600'
									/>
									<span className='ml-2 text-gray-700 flex items-center w-10/12 text-sm md:text-base'>
										Outro:
										{otherCheck ? (
											<input
												type='text'
												name='tipo_incidente'
												onChange={({
													target: { value, name },
												}) => handleForm(value, name)}
												className='ml-2 w-full h-8 text-xs md:text-sm border-b-[1px] border-gray-500  focus:outline-none'
											/>
										) : null}
									</span>
								</label>
							</div>
						</div>
						<div className=' flex w-full py-2'>
							<p
								className={`${
									formLayout
										? 'font-semibold px-2  content-center w-5/12 text-start'
										: 'font-semibold px-2  content-center md:w-5/12 text-start'
								}`}>
								O fato foi presenciado por alguém?
							</p>
							<div className='w-full flex items-center px-2 gap-4'>
								<label className='flex items-center'>
									<input
										type='radio'
										name='quem_presenciou'
										id='ninguem'
										onChange={({
											target: { id, name },
										}) => {
											handleForm(id, name);
											setYesCheck(false);
										}}
										className='form-radio h-4 w-4 text-blue-600'
									/>
									<span
										className={`${
											formLayout
												? 'ml-2 text-gray-700 mb-2'
												: 'ml-2 text-gray-700'
										}`}>
										Não
									</span>
								</label>
								<label className='flex items-center w-full font-semibold'>
									<input
										type='radio'
										name='quem_presenciou'
										id='sim'
										onChange={() => {
											setYesCheck(true);
										}}
										className='form-radio h-4 w-4 text-blue-600'
									/>
									<span className='ml-2 text-gray-700 flex w-full  items-center'>
										<span
											className={`${
												formLayout
													? ' text-gray-700 w-3/12 text-start py-2 mb-2'
													: ' text-gray-700 md:w-3/12 text-start py-2'
											}`}>
											Sim - Quem ?
										</span>
										{yesCheck ? (
											<input
												type='text'
												id='quem_presenciou'
												onChange={({
													target: { value, id },
												}) => handleForm(value, id)}
												className=' w-full h-8 text-sm border-b-[1px] border-gray-500 focus:outline-none '
											/>
										) : null}
									</span>
								</label>
							</div>
						</div>
					</div>
					<div className='w-full my-5 border-[1px] border-black'>
						<div className='text-start px-2 font-semibold'>
							Descrição da ocorrência.
						</div>
						<textarea
							name='descricao_ocorrencia'
							id='descricao_ocorrencia'
							onChange={({ target: { value, id } }) =>
								handleForm(value, id)
							}
							className='w-full h-36 mt-2 px-2 text-sm text-gray-700 border-none focus:outline-none focus:ring-0 resize-none'></textarea>
					</div>
					<div className='w-full my-5 border-[1px] border-black'>
						<div className='text-start px-2 font-semibold'>
							Ações imediatas tomadas.
						</div>
						<textarea
							name='acoes_imediatas'
							id='acoes_imediatas'
							onChange={({ target: { value, id } }) =>
								handleForm(value, id)
							}
							className='w-full h-36 mt-2 px-2 text-sm text-gray-700 border-none focus:outline-none focus:ring-0 resize-none'></textarea>
					</div>
					<div className='w-full my-5 border-[1px] border-black'>
						<div className='text-start px-2 font-semibold'>
							Existe alguma medida em vigor para prevenir tal
							incidente? Qual?
						</div>
						<textarea
							name='medida_preventiva'
							id='medida_preventiva'
							onChange={({ target: { value, id } }) =>
								handleForm(value, id)
							}
							className='w-full h-36 mt-2 px-2 text-sm text-gray-700 border-none focus:outline-none focus:ring-0 resize-none'></textarea>
					</div>
				</div>
			)}
			{!enviado && (
				<div className='flex justify-between mx-auto w-full max-w-[1130px] mt-10 px-4 sm:px-6 lg:px-8'>
					<button
						className='enabled:bg-[#E3E3E3] shadow-md enabled:text-[#757575] font-semibold py-2 px-4 rounded-md enabled:hover:bg-[#7F7F7F] enabled:hover:text-[#FFFFFF] focus:outline-none'
						onClick={() => {
							isFormValid()
								? handleGeneratePDF()
								: setShowAlert(true);
						}}>
						Download PDF
					</button>
					<div className='flex justify-end text-red-500 gap-2'>
						{showAlert && (
							<div className='font-semibold w-[50%] leading-5'>
								Por favor, preencha todos os campos
								obrigatórios.
							</div>
						)}

						<button
							onClick={() => {
								isFormValid()
									? handleSubmit()
									: setShowAlert(true);
							}}
							className='enabled:bg-[#002D4E] bg-[#5e7687]  text-white py-2 px-4 rounded-md enabled:hover:bg-[#009CC6] focus:outline-none'>
							Enviar
						</button>
					</div>
					{/* <button
					onClick={handleGeneratePDF}
					style={{ marginTop: '20px' }}>
					Gerar PDF
				</button> */}
					{/* <BotaoGerarPDF /> */}
				</div>
			)}
		</div>
	);
}

export default NotifyForm;
