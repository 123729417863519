import React, { useState, useEffect, useRef } from 'react';
import Logo from '../images/logoGrandePreta.jpg';
import { usePDF } from 'react-to-pdf';
import BotaoGerarPDF from './GerarPDFComReactPDF';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
	requestUnits,
	requestClassCreation,
	requestInvCreation,
	requestInvList,
	requestAvalCreation,
} from '../services/requests';
import Json from '../utils/tipos_incidentes.json';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import NeverJson from '../utils/never_events.json';
import tiposIncidentes from '../utils/tipos_incidentes.json';
import classificacoes from '../utils/classificacoes.json';
import { FaRegFilePdf } from 'react-icons/fa';

function ClassInvForm({ notificacao }) {
	const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });
	const [otherCheck, setOtherCheck] = useState(false);
	const [yesCheck, setYesCheck] = useState(false);
	const [formLayout, setFormLayout] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [enviado, setEnviado] = useState(false);
	const [copiado, setCopiado] = useState(false);
	const [protocolo, setProtocolo] = useState('');
	const [counter, setCounter] = useState(1);
	const [cronCounter, setCronCounter] = useState(1);
	const [incident1Counter, setIncident1Counter] = useState(1);
	const [planCounter, setPlanCounter] = useState(1);
	const [isButtonEnabled, setIsButtonEnabled] = useState(true);

	const [invForm, setInvForm] = useState({
		coleta_dados: '',
		investigacao_concluida: false,
		equipe_investigacao_data: [
			{
				nome_completo: '',
				cargo: '',
				setor: '',
				email: '',
			},
		],
		cronologia_incidentes_data: [
			{
				evento: '',
				data: '',
				hora: '',
				setor: '',
				descricao: '',
			},
		],
		definicao_causas_data: {
			fator_paciente: '',
			fator_profissional: '',
			fator_tarefa: '',
			fator_comunicacao: '',
			fator_equipamento: '',
			fator_ambiente: '',
			fator_organizacional: '',
			fator_treinamento: '',
		},
		plano_acoes_data: [
			{
				causas_prioritarias: '',
				acoes_planejadas: '',
				responsavel: '',
				prazo: 0,
				status: '',
			},
		],
	});

	const [finalForm, setFinalForm] = useState({
		investigacao: '',
		acoes_realizadas: '',
		padrao_trabalho: '',
		observacoes: '',
	});

	const [classForm, setClassForm] = useState({
		notificacao: '',
		classificacao: '',
		sub_classificacao: '',
		prazo_investigacao: '',
	});

	const [form, setForm] = useState({
		unidade_token_notificacao: '',
		data_notificacao: '',
		data_ocorrencia: '',
		unidade: '',
		setor: '',
		nome_paciente: '',
		sexo_paciente: '',
		nascimento_paciente: '',
		prontuario: '',
		notificador: '',
		tipo_incidente: '',
		quem_presenciou: '',
		descricao_ocorrencia: '',
		acoes_imediatas: '',
		medida_preventiva: '',
		other_checked: false,
		fato_presenciado: '',
	});

	const formRef = useRef(null);

	function formatDate(isoDate) {
		const [year, month, day] = isoDate.split('-');
		return `${day}/${month}/${year}`;
	}

	const handleCopy = (event) => {
		const content = event.target.value; // Conteúdo específico
		navigator.clipboard
			.writeText(content)
			.then(() => {
				setCopiado(true);
			})
			.catch((err) => {
				console.error('Erro ao copiar:', err);
			});
	};

	const handleGeneratePDF = () => {
		setFormLayout(true); // Ativa o layout fixo para evitar mudanças de estilo
		const input = formRef.current;

		// Ajusta o layout para tamanhos fixos
		input.style.width = '1236px';
		input.style.minHeight = '1123px';

		// IDs dos blocos na ordem que você deseja renderizar
		let blocks = ['block1', 'block2', 'block3'];
		if (
			notificacao?.status === 'NOTIFICADO' ||
			notificacao?.status === 'CLASSIFICADO'
		) {
			blocks = ['block1', 'block2'];
		}

		const margin = 10; // Margem do PDF
		const pdf = new jsPDF('p', 'mm', 'a4'); // Configura o PDF

		const renderBlock = async (blockId, index) => {
			const block = document.getElementById(blockId);
			if (!block) {
				console.error(`Elemento com ID ${blockId} não encontrado!`);
				return;
			}

			const canvas = await html2canvas(block, { scale: 2 });
			const imgData = canvas.toDataURL('image/jpeg', 1);

			if (!imgData) {
				console.error(`Erro ao gerar imagem para o bloco ${blockId}`);
				return;
			}

			const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin; // Largura com margem
			const canvasWidth = canvas.width;
			const canvasHeight = canvas.height;

			/* if (canvasWidth === 0 || canvasHeight === 0) {
				console.error(
					`Dimensões inválidas para canvas no bloco ${blockId}`
				);
				return;
			} */

			const imgHeight = (canvasHeight * pdfWidth) / canvasWidth;

			if (index > 0) {
				pdf.addPage();
			}

			try {
				pdf.addImage(
					imgData,
					'JPEG',
					margin,
					margin,
					pdfWidth,
					imgHeight
				);
			} catch (error) {
				console.error(
					`Erro ao adicionar imagem ao PDF para o bloco ${blockId}`,
					error
				);
				throw error;
			}
		};

		const generatePDF = async () => {
			for (let i = 0; i < blocks.length; i++) {
				await renderBlock(blocks[i], i);
			}
			pdf.save('formulario.pdf'); // Salva o PDF após renderizar todos os blocos

			// Restaura o layout original
			setFormLayout(false);
			input.style.width = '';
			input.style.minHeight = '';
		};

		// Aguarda o layout ser aplicado antes de começar
		setTimeout(generatePDF, 500);
	};

	const handleFinal = (chave, valor) => {
		let acoes;
		valor === 'sim' ? (acoes = true) : (acoes = false);

		let padroes;
		valor === 'sim' ? (padroes = true) : (padroes = false);
		if (chave === 'acoes_realizadas') {
			setFinalForm((prevFormData) => ({
				...prevFormData,
				acoes_realizadas: acoes,
			}));
		}

		if (chave === 'observacoes') {
			setFinalForm((prevFormData) => ({
				...prevFormData,
				observacoes: valor,
			}));
		}

		if (chave === 'padroes_trabalho') {
			setFinalForm((prevFormData) => ({
				...prevFormData,
				padrao_trabalho: padroes,
			}));
		}
	};

	const moment = require('moment');
	require('moment/locale/pt-br');
	moment.locale('pt-br');

	/* const incidentTypes = [
		'Falha na identificação do paciente',
		'Falha na terapia medicamentosa',
		'Falha na segurança cirúrgica',
		'Queda ou quase queda do paciente',
		'Lesão por pressão adquirida após admissão do paciente',
		'Reações transfusionais ou falhas com hemocomponentes',
		'Incidente envolvendo a dieta',
		'Falha no transporte/transferência do paciente',
		'Falha relacionada ao uso de gases/oxigênio/vapor',
		'Falha relacionada a exames e/ou diagnósticos',
		'Falha operacional de equipamentos ou dispositivos médicos',
		'Falha relacionada à infraestrutura, edifício ou instalações',
		'Falhas na comunicação e/ou documentação',
		'Contenção, suicídio ou dano autoinfligido',
		'Evasão de paciente',
	]; */

	const handleForm = (value, id) => {
		const findValueFromDisplay = (displayValue) => {
			let word = displayValue.trim();
			const result = Json.find((item) => item.display === word);
			return result ? result.value : '';
		};

		if (id === 'tipo_incidente') {
			setForm((prevForm) => ({
				...prevForm,
				tipo_incidente: findValueFromDisplay(value),
			}));
		} else {
			setForm((prevForm) => ({ ...prevForm, [id]: value }));
		}
	};

	const isFormValid = () => {
		return Object.entries(form).every(
			([key, value]) =>
				[
					'nome_paciente',
					'sexo_paciente',
					'nascimento_paciente',
					'notificador',
				].includes(key) || value !== ''
		);
	};

	const handleSubmit = async () => {
		if (notificacao.status === 'NOTIFICADO') {
			if (!isButtonEnabled) {
				const result = await requestClassCreation(classForm);
				if (result.error) {
					console.log(result.error);
					return;
				} else {
					console.log('Formulário classificado com sucesso');
					setEnviado(true);
				}
			}
		}

		if (notificacao.status === 'CLASSIFICADO') {
			console.log(isButtonEnabled);
			if (!sendButtonEnabled()) {
				let response = { ...invForm, investigacao_concluida: true };
				const result = await requestInvCreation(
					response,
					notificacao.protocolo
				);

				if (result.error) {
					console.log(result.error);
					return;
				} else {
					console.log('Formulário Investigado com sucesso');
					setEnviado(true);
				}
			}
		}

		if (notificacao.status === 'INVESTIGADO') {
			if (!sendButtonEnabled()) {
				let response = {
					...finalForm,
					investigacao: notificacao.protocolo,
				};
				const result = await requestAvalCreation(response);

				if (result.error) {
					console.log(result.error);
					return;
				} else {
					console.log('Formulário Avaliado com sucesso');
					setEnviado(true);
				}
			}
		}
	};
	const handleColeta = (id, nome, valor) => {
		let key = nome;
		let value = valor;
		let prevForm = invForm;
		prevForm[key] = value;
		setInvForm(prevForm);
		sendButtonEnabled();
	};

	const setUnitToken = async () => {
		const units = await requestUnits();
		const unit = units.find(
			(unit) =>
				decodeURIComponent(
					String(window.location.pathname).split('/')[2]
				) === unit.nome
		);
		setForm((prevForm) => ({
			...prevForm,
			unidade_token_notificacao: unit.token_notificacao,
		}));
	};

	const handleClassForm = (event) => {
		const { id, value } = event.target;
		if (id === 'classificacao' && value !== 'NEVER_EVENTS') {
			setClassForm((prevFormData) => ({
				...prevFormData,
				[id]: value,
				sub_classificacao: '',
			}));
			sendButtonEnabled();
		}

		if (id === 'prazo_investigacao') {
			const selectedDate = new Date(value);
			const today = new Date();
			today.setHours(0, 0, 0, 0); // Ajustar para ignorar a hora

			if (selectedDate <= today) {
				setClassForm((prevFormData) => ({
					...prevFormData,
					[id]: '', // Atualiza apenas a chave correspondente ao id
				}));
				alert('A data não pode ser anterior à data atual.');
				sendButtonEnabled();
			} else {
				setClassForm((prevFormData) => ({
					...prevFormData,
					[id]: value, // Atualiza apenas a chave correspondente ao id
				}));
				sendButtonEnabled();
			}
		} else {
			setClassForm((prevFormData) => ({
				...prevFormData,
				[id]: value, // Atualiza apenas a chave correspondente ao id
			}));
			sendButtonEnabled();
		}
	};

	const handleEquipe = (id, nome, valor) => {
		let position = id.split('/')[0].trim();
		let object = id.split('/')[1].trim();
		let key = nome;
		let value = valor;

		let prevForm = invForm;
		prevForm[object][position][key] = value;
		setInvForm(prevForm);
		sendButtonEnabled();
	};
	const addEquipe = (id) => {
		if (id === 'adcionar') {
			setCounter(counter + 1);
			let newForm = invForm;
			newForm.equipe_investigacao_data.push({
				nome_completo: '',
				cargo: '',
				setor: '',
				email: '',
			});
			setInvForm(newForm);
		}
		if (id === 'remover') {
			if (counter > 1) {
				setCounter(counter - 1);
				let newForm = invForm;
				newForm.equipe_investigacao_data.pop();
			}
		}
		sendButtonEnabled();
	};

	const handleCronologia = (id, nome, valor) => {
		let position = id.split('/')[0].trim();
		let object = id.split('/')[1].trim();
		let key = nome;
		let value = valor;

		let prevForm = invForm;
		prevForm[object][position][key] = value;
		setInvForm(prevForm);
		sendButtonEnabled();
	};
	const addCronologia = (id) => {
		if (id === 'adcionar') {
			setCronCounter(cronCounter + 1);
			let newForm = invForm;
			newForm.cronologia_incidentes_data.push({
				evento: '',
				data: '',
				hora: '',
				setor: '',
				descricao: '',
			});
			setInvForm(newForm);
			sendButtonEnabled();
		}
		if (id === 'remover') {
			if (cronCounter > 1) {
				setCronCounter(cronCounter - 1);
				let newForm = invForm;
				newForm.cronologia_incidentes_data.pop();
				sendButtonEnabled();
			}
		}
	};

	const handlePlano = (id, nome, valor) => {
		let position = id.split('/')[0].trim();
		let object = id.split('/')[1].trim();
		let key = nome;
		let value = valor;

		let prevForm = invForm;
		console.log(prevForm);
		prevForm[object][position][key] = value;
		setInvForm(prevForm);
		sendButtonEnabled();
	};
	const addPlano = (id) => {
		if (id === 'adcionar') {
			setPlanCounter(planCounter + 1);
			let newForm = invForm;
			console.log(newForm);
			newForm.plano_acoes_data.push({
				causas_prioritarias: '',
				acoes_planejadas: '',
				responsavel: '',
				prazo: 0,
				status: '',
			});
			console.log(newForm);
			setInvForm(newForm);
			sendButtonEnabled();
		}
		if (id === 'remover') {
			if (planCounter > 1) {
				setPlanCounter(planCounter - 1);
				let newForm = invForm;
				newForm.plano_acoes_data.pop();
				sendButtonEnabled();
			}
		}
	};

	const handleCausas = (id, nome, valor) => {
		let position = id.split('/')[0].trim();
		let object = id.split('/')[1].trim();
		let key = nome;
		let value = valor;

		let prevForm = invForm;
		prevForm[object][key] = value;
		setInvForm(prevForm);
		sendButtonEnabled();
	};
	/* const addCausas = (id) => {
		if (id === 'adcionar') {
			setCronCounter(cronCounter + 1);
			let newForm = invForm;
			newForm.cronologia_incidentes_data.push({
				nome_completo: '',
				cargo: '',
				setor: '',
				email: '',
			});
			setInvForm(newForm);
		}
		if (id === 'remover') {
			if (cronCounter > 1) {
				setCronCounter(cronCounter - 1);
				let newForm = invForm;
				newForm.cronologia_incidentes_data.pop();
			}
		}
	}; */

	const hasEmptyFields = (obj) => {
		for (const key in obj) {
			const value = obj[key];

			// Verifica valores vazios, null ou undefined
			if (value === '' || value === null || value === undefined) {
				return true;
			}

			// Verifica arrays
			if (Array.isArray(value)) {
				for (const item of value) {
					if (hasEmptyFields(item)) return true;
				}
			}

			// Verifica objetos aninhados
			if (
				typeof value === 'object' &&
				value !== null &&
				!Array.isArray(value)
			) {
				if (hasEmptyFields(value)) return true;
			}
		}
		return false; // Retorna false se nenhum campo vazio foi encontrado
	};

	const sendButtonEnabled = () => {
		if (notificacao?.status === 'NOTIFICADO') {
			console.log('morte mesmo');
			const { classificacao, sub_classificacao, prazo_investigacao } =
				classForm;
			if (!prazo_investigacao || !classificacao)
				setIsButtonEnabled(false);
			if (classificacao === 'NEVER_EVENTS' && !sub_classificacao)
				setIsButtonEnabled(true);
			if (classificacao !== 'NEVER_EVENTS' && sub_classificacao === '')
				setIsButtonEnabled(true);
			if (classificacao !== 'NEVER_EVENTS' && sub_classificacao !== '')
				setIsButtonEnabled(true);
			setIsButtonEnabled(true);
		}
		if (notificacao.status === 'CLASSIFICADO') {
			console.log(hasEmptyFields(invForm));
			console.log(invForm);
			return setIsButtonEnabled(hasEmptyFields(invForm));
		}
	};
	const listInv = async () => {
		const result = await requestInvList(notificacao.protocolo);
		if (result.error) {
			console.log(result.error);
			return;
		} else {
			setCounter(result?.equipe_investigacao?.length);
			setCronCounter(result?.cronologia_incidentes?.length);
			setPlanCounter(result?.plano_acoes?.length);
			setInvForm(result);
		}
	};

	/* useEffect(() => {
		console.log('toma');
	}, [invForm]); */

	useEffect(() => {
		if (notificacao) {
			if (notificacao.status === 'NOTIFICADO') {
				setForm({
					...form,
					notificacao: notificacao.protocolo,
				});
			}
			if (notificacao.status === 'CLASSIFICADO') {
				setClassForm({
					classificacao: notificacao.classificacao.classificacao,
					prazo_investigacao:
						notificacao.classificacao.prazo_investigacao,
					sub_classificacao:
						notificacao.classificacao.sub_classificacao,
					notificacao: notificacao.protocolo,
				});
			}
			if (notificacao.status === 'INVESTIGADO') {
				console.log('entrou');
				listInv();
				setInvForm({
					...invForm,
					notificacao: notificacao.protocolo,
				});
			}
			setForm((prevForm) => ({
				...prevForm,
				num_notificacao: notificacao.protocolo,
				data_notificacao: notificacao.data_notificacao,
				data_ocorrencia: notificacao.data_ocorrencia,
				unidade: notificacao.unidade,
				setor: notificacao.setor,
				nome_paciente: notificacao.nome_paciente,
				sexo_paciente: notificacao.sexo_paciente,
				nascimento_paciente: notificacao.nascimento_paciente,
				prontuario: notificacao.prontuario,
				notificador: notificacao.notificante,
				tipo_incidente: notificacao.tipo_incidente,
				quem_presenciou: notificacao.quem_presenciou,
				descricao_ocorrencia: notificacao.descricao,
				acoes_imediatas: notificacao.acoes_imediatas,
				medida_preventiva: notificacao.medida_preventiva,
				fato_presenciado: notificacao.fato_presenciado,
				other_checked:
					notificacao.tipo_incidente_outros !== '' ? true : false,
			}));
		}
	}, []);

	return (
		<div className='flex flex-col min-h-screen'>
			{enviado ? (
				<div className='font-inter flex justify-center items-start w-full h-screen'>
					<div className='flex flex-col  bg-[#F5F5F5] mt-[100px]  items-center rounded-md max-w-[800px] gap-5  flex-grow shadow-lg'>
						<div className='bg-[#004A80]  rounded-t-md  w-full h-[20px] '>
							{' '}
						</div>
						<div className='text-[#00C0F3]  rounded-full '>
							<IoMdCheckmarkCircle size={120} />
						</div>
						<div className='text-3xl font-semibold text-[#004A80]'>
							{' '}
							Classificação feita com Sucesso{' '}
						</div>
						<div className='text-lg font-medium  w-[80%] text-[#8b8b8b]'>
							{' '}
							A etapa de classificação foi concluída com sucesso,
							acompanhe o andamento dela através do painel de
							acompanhamento ou consultando o protocolo dela
							através do link abaixo.{' '}
						</div>
						<div className=' flex flex-col text-4xl font-bold mt-16 text-[#004A80]'>
							<div className='flex gap-3'>
								<div>Protocolo: </div>
								<button
									onClick={(event) => handleCopy(event)}
									value={notificacao?.protocolo}
									className=''>
									{notificacao?.protocolo}
									{/* {' '}
									{Math.floor(Math.random() * 1000000)} */}
								</button>
							</div>
							{copiado ? (
								<div className='flex flex-col justify-end '>
									<div className='text-[#a0a0a0] font-semibold  text-[12px]'>
										Protocolo copiado!
									</div>
								</div>
							) : null}
						</div>

						<a
							className='text-[#00C0F3] mb-14 hover:text-[#004A80] transition-all duration-200'
							href='/hub-de-notificacoes'>
							{' '}
							clique aqui para ser redirecionado à consulta de
							protocolos
						</a>
					</div>
				</div>
			) : (
				<div
					ref={formRef}
					id='my-table-id'
					className='mx-auto w-full max-w-[1130px] mt-10 px-4 sm:px-6 lg:px-8'>
					<div id='block1'>
						<div
							className={`${
								formLayout
									? 'flex flex-row justify-between w-full h-[150px] my-1'
									: 'flex flex-col md:flex-row justify-between w-full h-[150px] my-1'
							}`}>
							<div
								className={`${
									formLayout
										? 'w-[30%] h-[105px] my-3 flex justify-start'
										: 'w-0 md:w-[30%] h-[105px] my-3 flex justify-center md:justify-start'
								}`}>
								<img
									alt='logo da prefeitura'
									src={Logo}
									className={`${
										formLayout
											? 'h-[80%] mt-6 scale-75 object-contain'
											: 'h-full scale-75 object-contain'
									}`}
								/>
							</div>
							<div
								className={`${
									formLayout
										? 'w-[65%] h-[105px] my-5 text-white font-bold text-center flex items-center justify-center'
										: 'w-full md:w-[65%] h-[105px] my-5 text-white font-bold text-center flex items-center justify-center'
								}`}>
								<p
									className={`${
										formLayout
											? 'p-0 pb-10 text-3xl bg-gray-800 h-full content-center'
											: 'text-2xl py-5 px-5 md:p-0 md:text-2xl lg:text-3xl bg-gray-800 h-full content-center'
									}`}>
									ANÁLISE E TRATAMENDO DE EVENTOS ADVERSOS
								</p>
							</div>
						</div>
						<div className='w-full border-[1px] border-black'>
							<div className='w-full'>
								<div
									className={`${
										formLayout
											? 'flex flex-row'
											: 'flex flex-col md:flex-row'
									}`}>
									<div className=' flex w-full justify-start md:w-1/2 border-r-[1px] border-b-[1px] border-black px-2 py-3'>
										<p className='font-semibold w-4/12 mr-2 text-start content-center '>
											Notificação n.º:{' '}
										</p>

										{notificacao?.protocolo}
									</div>
									<div className=' flex w-full justify-start md:w-1/2 border-r-[1px] border-b-[1px] border-black px-2 py-3'>
										<p className='font-semibold w-6/12 text-start content-center mr-2 '>
											Data da notificação:
										</p>
										{formatDate(
											notificacao?.data_notificacao
										)}
									</div>
									<div className=' flex w-full justify-start md:w-1/2  border-b-[1px] border-black px-2 py-3'>
										<p className='font-semibold w-6/12 text-start content-center mr-2'>
											Data da ocorrência:
										</p>
										{formatDate(
											notificacao?.data_ocorrencia
										)}
									</div>
								</div>
								<div
									className={`${
										formLayout
											? 'flex flex-row'
											: 'flex flex-col md:flex-row'
									}`}>
									<div className='flex w-full md:w-1/2 border-r-[1px] border-b-[1px] border-black px-2 py-3'>
										<p className='font-semibold content-center align-baseline py-1 mr-2'>
											Unidade:
										</p>
										<p className='mx-4 content-center'>
											{notificacao?.unidade?.nome}
										</p>
									</div>
									<div
										className={`${
											formLayout
												? 'flex w-7/12 border-b-[1px] border-black px-2 py-3'
												: 'flex w-full md:w-7/12 border-b-[1px] border-black px-2 py-3'
										}`}>
										<p className='font-semibold content-center w-6/12 py-1  '>
											Setor onde ocorreu o incidente:
										</p>
										<p className='mx-2 content-center'>
											{notificacao?.setor}
										</p>
									</div>
								</div>
								<div className='flex border-b-[1px] border-black px-2 py-3'>
									<p className='font-semibold  text-start py-1'>
										Nome completo do paciente:
									</p>
									<p className='mx-4 content-center'>
										{notificacao?.nome_paciente}
									</p>
								</div>
								<div
									className={`${
										formLayout
											? 'w-full flex flex-row'
											: 'w-full flex flex-col md:flex-row'
									}`}>
									<div className=' flex xs:w-1/4 border-r-[1px] border-b-[1px] border-black px-2 py-3'>
										<p className='font-semibold text-start mr-3 content-center'>
											Sexo:
										</p>
										<div className='flex  gap-4 '>
											<label className='flex items-end py-1 '>
												<input
													type='radio'
													name='sexo_paciente'
													id='masculino'
													checked={
														notificacao?.sexo_paciente ===
														'MASCULINO'
													}
													{...(notificacao?.status ===
														'NOTIFICADO' &&
														'readOnly')}
													className=' h-4 w-4 text-blue-600'
												/>
												<span className='ml-2 text-sm'>
													Masculino
												</span>
											</label>
											<label className='flex items-end py-1'>
												<input
													type='radio'
													name='sexo_paciente'
													id='feminino'
													checked={
														notificacao?.sexo_paciente ===
														'FEMININO'
													}
													{...(notificacao?.status ===
														'NOTIFICADO' &&
														'readOnly')}
													className=' h-4 w-4 text-blue-600'
												/>
												<span className='ml-2 text-sm'>
													Feminino
												</span>
											</label>
										</div>
									</div>
									<div className='flex w-full justify-start md:w-5/12  border-b-[1px] border-black px-2 py-3'>
										<p className='font-semibold w-7/12 text-start content-center'>
											Data de Nascimento:
										</p>
										<p className='content-center'>
											{' '}
											{formatDate(
												notificacao?.nascimento_paciente
											)}
										</p>
									</div>
									<div className=' flex w-full md:w-2/3 border-l border-b-[1px] border-black px-2 py-3'>
										<p className='font-semibold content-center py-1'>
											Prontuário/BE:
										</p>
										<p className=' mx-4 content-center'>
											{notificacao?.protocolo}
										</p>
										<input
											name='prontuario'
											id='prontuario'
											onChange={({
												target: { value, id },
											}) => handleForm(value, id)}
											className=' ml-2 w-full focus:outline-none'
											type='text'
										/>
									</div>
								</div>
								<div
									className={`${
										formLayout
											? 'flex w-full  border-black px-2 py-3'
											: 'flex w-full md:w-full  border-black px-2 py-3'
									}`}>
									<p
										className={`${
											formLayout
												? 'font-semibold content-center text-start py-1 w-[300px]'
												: 'font-semibold content-center text-start py-1 sm:w-[300px]'
										}`}>
										Identificação do notificador (opcional):
									</p>
									<p className='content-center mx-4'>
										{' '}
										{notificacao?.notificante}
									</p>
								</div>
							</div>
						</div>

						<div className='w-full my-5 border-[1px] border-black'>
							<div className='w-full bg-gray-300 py-2 text-center font-bold'>
								INCIDENTES E NÃO CONFORMIDADES
							</div>
							<div className='w-full border-b-[1px] border-t border-black p-4'>
								<div
									className={`${
										formLayout
											? 'grid grid-cols-2 gap-4'
											: 'grid grid-cols-1 lg:grid-cols-2 gap-4'
									}`}>
									{tiposIncidentes.map((incident, index) => (
										<label
											key={index + 'b'}
											className='flex items-center whitespace-nowrap'>
											<input
												type='radio'
												name='tipo_incidente'
												checked={
													incident.value ===
													notificacao?.tipo_incidente
												}
												value={incident.display}
												id={incident.display}
												className='form-radio h-3 w-3 md:h-4 md:w-4 text-blue-600'
											/>
											<span
												className={`${
													formLayout
														? 'ml-2 text-gray-700 mb-2 text-base'
														: 'ml-2 text-gray-700 text-sm md:text-base'
												}`}>
												{incident.display}
											</span>
										</label>
									))}
									<label className='flex items-center whitespace-nowrap'>
										<input
											type='radio'
											name='tipo_incidente'
											defaultChecked={
												!notificacao?.tipo_incidente_outros ===
												''
											}
											className='form-radio h-3 w-3 md:h-4 md:w-4 text-blue-600'
										/>
										<span className='ml-2 text-gray-700 flex items-center w-10/12 text-sm md:text-base'>
											Outro:
											{!notificacao?.tipo_incidente_outros ===
											'' ? (
												<div
													type='text'
													name='tipo_incidente'
													className='ml-2 w-full h-8 text-xs md:text-sm border-b-[1px] border-gray-500  focus:outline-none'>
													{
														notificacao?.tipo_incidente_outros
													}
												</div>
											) : null}
										</span>
									</label>
								</div>
							</div>
							<div className=' flex w-full py-2'>
								<p
									className={`${
										formLayout
											? 'font-semibold px-2  content-center w-5/12 text-start'
											: 'font-semibold px-2  content-center md:w-5/12 text-start'
									}`}>
									O fato foi presenciado por alguém?
								</p>
								<div className='w-full flex items-center px-2 gap-4'>
									<label className='flex items-center'>
										<input
											type='radio'
											name='quem_presenciou'
											id='ninguem'
											checked={!form?.fato_presenciado}
											className='form-radio h-4 w-4 text-blue-600'
										/>
										<span
											className={`${
												formLayout
													? 'ml-2 text-gray-700 mb-2'
													: 'ml-2 text-gray-700'
											}`}>
											Não
										</span>
									</label>

									<label className='flex items-center w-full font-semibold'>
										<input
											type='radio'
											name='quem_presenciou'
											id='sim'
											checked={form?.fato_presenciado}
											className='form-radio h-4 w-4 text-blue-600'
										/>
										<span className='ml-2 text-gray-700 flex w-full  items-center'>
											<span
												className={`${
													formLayout
														? ' text-gray-700 w-3/12 text-start py-2 mb-2'
														: ' text-gray-700 md:w-3/12 text-start py-2'
												}`}>
												Sim - Quem ?
											</span>
											{form?.fato_presenciado ? (
												<p
													type='text'
													id='quem_presenciou'
													className=' w-full h-[20px] text-sm border-b-[1px] border-gray-500 focus:outline-none '>
													{form?.quem_presenciou}
												</p>
											) : null}
										</span>
									</label>
								</div>
							</div>
						</div>
						<div className='w-full my-5 border-[1px] border-black'>
							<div className='text-start px-2 font-semibold'>
								Descrição da ocorrência.
							</div>
							<div
								name='descricao_ocorrencia'
								id='descricao_ocorrencia'
								className='w-full h-36 mt-2 px-2 text-sm text-gray-700 border-none focus:outline-none focus:ring-0 resize-none'>
								{' '}
								{form?.descricao_ocorrencia}
							</div>
						</div>
						<div className='w-full my-5 border-[1px] border-black'>
							<div className='text-start px-2 font-semibold'>
								Ações imediatas tomadas.
							</div>
							<div
								name='acoes_imediatas'
								id='acoes_imediatas'
								className='w-full h-36 mt-2 px-2 text-sm text-gray-700 border-none focus:outline-none focus:ring-0 resize-none'>
								{form?.acoes_imediatas}
							</div>
						</div>
						<div className='w-full my-5 border-[1px] border-black'>
							<div className='text-start px-2 font-semibold'>
								Existe alguma medida em vigor para prevenir tal
								incidente? Qual?
							</div>
							<div
								name='medida_preventiva'
								id='medida_preventiva'
								className='w-full h-36 mt-2 px-2 text-sm text-gray-700 border-none focus:outline-none focus:ring-0 resize-none'>
								{form?.acoes_imediatas}
							</div>
						</div>
					</div>
					<div id='block2'>
						{notificacao?.status === 'NOTIFICADO' ||
						notificacao?.status === 'CLASSIFICADO' ||
						notificacao?.status === 'INVESTIGADO' ? (
							<div
								className={`${
									formLayout
										? 'w-full my-5 mt-10 border-[1px]  border-black'
										: 'w-full my-5 border-[1px]  border-black'
								}`}>
								<div
									className={`${
										formLayout
											? 'w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold '
											: 'w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold'
									}`}>
									ANÁLISE DO INCIDENTE
									<p
										className={`${
											formLayout ? 'mb-4' : ''
										}`}>
										{
											'(preenchimento pelo Núcleo de Segurança do Paciente)'
										}
									</p>
								</div>

								<div
									className={`${
										formLayout
											? 'w-full p-2 flex gap-6 mb-2'
											: 'w-full p-2 flex gap-6'
									}`}>
									<p>
										Prazo máximo para que ocorra a
										investigação:{' '}
									</p>
									{notificacao.status !== 'NOTIFICADO' ? (
										<div>
											{formatDate(
												notificacao?.classificacao
													.prazo_investigacao
											)}
										</div>
									) : (
										<input
											type='date'
											id='prazo_investigacao'
											value={classForm.prazo_investigacao}
											onChange={handleClassForm}
										/>
									)}
								</div>
							</div>
						) : null}
						{notificacao?.status === 'NOTIFICADO' ||
						notificacao?.status === 'CLASSIFICADO' ||
						notificacao?.status === 'INVESTIGADO' ? (
							<div
								className={`${
									formLayout
										? 'w-full my-5 border-[1px] mb-10  border-black'
										: 'w-full my-5 border-[1px]  border-black'
								}`}>
								<div
									className={`${
										formLayout
											? 'w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold pb-4'
											: 'w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold'
									}`}>
									CLASSIFICAÇÃO INCIDENTE
								</div>
								<div className='w-full p-2'>
									<div className='flex flex-col gap-2 mt-2'>
										{classificacoes.map((evento) => (
											<div
												key={evento.display + 'c'}
												className={`flex items-start gap-2`}>
												<input
													type='radio'
													name='opcao'
													id='classificacao'
													checked={
														notificacao?.status ===
														'NOTIFICADO'
															? undefined
															: notificacao
																	?.classificacao
																	?.classificacao ===
															  evento.display
													}
													value={evento.display}
													className={`${
														formLayout
															? 'mt-[10px]'
															: 'mt-[4px]'
													}`}
													onChange={
														notificacao?.status ===
														'NOTIFICADO'
															? handleClassForm
															: null
													}
												/>
												<label
													htmlFor={evento.display}
													className={`${
														formLayout
															? 'flex gap-2 mb-2'
															: 'flex gap-2'
													}`}>
													<p className='text-sm font-normal content-center'>
														<span className='font-bold'>
															{evento.value}:
														</span>{' '}
														{evento.descricao}
													</p>
												</label>
											</div>
										))}

										<div className='flex items-start gap-2'>
											<input
												type='radio'
												name='opcao'
												id='classificacao'
												checked={
													notificacao?.status ===
													'NOTIFICADO'
														? undefined
														: notificacao
																?.classificacao
																?.classificacao ===
														  'NEVER_EVENTS'
												}
												value='NEVER_EVENTS'
												className={`${
													formLayout
														? 'mt-[10px]'
														: 'mt-[4px]'
												}`}
												onChange={
													notificacao?.status ===
													'NOTIFICADO'
														? handleClassForm
														: null
												}
											/>
											<label
												htmlFor='never'
												className={`${
													formLayout
														? 'flex gap-2'
														: 'flex gap-2'
												}`}>
												<div className='text-sm font-normal content-center flex gap-2'>
													<span className='font-bold'>
														Never Events:
													</span>{' '}
													{notificacao?.status ===
													'NOTIFICADO' ? (
														<select
															className='w-[290px] bg-[#f0f0f0] p-1 rounded-md  '
															value={
																notificacao
																	?.classificacao
																	?.classificacao ===
																'NEVER_EVENTS'
																	? notificacao
																			?.classificacao
																			?.sub_classificacao
																	: classForm.sub_classificacao
															}
															id='sub_classificacao'
															onChange={
																handleClassForm
															}>
															<option
																value=''
																className=''>
																Escolha um valor
																de acordo com o
																evento
															</option>
															{NeverJson.map(
																(
																	item,
																	index
																) => (
																	<option
																		key={
																			index +
																			'g'
																		}
																		id='sub_classificacao'
																		value={
																			item.codigo
																		}
																		className='flex mb-10'>
																		{item.codigo +
																			' ' +
																			item.descricao}
																	</option>
																)
															)}
														</select>
													) : (
														<p className=''>
															{notificacao
																?.classificacao
																?.sub_classificacao ===
															''
																? 'Nenhum Never Event'
																: notificacao
																		?.classificacao
																		?.sub_classificacao}
														</p>
													)}
												</div>
											</label>
										</div>
									</div>
								</div>
							</div>
						) : null}
						{notificacao?.status === 'CLASSIFICADO' ||
						notificacao?.status === 'INVESTIGADO' ? (
							<div className='w-full my-5 border-[1px] mb-10 border-black'>
								<div
									className={`${
										formLayout
											? 'w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold pb-4'
											: 'w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold'
									}`}>
									EQUIPE DE INVESTIGAÇÃO
								</div>
								<div className='grid'>
									<div className='h-[30px] w-full flex'>
										<div
											className={`${
												formLayout
													? 'w-full content-center h-[full] font-semibold border-[1px] border-r-black  text-center pb-4'
													: 'w-full content-center h-[full] font-semibold border-[1px] border-r-black  text-center '
											}`}>
											NOME COMPLETO
										</div>
										<div
											className={`${
												formLayout
													? 'w-full content-center h-[full] font-semibold border-[1px] border-r-black  text-center pb-4'
													: 'w-full content-center h-[full] font-semibold border-[1px] border-r-black  text-center '
											}`}>
											CARGO
										</div>
										<div
											className={`${
												formLayout
													? 'w-full content-center h-[full] font-semibold border-[1px] border-r-black  text-center pb-4'
													: 'w-full content-center h-[full] font-semibold border-[1px] border-r-black  text-center '
											}`}>
											SETOR
										</div>
										<div
											className={`${
												formLayout
													? 'w-full h-[full] border-[1px]  font-semibold content-center text-center pb-4 '
													: 'w-full h-[full] border-[1px]  font-semibold content-center text-center '
											}`}>
											E-MAIL
										</div>
									</div>
								</div>
								{Array.from('x'.repeat(counter)).map(
									(item, index) => (
										<div
											key={index + 'per'}
											id={index + 'cron'}
											className='grid'>
											<div className='min-h-[30px] h-auto w-full flex'>
												<div className='w-full content-center h-[full] font-semibold border-[1px] border-r-black  text-center '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='max-w-[260px] text-wrap'>
															{invForm[
																'equipe_investigacao'
															]
																? invForm[
																		'equipe_investigacao'
																  ][index][
																		'nome_completo'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'equipe_investigacao_data'
															}
															name='nome_completo'
															className=''
															onChange={(e) =>
																handleEquipe(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div className='w-full h-[full] border-[1px] border-r-black font-semibold content-center text-center '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='max-w-[260px] text-wrap'>
															{invForm[
																'equipe_investigacao'
															]
																? invForm[
																		'equipe_investigacao'
																  ][index][
																		'cargo'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'equipe_investigacao_data'
															}
															name='cargo'
															className=''
															onChange={(e) =>
																handleEquipe(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div className='w-full h-[full] border-[1px] border-r-black font-semibold content-center text-center '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='max-w-[260px] text-wrap'>
															{invForm[
																'equipe_investigacao'
															]
																? invForm[
																		'equipe_investigacao'
																  ][index][
																		'setor'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'equipe_investigacao_data'
															}
															name='setor'
															className=''
															onChange={(e) =>
																handleEquipe(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div className='w-full  border-[1px]  font-semibold content-center text-center '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='max-w-[260px] p-2 h-auto break-words '>
															{invForm[
																'equipe_investigacao'
															]
																? invForm[
																		'equipe_investigacao'
																  ][index][
																		'email'
																  ]
																: null}
														</div>
													) : (
														<input
															type='email'
															id={`${index} / equipe_investigacao_data`}
															name='email'
															className=''
															onBlur={(e) => {
																const email =
																	e.target
																		.value;
																const emailRegex =
																	/^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex para validar email
																if (
																	!emailRegex.test(
																		email
																	)
																) {
																	alert(
																		'Por favor, insira um email válido.'
																	);
																} else {
																	handleEquipe(
																		e.target
																			.id,
																		e.target
																			.name,
																		email
																	);
																}
															}}
														/>
													)}
												</div>
											</div>
										</div>
									)
								)}

								{notificacao?.status === 'CLASSIFICADO' ? (
									<div>
										<button
											className='absolute font-bold text-[14px] hover:text-[#666666]'
											id='adcionar'
											onClick={(e) => {
												addEquipe(e.target.id);
											}}>
											{' '}
											Adcionar{' '}
										</button>
										<button
											className='absolute font-bold text-[14px] ml-20  hover:text-[#666666]'
											id='remover'
											onClick={(e) => {
												addEquipe(e.target.id);
											}}>
											{' '}
											Remover{' '}
										</button>
									</div>
								) : null}
							</div>
						) : null}

						{notificacao?.status === 'CLASSIFICADO' ||
						notificacao?.status === 'INVESTIGADO' ? (
							<div className='w-full my-5 border-[1px]  border-black'>
								<div className='w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold'>
									COLETA E ORGANIZAÇÃO DOS DADOS
									<p
										className={`${
											formLayout
												? 'text-sm pb-4'
												: 'text-sm'
										}`}>
										{
											'(usar metodologia apropriada - entrevistas, consula a prontuários ou documentos que a instituição possua, busca ativa em campo, entre outros)'
										}
									</p>
								</div>
								{notificacao?.status === 'CLASSIFICADO' ? (
									<textarea
										id='coleta_dados'
										className='p-2 w-full h-36 mt-2 text-sm text-gray-700 border-none focus:outline-none focus:ring-0 resize-none'
										placeholder='Definição do cenário:'
										type='text'
										name='coleta_dados'
										onChange={(e) =>
											handleColeta(
												e.target.id,

												e.target.name,

												e.target.value
											)
										}></textarea>
								) : (
									<div className='p-4 w-full h-full'>
										{invForm.coleta_dados}
									</div>
								)}
							</div>
						) : null}
						{notificacao?.status === 'CLASSIFICADO' ||
						notificacao?.status === 'INVESTIGADO' ? (
							<div className='w-full my-5 border-[1px] mb-10 border-black'>
								<div
									className={`${
										formLayout
											? 'w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold pb-4'
											: 'w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold'
									}`}>
									CRONOLOGIA DO INCIDENTE
								</div>
								<div className='grid'>
									<div className='h-[30px] w-full flex'>
										<div className='w-7/12 content-center h-[full] font-semibold border-[1px] border-r-black  text-center '>
											EVENTO
										</div>
										<div className='w-5/12 h-[full] border-[1px] border-r-black font-semibold content-center text-center '>
											DATA
										</div>
										<div className='w-4/12 h-[full] border-[1px] border-r-black font-semibold content-center text-center '>
											HORA
										</div>
										<div className='w-6/12 h-[full] border-[1px]  font-semibold border-r-black content-center text-center '>
											LOCAL/SETOR
										</div>
										<div className='w-full h-[full] border-[1px]  font-semibold content-center text-center '>
											DESCRIÇÃO
										</div>
									</div>
								</div>
								<div></div>
								{Array.from({ length: cronCounter }).map(
									(_, index) => (
										<div
											key={`${index}-${'australopitecus'}`}
											className='grid'>
											<div className='min-h-[30px] h-auto w-full flex'>
												<div className='w-[219px] content-center h-[full] font-semibold border-[1px] border-r-black text-center'>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='max-w-[260px] text-wrap'>
															{invForm[
																'cronologia_incidentes'
															]
																? invForm[
																		'cronologia_incidentes'
																  ][index][
																		'evento'
																  ]
																: null}
														</div>
													) : (
														<div>
															<input
																type='text'
																id={
																	index +
																	' ' +
																	'/' +
																	' ' +
																	'cronologia_incidentes_data'
																}
																name='evento'
																className=''
																onChange={(e) =>
																	handleCronologia(
																		e.target
																			.id,

																		e.target
																			.name,

																		e.target
																			.value
																	)
																}></input>
														</div>
													)}
												</div>
												<div className='w-[156.5px] h-[full] border-[1px] border-r-black font-semibold content-center text-center'>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='max-w-[260px] text-wrap'>
															{invForm[
																'cronologia_incidentes'
															]
																? formatDate(
																		invForm[
																			'cronologia_incidentes'
																		][
																			index
																		][
																			'data'
																		]
																  )
																: null}
														</div>
													) : (
														<input
															type='date'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'cronologia_incidentes_data'
															}
															name='data'
															className=''
															onChange={(e) =>
																handleCronologia(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div className='w-[126px] h-[full] border-[1px] border-r-black font-semibold content-center text-center'>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='max-w-[260px] text-wrap'>
															{invForm[
																'cronologia_incidentes'
															]
																? invForm[
																		'cronologia_incidentes'
																  ][index][
																		'hora'
																  ]
																: null}
														</div>
													) : (
														<input
															type='time'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'cronologia_incidentes_data'
															}
															name='hora'
															className=''
															onChange={(e) =>
																handleCronologia(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div className='w-[188px] h-[full] border-[1px] font-semibold content-center border-r-black text-center'>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='max-w-[260px] text-wrap'>
															{invForm[
																'cronologia_incidentes'
															]
																? invForm[
																		'cronologia_incidentes'
																  ][index][
																		'setor'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'cronologia_incidentes_data'
															}
															name='setor'
															className='w-[90%]'
															onChange={(e) =>
																handleCronologia(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div className=' w-[374px] h-[full] border-[1px] font-semibold content-center text-center'>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 text-wrap'>
															{invForm[
																'cronologia_incidentes'
															]
																? invForm[
																		'cronologia_incidentes'
																  ][index][
																		'descricao'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'cronologia_incidentes_data'
															}
															name='descricao'
															className='w-[370px]'
															onChange={(e) =>
																handleCronologia(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
											</div>
										</div>
									)
								)}
								{notificacao?.status === 'CLASSIFICADO' ? (
									<div>
										<button
											className='absolute font-bold text-[14px] hover:text-[#666666]'
											id='adcionar'
											onClick={(e) => {
												addCronologia(e.target.id);
											}}>
											{' '}
											Adcionar{' '}
										</button>
										<button
											key={'sla'}
											id='remover'
											className='absolute font-bold text-[14px] ml-20  hover:text-[#666666]'
											onClick={(e) => {
												addCronologia(e.target.id);
											}}>
											{' '}
											Remover{' '}
										</button>
									</div>
								) : null}
							</div>
						) : null}
						{notificacao?.status === 'CLASSIFICADO' ||
						notificacao?.status === 'INVESTIGADO' ? (
							<div className='w-full mt-5 border-[1px] border-black'>
								<div
									className={`${
										formLayout
											? 'w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold pb-4'
											: 'w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold'
									}`}>
									DEFINIÇÃO DAS CAUSAS — FATORES QUE
									CONTRIBUÍRAM PARA O INCIDENTE
								</div>
								{/* <div className='w-full px-2  py-1 border-black border-b-2 text-start text-sm font-bold'>
								INCIDENTE :
							</div> */}
								<div className='grid'>
									<div className='min-h-[45px] h-auto w-full flex text-[13px]'>
										<div className='w-full content-center h-[full] font-bold border-[1px] border-r-black  text-center '>
											FATORES DO PACIENTE
										</div>
										<div className='w-full h-[full] border-[1px] border-r-black font-bold content-center text-center '>
											FATORES INDIVIDUAIS DOS
											PROFISSIONAIS
										</div>
										<div className='w-full h-[full] border-[1px] border-r-black font-bold content-center text-center '>
											FATORES INDIVIDUAIS DA TAREFA OU
											ATIVIDADE
										</div>
										<div className='w-full h-[full] border-[1px]  font-bold border-r-transparent content-center text-center '>
											FATORES RELACIONADOS A COMUNICAÇÃO
										</div>
									</div>
								</div>
								{Array.from('x'.repeat(incident1Counter)).map(
									(item, index) => (
										<div className='grid '>
											<div className='h-auto min-h-[45px]  w-full flex text-[13px] font-semibold'>
												<div className='w-full content-center h-[full]  border-[1px] border-r-black  text-center '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 py-5  text-wrap '>
															{invForm[
																'definicao_causas'
															]
																? invForm[
																		'definicao_causas'
																  ][
																		'fator_paciente'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'definicao_causas_data'
															}
															name='fator_paciente'
															className='w-[90%]'
															onChange={(e) =>
																handleCausas(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div className='w-full h-[full] border-[1px] font-semibold border-r-black  content-center text-center '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 py-5  text-wrap'>
															{invForm[
																'definicao_causas'
															]
																? invForm[
																		'definicao_causas'
																  ][
																		'fator_profissional'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'definicao_causas_data'
															}
															name='fator_profissional'
															className='w-[90%]'
															onChange={(e) =>
																handleCausas(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div className='w-full h-[full] border-[1px] border-r-black font-semibold content-center text-center '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 py-5    text-wrap'>
															{invForm[
																'definicao_causas'
															]
																? invForm[
																		'definicao_causas'
																  ][
																		'fator_tarefa'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'definicao_causas_data'
															}
															name='fator_tarefa'
															className='w-[90%]'
															onChange={(e) =>
																handleCausas(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div className='w-full h-[full] border-[1px]  font-semibold border-r-transparent content-center text-center '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 py-5 text-wrap'>
															{invForm[
																'definicao_causas'
															]
																? invForm[
																		'definicao_causas'
																  ][
																		'fator_comunicacao'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'definicao_causas_data'
															}
															name='fator_comunicacao'
															className='w-[90%]'
															onChange={(e) =>
																handleCausas(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
											</div>
										</div>
									)
								)}
								{/* <button
								className='absolute font-bold text-[14px] hover:text-[#666666]'
								onClick={() =>
									incident1Counter
										? setIncident1Counter(
												incident1Counter + 1
										  )
										: null
								}>
								{' '}
								Adcionar{' '}
							</button>
							<button
								className='absolute font-bold text-[14px] ml-20  hover:text-[#666666]'
								onClick={() =>
									incident1Counter > 1
										? setIncident1Counter(
												incident1Counter - 1
										  )
										: null
								}>
								{' '}
								Remover{' '}
							</button> */}
							</div>
						) : null}
						{notificacao?.status === 'CLASSIFICADO' ||
						notificacao?.status === 'INVESTIGADO' ? (
							<div className='grid '>
								<div className='min-h-[45px] h-auto w-full flex text-[13px]'>
									<div className='w-full pr-[1px] content-center h-[full] font-bold border-[1px] border-black border-b-transparent border-t-transparent text-center '>
										FATORES RELACIONADOS A EQUIPAMENTOS
									</div>
									<div className='w-full h-[full] border-[1px] border-black border-l-transparent border-b-transparent font-bold content-center text-center  border-t-transparent'>
										FATORES RELACIONADOS AO AMBIENTE DE
										TRABALHO
									</div>
									<div className='w-full h-[full] border-[1px] border-black border-l-transparent border-b-transparent font-bold content-center text-center border-t-transparent '>
										FATORES ORGANIZACIONAIS
									</div>
									<div className='w-full h-[full] border-[1px]  font-bold border-black border-l-transparent border-b-transparent content-center text-center border-t-transparent '>
										FATORES RELACIONADOS A TREINAMENTO E
										EDUCAÇÃO
									</div>
								</div>
							</div>
						) : null}
						{notificacao?.status === 'CLASSIFICADO' ||
						notificacao?.status === 'INVESTIGADO' ? (
							<div>
								{Array.from('x'.repeat(incident1Counter)).map(
									(item, index) => (
										<div className='grid'>
											<div
												className={`min-h-[45px] h-auto  w-full flex text-[13px]`}>
												<div
													className={`w-full content-center pr-[1px]  border-l-black h-[full] font-semibold border-[1px] border-r-black  text-center  ${
														index ===
														incident1Counter - 1
															? 'border-b-black'
															: ''
													} `}>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 py-5 text-wrap'>
															{invForm[
																'definicao_causas'
															]
																? invForm[
																		'definicao_causas'
																  ][
																		'fator_equipamento'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'definicao_causas_data'
															}
															name='fator_equipamento'
															className='w-[90%]'
															onChange={(e) =>
																handleCausas(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div
													className={`w-full h-[full] border-[1px] border-r-black font-semibold content-center text-center  ${
														index ===
														incident1Counter - 1
															? 'border-b-black'
															: ''
													} `}>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 text-wrap'>
															{invForm[
																'definicao_causas'
															]
																? invForm[
																		'definicao_causas'
																  ][
																		'fator_ambiente'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'definicao_causas_data'
															}
															name='fator_ambiente'
															className='w-[90%]'
															onChange={(e) =>
																handleCausas(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div
													className={`w-full h-[full] border-[1px] border-r-black font-semibold content-center text-center  ${
														index ===
														incident1Counter - 1
															? 'border-b-black'
															: ''
													} `}>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 text-wrap'>
															{invForm[
																'definicao_causas'
															]
																? invForm[
																		'definicao_causas'
																  ][
																		'fator_organizacional'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'definicao_causas_data'
															}
															name='fator_organizacional'
															className='w-[90%]'
															onChange={(e) =>
																handleCausas(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>

												<div
													className={`w-full h-[full] border-[1px]  font-semibold  content-center border-r-black text-center ${
														index ===
														incident1Counter - 1
															? 'border-b-black'
															: ''
													} `}>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 text-wrap'>
															{invForm[
																'definicao_causas'
															]
																? invForm[
																		'definicao_causas'
																  ][
																		'fator_treinamento'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'definicao_causas_data'
															}
															name='fator_treinamento'
															className='w-[90%]'
															onChange={(e) =>
																handleCausas(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
											</div>
										</div>
									)
								)}
							</div>
						) : null}
						{/* {notificacao?.status !== 'CLASSIFICADO' ? null : (
						<div>
							<button
								className='absolute font-bold text-[14px] hover:text-[#666666]'
								onClick={() =>
									incident1Counter
										? setIncident1Counter(
												incident1Counter + 1
										  )
										: null
								}>
								{' '}
								Adcionar{' '}
							</button>
							<button
								className='absolute font-bold text-[14px] ml-20  hover:text-[#666666]'
								onClick={() =>
									incident1Counter > 1
										? setIncident1Counter(
												incident1Counter - 1
										  )
										: null
								}>
								{' '}
								Remover{' '}
							</button>
						</div>
					)} */}
						{notificacao?.status === 'CLASSIFICADO' ? (
							<div className='w-full my-10 border-[1px] mb-10  border-black'>
								<div
									className={`${
										formLayout
											? 'w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold pb-4'
											: 'w-full bg-gray-300 py-1 border-black border-b-2 text-center font-bold'
									}`}>
									PLANO DE AÇÃO
								</div>
								<div className='grid'>
									<div className='h-[30px] w-full flex'>
										<div className='w-full content-center h-[full] font-semibold border-[1px] border-r-black  text-center '>
											CAUSAS PRIORITÁRIAS
										</div>
										<div className='w-full h-[full] border-[1px] border-r-black font-semibold content-center text-center '>
											AÇÕES PLANEJADAS
										</div>
										<div className='w-full h-[full] border-[1px] border-r-black font-semibold content-center text-center '>
											RESPONSABILIDADE
										</div>
										<div className='w-full h-[full] border-[1px]  font-semibold border-r-black content-center text-center '>
											PRAZO
										</div>
										<div className='w-full h-[full] border-[1px]  font-semibold content-center text-center '>
											STATUS
										</div>
									</div>
								</div>
								{Array.from('x'.repeat(planCounter)).map(
									(item, index) => (
										<div className='grid'>
											<div className='h-auto min-h-[45px] w-full flex'>
												<div className='w-full content-center h-[full] font-semibold border-[1px] border-r-black  text-center '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 text-wrap font-normal'>
															{invForm[
																'plano_acoes'
															]
																? invForm[
																		'plano_acoes'
																  ][index][
																		'causas_prioritarias'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'plano_acoes_data'
															}
															name='causas_prioritarias'
															className='w-[90%]'
															onChange={(e) =>
																handlePlano(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div className='w-full h-[full] border-[1px] border-r-black  content-center text-center '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 text-wrap font-normal'>
															{invForm[
																'plano_acoes'
															]
																? invForm[
																		'plano_acoes'
																  ][index][
																		'acoes_planejadas'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'plano_acoes_data'
															}
															name='acoes_planejadas'
															className='w-[90%]'
															onChange={(e) =>
																handlePlano(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div className='w-full h-[full] border-[1px]  border-r-black font-semibold content-center text-center '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 text-wrap font-normal'>
															{invForm[
																'plano_acoes'
															]
																? invForm[
																		'plano_acoes'
																  ][index][
																		'responsavel'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'plano_acoes_data'
															}
															name='responsavel'
															className='w-[90%]'
															onChange={(e) =>
																handlePlano(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
												<div className='w-[99.9%] h-[full] border-[1px]   font-semibold content-center text-center '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 text-wrap font-normal'>
															{invForm[
																'plano_acoes'
															]
																? invForm[
																		'plano_acoes'
																  ][index][
																		'prazo'
																  ]
																: null}
														</div>
													) : (
														<input
															type='number'
															id={`${index} / plano_acoes_data`}
															name='prazo'
															className='w-[90%]'
															onBlur={(e) => {
																const prazo =
																	e.target
																		.value;
																const maxPrazo = 32767; // Número máximo permitido
																if (
																	parseInt(
																		prazo
																	) > maxPrazo
																) {
																	alert(
																		`O valor do prazo não pode ser maior que ${maxPrazo}.`
																	);
																} else {
																	handlePlano(
																		e.target
																			.id,
																		e.target
																			.name,
																		prazo
																	);
																}
															}}
														/>
													)}
												</div>
												<div className='w-full h-[full] border-[1px] pl-[1px]  font-semibold content-center text-center border-l-black '>
													{notificacao?.status ===
													'INVESTIGADO' ? (
														<div className='w-full p-2 text-wrap font-normal'>
															{invForm[
																'plano_acoes'
															]
																? invForm[
																		'plano_acoes'
																  ][index][
																		'status'
																  ]
																: null}
														</div>
													) : (
														<input
															type='text'
															id={
																index +
																' ' +
																'/' +
																' ' +
																'plano_acoes_data'
															}
															name='status'
															className='w-[90%]'
															onChange={(e) =>
																handlePlano(
																	e.target.id,

																	e.target
																		.name,

																	e.target
																		.value
																)
															}></input>
													)}
												</div>
											</div>
										</div>
									)
								)}
								{notificacao?.status === 'CLASSIFICADO' ? (
									<div>
										<button
											className='absolute font-bold text-[14px] hover:text-[#666666]'
											id='adcionar'
											onClick={(event) =>
												addPlano(event.target.id)
											}>
											{' '}
											Adcionar{' '}
										</button>
										<button
											className='absolute font-bold text-[14px] ml-20  hover:text-[#666666]'
											id='remover'
											onClick={(event) =>
												addPlano(event.target.id)
											}>
											{' '}
											Remover{' '}
										</button>
									</div>
								) : null}
							</div>
						) : null}
					</div>
					<div id='block3'>
						{notificacao?.status !== 'INVESTIGADO' ? null : (
							<div className='w-full my-5 border-[1px]  border-black'>
								<div
									className={`${
										formLayout
											? 'w-full bg-gray-300 py-1 border-black border-b-[1px] text-center font-bold pb-4'
											: 'w-full bg-gray-300 py-1 border-black border-b-[1px] text-center font-bold'
									}`}>
									AVALIAÇÃO FINAL
								</div>
								<div className='w-full flex gap-2 py-1 text-start px-2 border-black   font-normal text-[16px]'>
									As ações realizadas foram suficientes
									bloquear a reincidência do incidente?
									<div className='flex gap-1 '>
										<input
											type='radio'
											name='acoes_realizadas'
											id='sim'
											onChange={(e) => {
												handleFinal(
													e.target.name,
													e.target.id
												);
											}}></input>
										SIM
										<input
											type='radio'
											name='acoes_realizadas'
											id='nao'
											onChange={(e) => {
												handleFinal(
													e.target.name,
													e.target.id
												);
											}}></input>
										NÃO
									</div>
								</div>
								<div className='w-full flex gap-2 py-1 text-start px-2 border-black border-b-[1px]  font-normal text-[16px]'>
									Foi criado ou alterado algum padrão de
									trabalho para evitar a reincidência do
									incidente?
									<div className='flex gap-1 '>
										<input
											type='radio'
											name='padroes_trabalho'
											id='sim'
											onChange={(e) => {
												handleFinal(
													e.target.name,
													e.target.id
												);
											}}></input>
										SIM
										<input
											type='radio'
											name='padroes_trabalho'
											id='nao'
											onChange={(e) => {
												handleFinal(
													e.target.name,
													e.target.id
												);
											}}></input>
										NÃO
									</div>
								</div>
								<textarea
									className='p-2 w-full h-36 mt-2 text-sm text-gray-700 border-none focus:outline-none focus:ring-0 resize-none'
									placeholder='Observações:'
									name='observacoes'
									onChange={(e) => {
										handleFinal(
											e.target.name,
											e.target.value
										);
									}}></textarea>
							</div>
						)}
					</div>
				</div>
			)}
			{!enviado && (
				<div className='flex justify-between mx-auto w-full max-w-[1130px] mt-10 px-4 sm:px-6 lg:px-8'>
					<button
						className='enabled:bg-[#13335A] flex gap-2 bg-[#6285af] text-white py-2 px-4 rounded-md enabled:hover:bg-[#0078B5] focus:outline-none font-semibold'
						disabled={isButtonEnabled}
						onClick={handleGeneratePDF}>
						<div className='flex h-full scale-[130%] mt-1 '>
							<FaRegFilePdf />
						</div>
						PDF
					</button>
					<div className='flex justify-end text-red-500 gap-2'>
						{showAlert && (
							<div className='font-semibold w-[50%] leading-5'>
								Por favor, preencha todos os campos
								obrigatórios.
							</div>
						)}

						<button
							onClick={handleSubmit}
							disabled={isButtonEnabled}
							className='enabled:bg-[#13335A] bg-[#6285af] text-white py-2 px-4 rounded-md enabled:hover:bg-[#0078B5] focus:outline-none font-semibold'>
							Enviar
						</button>
					</div>
					{/* <button
					onClick={handleGeneratePDF}
					style={{ marginTop: '20px' }}>
					Gerar PDF
				</button> */}
					{/* <BotaoGerarPDF /> */}
				</div>
			)}
		</div>
	);
}

export default ClassInvForm;
